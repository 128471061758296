import "./traveller-rooms.component.scss";
import { useEffect, useRef, useState } from "react";
import { TravellerSelectorComponent } from "./traveller-selector/traveller-selector.component";
import { useSearchParams } from "react-router-dom";
import { searchParamsEnum } from "../../../common/enums/searchParamsEnum";

export const TravellerRoomsComponent = ({
  contentfulButtons,
  contentfulRoomAllocation,
  roomAllocation,
  updateRoomAllocation,
  parseRoomAllocationToCode,
  closeTravellerRoomsComponent,
}) => {
  const containerRef = useRef(null);
  const defaultButtons = {
    adultsMinusButtonDisabled: true,
    adultsPlusButtonDisabled: false,
    childrenMinusButtonDisabled: true,
    childrenPlusButtonDisabled: false,
    babiesMinusButtonDisabled: true,
    babiesPlusButtonDisabled: false,
  };
  const defaultValues = {
    rooms: {
      min: 1,
      max: 4,
    },
    travellers: {
      min: 1,
      max: 9,
    },
    travellersPerRoom: {
      min: 1,
      max: 4,
    },
  };
  const defaultRoom = {
    adults: 1,
    children: 0,
    babies: 0,
    buttons: JSON.parse(JSON.stringify(defaultButtons)),
  };
  const [rooms, setStateRooms] = useState([defaultRoom]);
  const [numberOfAdults, setStateNumberOfAdults] = useState(0);
  const [numberOfTravellers, setStateNumberOfTravellers] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  let originalRoomAllocation;
  let clickEventListenerTrigger = false;

  useEffect(() => {
    presetRoomAllocation();
  }, []);

  useEffect(() => {
    setNumberOfTravellers();
  }, [rooms, numberOfAdults, numberOfTravellers]);

  useEffect(() => {
    const clickEventHandler = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        !event.target.getAttribute("data-trigger") &&
        clickEventListenerTrigger
      ) {
        closeTravellerRoomsComponent();
      } else {
        clickEventListenerTrigger = true;
      }
    };

    document.addEventListener("click", clickEventHandler);
    return () => document.removeEventListener("click", clickEventHandler);
  }, [containerRef]);

  const presetRoomAllocation = () => {
    if (roomAllocation) {
      roomAllocation.forEach(
        (e) => (e["buttons"] = JSON.parse(JSON.stringify(defaultButtons)))
      );
      originalRoomAllocation = JSON.parse(JSON.stringify(roomAllocation));
      setStateRooms(setButtonsEnabledOrDisabled(originalRoomAllocation));
    } else {
      const savedRoomAllocation = localStorage.getItem("roomAllocation");
      originalRoomAllocation = savedRoomAllocation
        ? JSON.parse(savedRoomAllocation)
        : JSON.parse(JSON.stringify([defaultRoom]));
      setStateRooms(setButtonsEnabledOrDisabled(originalRoomAllocation));
    }
  };

  const setNumberOfRooms = (operator) => {
    if (
      operator === "+" &&
      rooms.length < defaultValues.rooms.max &&
      numberOfAdults >= rooms.length &&
      numberOfTravellers < defaultValues.travellers.max
    ) {
      const roomsToProcess = [...rooms, defaultRoom];
      setStateRooms(setButtonsEnabledOrDisabled(roomsToProcess));
    } else if (operator === "-" && rooms.length > defaultValues.rooms.min) {
      const roomsToProcess = [...rooms.slice(0, -1)];
      autoAdjustNumberOfBabiesToNumberOfAdults(roomsToProcess);
      setStateRooms(setButtonsEnabledOrDisabled(roomsToProcess));
    }
  };

  const deleteRoom = (index) => {
    const roomsToProcess = [...rooms].filter((e, i) => i !== index);
    autoAdjustNumberOfBabiesToNumberOfAdults(roomsToProcess);
    setStateRooms(setButtonsEnabledOrDisabled(roomsToProcess));
  };

  const getNumberOfAdults = (rooms) => {
    const numberOfAdults = rooms
      .map((e) => e.adults.valueOf())
      .reduce((a, b) => a + b);
    return numberOfAdults;
  };

  const getNumberOfChildren = (rooms) => {
    const numberOfChildren = rooms
      .map((e) => e.children.valueOf())
      .reduce((a, b) => a + b);
    return numberOfChildren;
  };

  const getNumberOfBabies = (rooms) => {
    const numberOfBabies = rooms
      .map((e) => e.babies.valueOf())
      .reduce((a, b) => a + b);
    return numberOfBabies;
  };

  const getNumberOfTravellers = (rooms) => {
    const numberOfTravellers = rooms
      .map((e) => e.adults.valueOf() + e.children.valueOf())
      .reduce((a, b) => a + b);
    return numberOfTravellers;
  };

  const setNumberOfTravellers = () => {
    const roomsToProcess = [...rooms];
    const numberOfAdults = getNumberOfAdults(roomsToProcess);
    const numberOfChildren = getNumberOfChildren(roomsToProcess);

    setStateNumberOfAdults(numberOfAdults);
    setStateNumberOfTravellers(numberOfAdults + numberOfChildren);
  };

  const setNumberOfTravellersPerRoom = (index, operator, travellers) => {
    switch (travellers) {
      case "adults":
        setNumberOfAdults(index, operator, index);
        break;
      case "children":
        setNumberOfChildren(index, operator, index);
        break;
      case "babies":
        setNumberOfBabies(index, operator, index);
        break;
      default:
        console.error("unknown travellers type");
    }
  };

  const setNumberOfAdults = (index, operator) => {
    const roomsToProcess = [...rooms];

    if (operator === "-" && roomsToProcess[index].adults > defaultRoom.adults) {
      roomsToProcess[index].adults -= 1;
      autoAdjustNumberOfBabiesToNumberOfAdults(roomsToProcess);
    } else if (
      operator === "+" &&
      !maxNumberOfTravellersPerRoomReached(
        roomsToProcess[index].adults,
        roomsToProcess[index].children
      )
    ) {
      if (numberOfTravellers < defaultValues.travellers.max) {
        roomsToProcess[index].adults += 1;
      }
    }

    setStateRooms(setButtonsEnabledOrDisabled(roomsToProcess));
  };

  const setNumberOfChildren = (index, operator) => {
    const roomsToProcess = [...rooms];

    if (
      operator === "-" &&
      roomsToProcess[index].children > defaultRoom.children
    ) {
      roomsToProcess[index].children -= 1;
    } else if (
      operator === "+" &&
      !maxNumberOfTravellersPerRoomReached(
        roomsToProcess[index].adults,
        roomsToProcess[index].children
      )
    ) {
      if (numberOfTravellers < defaultValues.travellers.max) {
        roomsToProcess[index].children += 1;
      }
    }

    setStateRooms(setButtonsEnabledOrDisabled(roomsToProcess));
  };

  const setNumberOfBabies = (index, operator) => {
    const roomsToProcess = [...rooms];
    const numberOfAdults = getNumberOfAdults(roomsToProcess);
    const numberOfBabies = getNumberOfBabies(roomsToProcess);

    if (operator === "-" && roomsToProcess[index].babies > defaultRoom.babies) {
      roomsToProcess[index].babies -= 1;
    } else if (operator === "+") {
      if (
        roomsToProcess[index].babies < defaultValues.travellersPerRoom.max &&
        numberOfBabies < numberOfAdults
      ) {
        roomsToProcess[index].babies += 1;
      }
    }

    setStateRooms(setButtonsEnabledOrDisabled(roomsToProcess));
  };

  const maxNumberOfTravellersReached = () => {
    return numberOfTravellers >= defaultValues.travellers.max;
  };

  const maxNumberOfTravellersPerRoomReached = (adults, children) => {
    return adults + children >= defaultValues.travellersPerRoom.max;
  };

  const autoAdjustNumberOfBabiesToNumberOfAdults = (rooms) => {
    rooms.map((room) => {
      if (room.babies > room.adults) {
        room.babies = room.adults;
      }
    });
  };

  const setButtonsEnabledOrDisabled = (rooms) => {
    const numberOfAdults = getNumberOfAdults(rooms);
    const numberOfBabies = getNumberOfBabies(rooms);
    const limitOfBabiesReached = numberOfBabies === numberOfAdults;

    rooms.forEach((room) => {
      const limitOfTravellersReached =
        maxNumberOfTravellersPerRoomReached(room.adults, room.children) ||
        maxNumberOfTravellersReached();

      if (
        room.adults > defaultRoom.adults &&
        room.adults < defaultValues.travellersPerRoom.max
      ) {
        room.buttons.adultsMinusButtonDisabled = false;
      } else {
        room.buttons.adultsMinusButtonDisabled =
          room.adults === defaultRoom.adults;
      }
      room.buttons.adultsPlusButtonDisabled = limitOfTravellersReached;

      if (
        room.children > defaultRoom.children &&
        room.adults < defaultValues.travellersPerRoom.max
      ) {
        room.buttons.childrenMinusButtonDisabled = false;
      } else {
        room.buttons.childrenMinusButtonDisabled =
          room.children === defaultRoom.children;
      }
      room.buttons.childrenPlusButtonDisabled = limitOfTravellersReached;

      if (room.babies > defaultRoom.babies && room.babies < room.adults) {
        room.buttons.babiesMinusButtonDisabled = false;
        room.buttons.babiesPlusButtonDisabled = limitOfBabiesReached;
      } else {
        room.buttons.babiesMinusButtonDisabled =
          room.babies === defaultRoom.babies;
        room.buttons.babiesPlusButtonDisabled =
          room.babies === room.adults || limitOfBabiesReached;
      }
    });

    return rooms;
  };

  const saveChanges = () => {
    localStorage.setItem("roomAllocation", JSON.stringify(rooms));
    updateRoomAllocation(JSON.parse(JSON.stringify(rooms)));
    setSearchParams((params) => {
      params.set(
        searchParamsEnum.roomAllocation,
        parseRoomAllocationToCode(rooms)
      );
      return params;
    });
  };

  return (
    <div className="traveller-rooms-component-wrapper">
      {/* Buttons */}
      <div className="button-container">
        <div className="button" onClick={closeTravellerRoomsComponent}>
          {contentfulButtons?.cancel}
        </div>
        <div className="button" onClick={saveChanges}>
          {contentfulButtons?.save}
        </div>
      </div>
      <div
        id="traveller-rooms-component"
        className="traveller-rooms-component"
        ref={containerRef}
      >
        {/* Info */}
        <div className="room-info">
          <div>
            <div className="info-icon"></div>
          </div>
          <div>{contentfulRoomAllocation?.roomInfoText}</div>
        </div>

        {/* Room selector */}
        <div className="room-selector">
          <div className="selector-text-container">
            <div className="room-selector-header">
              {contentfulRoomAllocation?.roomSelectorHeader}
            </div>
            <div className="room-selector-text">
              {contentfulRoomAllocation?.roomSelectorText}
            </div>
          </div>
          <div className="selector-numeric-stepper">
            <div
              className={
                rooms.length > defaultValues.rooms.min
                  ? "minus-button enabled"
                  : "minus-button disabled"
              }
              onClick={() => {
                setNumberOfRooms("-");
              }}
            >
              <div className="icon"></div>
            </div>
            <div className="value-indicator"> {rooms.length} </div>
            <div
              className={
                rooms.length < defaultValues.rooms.max &&
                numberOfAdults >= rooms.length &&
                numberOfTravellers < defaultValues.travellers.max
                  ? "plus-button enabled"
                  : "plus-button disabled"
              }
              onClick={() => {
                setNumberOfRooms("+");
              }}
            >
              <div className="icon"></div>
            </div>
          </div>
        </div>

        {/* Traveller selector */}
        <>
          {rooms.map((room, index) => {
            return (
              <div className="traveller-selector-container" key={index}>
                <TravellerSelectorComponent
                  contentfulButtons={contentfulButtons}
                  contentfulRoomAllocation={contentfulRoomAllocation}
                  index={index}
                  room={room}
                  deleteRoom={
                    rooms.length > defaultValues.rooms.min ? deleteRoom : null
                  }
                  setNumberOfTravellersPerRoom={setNumberOfTravellersPerRoom}
                />
              </div>
            );
          })}
        </>

        {/* Buttons only desktop */}
        <div className="button-container-desktop">
          <div className="button" onClick={closeTravellerRoomsComponent}>
            {contentfulButtons?.cancel}
          </div>
          <div className="button" onClick={saveChanges}>
            {contentfulButtons?.save}
          </div>
        </div>
      </div>
    </div>
  );
};
