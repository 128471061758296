import "./calamity-header.component.scss";
import { Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { SessionStorageService } from "../../services/session-storage.service";
import { SessionStorageEnum } from "../../common/enums/sessionStorageEnum";
import WarningIcon from '@mui/icons-material/Warning';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

export const CalamityHeader = ({headerContent}) => {
  const [showCalamityHeader, setShowCalamityHeader] = useState(
    SessionStorageService.getSessionStorageItem(
      SessionStorageEnum.SHOW_CALAMITY_HEADER
    )
  );
  useEffect(() => {
    if (
      !SessionStorageService.getSessionStorageItem(
        SessionStorageEnum.SHOW_CALAMITY_HEADER
      )
    ) {
      setShowCalamityHeader(true);
      SessionStorageService.setSessionStorageItem(true);
    } else {
      setShowCalamityHeader(
        JSON.parse(
          SessionStorageService.getSessionStorageItem(
            SessionStorageEnum.SHOW_CALAMITY_HEADER
          )
        )
      );
    }
  }, []);

  const handleOnClose = () => {
    SessionStorageService.setSessionStorageItem(
      SessionStorageEnum.SHOW_CALAMITY_HEADER,
      false
    );
    setShowCalamityHeader(false);
  };

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ),
    },
  };

  return (
    showCalamityHeader && (headerContent && headerContent[0]?.fields?.calamityHeader) && (
      <div className="calamity-component">
        <div className="calamity-header">
          <div className="calamity-container">
            <div className="alert-data">
              <Alert severity="warning" icon={<WarningIcon />}>
                {documentToReactComponents(headerContent && headerContent[0]?.fields?.calamityHeader, options)}
              </Alert>
            </div>
            <button
              className="calamity-close-button"
              onClick={() => handleOnClose()}
            >
              <CloseIcon />
            </button>
          </div>
        </div>
      </div>
    )
  );
};
