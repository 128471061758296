import { useEffect, useState } from "react";
import { FlagComponent } from "../../../stories/components/flag/flag.component";

export const HotelLocationComponent = ({ data, showFlag }) => {
  const [locationData, setLocationData] = useState(null);
  
  useEffect(() => {
    setLocationData(data)
  },[data]);

  if (!locationData) return null;

  const isHotelDataPresent = locationData.hotel && locationData.hotel.location;

  let country, city, countryDescription;

  if (isHotelDataPresent) {
    country = locationData.hotel.location.countryCode;
    city = locationData.hotel.location.city;
    countryDescription = locationData.hotel.location.countryDescription;
  } else {
    country = locationData.countryCode;
    city = locationData.location;
    countryDescription = locationData.countryDescription;
  }

  return (
    <div className="hotel-location-component">
      {showFlag && country && (
        <FlagComponent country={country} size={"small"} />
      )}
      <span>
        {countryDescription ? countryDescription + " - " : ""}
        {city}
      </span>
    </div>
  );
};
