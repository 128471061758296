import "./departure-dropdown.styles.scss";
import { useEffect, useRef, useState } from "react";
import config from "../../../environments/config.json";
import { affiliates } from "../../../common/enums/affiliates";
import { LocalStorageEnum } from "../../../common/enums/localStorageEnum";
import { LocalStorageService } from "../../../services/local-storage.service";
import { GetAffiliate } from "../../../services/general.service";
import { useSearchParams } from "react-router-dom";
import { searchParamsEnum } from "../../../common/enums/searchParamsEnum";

export const DepartureDropdownComponent = ({
  optionList,
  contentfulButtons,
  isMobile,
  departureLocationsDeepLinkParam,
  isResultsPage,
}) => {
  const containerRef = useRef(null);
  const [selectedDeparture, setSelectedDeparture] = useState({
    selectedByUser: true,
    departureLocationCode: "AMS",
    departureLocationName: "Amsterdam, (AMS), Nederland",
  });
  const [tempSelectedDepartureForMobile, setTempSelectedDepartureForMobile] =
    useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (departureLocationsDeepLinkParam) {
      setSelectedDeparture(departureLocationsDeepLinkParam);
      setTempSelectedDepartureForMobile(departureLocationsDeepLinkParam);
    }
    if (GetAffiliate() === affiliates.klm) {
      setSearchParams((params) => {
        params.set(
          searchParamsEnum.departureLocation,
          selectedDeparture.departureLocationCode
        );
        return params;
      });
    }
  }, [departureLocationsDeepLinkParam]);

  useEffect(() => {
    if (optionList) {
      if (GetAffiliate() === affiliates.transavia && isResultsPage) {
        if (!optionList.find((option) => option.departureLocationCode === ""))
          optionList?.push({
            selectedByUser: false,
            departureLocationCode: "",
            departureLocationName: "",
          });
        if (contentfulButtons) {
          optionList.find(
            (option) => option.departureLocationCode === ""
          ).departureLocationName =
            contentfulButtons?.departureLocationSelectAll;
        }
      }
      if (!departureLocationsDeepLinkParam) {
        if (GetAffiliate() === affiliates.transavia) {
          setSelectedDeparture({
            selectedByUser: true,
            departureLocationCode: "",
            departureLocationName:
              contentfulButtons?.departureLocationSelectAll,
          });
          setTempSelectedDepartureForMobile({
            selectedByUser: true,
            departureLocationCode: "",
            departureLocationName:
              contentfulButtons?.departureLocationSelectAll,
          });
          LocalStorageService.setLocalStorageItem(
            LocalStorageEnum.DEPARTURE_LOCATION,
            selectedDeparture.departureLocationCode
          );
        }
        if (GetAffiliate() === affiliates.klm) {
          setSelectedDeparture({
            selectedByUser: true,
            departureLocationCode: "AMS",
            departureLocationName: "Amsterdam, (AMS), Nederland",
          });
          setTempSelectedDepartureForMobile({
            selectedByUser: true,
            departureLocationCode: "AMS",
            departureLocationName: "Amsterdam, (AMS), Nederland",
          });
        }
      } else {
        setSearchParams((params) => {
          params.set(
            searchParamsEnum.departureLocation,
            departureLocationsDeepLinkParam.departureLocationCode
          );
          return params;
        });
      }
    }
  }, [optionList, contentfulButtons]);

  useEffect(() => {
    if (!isMobile) {
      const handleClickOutside = (event) => {
        if (
          containerRef.current &&
          !containerRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      };
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }
  }, [containerRef]);

  const selectDepartureLocation = (option) => {
    if (isMobile) {
      setTempSelectedDepartureForMobile(option);
    } else {
      setIsOpen(false);
      setSelectedDeparture(option);
      setSearchParams((params) => {
        params.set(
          searchParamsEnum.departureLocation,
          option.departureLocationCode
        );
        return params;
      });
      LocalStorageService.setLocalStorageItem(
        LocalStorageEnum.DEPARTURE_LOCATION,
        option.departureLocationCode
      );
    }
  };

  const handleOpenDropdown = () => {
    setIsOpen(!isOpen);
    if (isMobile) {
      setTempSelectedDepartureForMobile(selectedDeparture);
    }
  };
  const handleOnSave = () => {
    if (isMobile) {
      setSelectedDeparture(tempSelectedDepartureForMobile);
      setSearchParams((params) => {
        params.set(
          searchParamsEnum.departureLocation,
          tempSelectedDepartureForMobile.departureLocationCode
        );
        return params;
      });
      LocalStorageService.setLocalStorageItem(
        LocalStorageEnum.DEPARTURE_LOCATION,
        tempSelectedDepartureForMobile.departureLocationCode
      );
    } else {
      setSelectedDeparture(selectedDeparture);
      setSearchParams((params) => {
        params.set(
          searchParamsEnum.departureLocation,
          selectedDeparture.departureLocationCode
        );
        return params;
      });
    }
    setIsOpen(false);
  };

  const handleIsMobileSelection = (option) => {
    return isMobile
      ? option?.departureLocationName ===
          tempSelectedDepartureForMobile?.departureLocationName
      : option?.departureLocationName ===
          selectedDeparture?.departureLocationName;
  };
  return (
    <>
      <div
        className="departure-input"
        onClick={handleOpenDropdown}
        ref={containerRef}
      >
        <div className="input-placeholder">
          {selectedDeparture && selectedDeparture?.departureLocationName}
        </div>
        <div className={`dropdown-caret ${isOpen && "rotate"}`}></div>
      </div>
      <div className="departure-dropdown-component">
        {isOpen && (
          <div className="departure-dropdown-container">
            <div className="departure-button-container">
              <div className="button" onClick={handleOpenDropdown}>
                {contentfulButtons?.cancel}
              </div>
              <div className="button" onClick={handleOnSave}>
                {contentfulButtons?.save}
              </div>
            </div>

            <div className="departure-options-container">
              {(optionList
                ? optionList
                : [departureLocationsDeepLinkParam]
              ).map((option) => (
                <div
                  className={`departure-row-content ${
                    handleIsMobileSelection(option) &&
                    "selected-in-dropdown-row"
                  }`}
                  key={option.departureLocationCode}
                  onClick={() => selectDepartureLocation(option)}
                >
                  {handleIsMobileSelection(option) && (
                    <div className="departure-selection-highlight-bar"></div>
                  )}
                  <div className="selected-row">
                    {option.departureLocationName}{" "}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
