import { createSlice } from '@reduxjs/toolkit';

export const roomSlice = createSlice({
  name: 'room',
  initialState: {
    descriptions: [],
  },
  reducers: {
    setRoomDescriptions: (state, action) => {
      state.descriptions = action.payload;
    },
  },
});

export const { setRoomDescriptions } = roomSlice.actions;

export default roomSlice;
