import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { contentType } from "../../common/constants/contentType";
import { affiliates } from "../../common/enums/affiliates";
import { getContentfulByContentType } from "../../services/contentful.service";
import { GetAffiliate, GetLogoPath } from "../../services/general.service";
import ContentfulContext from "../../store/contentful/contentful-context";

import DepartureFlightIcon from "../../assets/icons/klm/departure-flight.svg";
import EarthIcon from "../../assets/icons/klm/earth-icon.svg";
import FlightConnector from "../../assets/icons/klm/flight-connector-payment.svg";
import FlightNumber from "../../assets/icons/klm/flight-number.svg";
import FlightType from "../../assets/icons/klm/flight-type.svg";
import FlightConnectorTransaviaDirect from "../../assets/icons/transavia/direct-flight-transavia.svg";

import FlightConnectorStop from "../../assets/icons/klm/connector-flight-stop.svg";
import FlightConnectorTransavia from "../../assets/icons/transavia/flight-connector.svg";
import EarthIconTransavia from "../../assets/icons/transavia/transavia_earth_icon.svg";
import FlightTypeTransavia from "../../assets/icons/transavia/transavia_flight_icon.svg";
import FlightNumberTransavia from "../../assets/icons/transavia/transavia_flight_nr_icon.svg";
import FlightConnectorStopTransavia from "../../assets/icons/transavia/transfer-transavia.svg";
import TransferBox from "../transfer-box/transfer-box";
import "./flight-details.scss";

const FlightSegment = ({
  segment,
  affiliate,
  flightDetailsPageContent,
  data,
}) => {
  const numberOfTransfers = data?.numberOfTransfers || 1;
  const ONE_TRANSFER = 1;
  const YEAR = 4;

  const formatAirportString = (airportString) => {
    return airportString.replace(/(,)? \(/g, ", (");
  };

  const holidayHeaderData = useSelector(
    (state) => state.holidayHeader.holidayHeader
  );

  return (
    <div className="expanded-info">
      <div className="expanded-connector">
        {numberOfTransfers < ONE_TRANSFER &&
          (affiliate === affiliates.klm ? (
            <img src={FlightConnector} alt="Flight connector" />
          ) : affiliate === affiliates.transavia ? (
            <img src={FlightConnectorTransavia} alt="Flight connector" />
          ) : null)}

        <div className="connector-time">
          <div className="departure-time">
            <p className="time-data">
              {segment.departureTime}{" "}
              <span className="full-date">{segment.departureDate}</span>
            </p>
          </div>
          <div className="arrival-time">
            <p className="time-data">
              {segment.arrivalTime}
              <span className="full-date">{segment.arrivalDate}</span>
            </p>
          </div>
        </div>
      </div>

      <div className="expanded-flight">
        <div className="expanded-flight-container">
          <div className="expanded-icons">
            {affiliate === affiliates.klm ? (
              <>
                <Tooltip
                  title={formatAirportString(segment.departureAirport)}
                  arrow
                >
                  <span className="airport">
                      {formatAirportString(segment.departureAirport)}
                  </span>
                </Tooltip>

                <div className="data-container">
                  <div className="first-part-data">
                    <div className="fl-number-box">
                      <img src={FlightNumber} alt="Flight number" />
                      <Tooltip title={segment.flightNumber} arrow>
                        <span className="fl-number">
                          {segment.flightNumber}
                        </span>
                      </Tooltip>
                    </div>
                    <div className="distance-box">
                      <img src={EarthIcon} alt="Earth icon" />
                      <Tooltip title={segment.distanceInKm} arrow>
                        <span className="ar-type">{segment.distanceInKm}</span>
                      </Tooltip>
                    </div> 
                  </div>
                  <div className="second-part-data">
                     <div className="operator-box">
                      <img src={FlightType} alt="Flight type" />
                      <Tooltip title={segment.aircraftType} arrow>
                        <span className="operator">{segment.aircraftType}</span>
                      </Tooltip>
                    </div>
                    <div className="operator-box">
                      <img src={DepartureFlightIcon} alt="Departure flight" />
                      <Tooltip title={segment.operatingAirline} arrow>
                        <span className="operator">
                          {flightDetailsPageContent &&
                            flightDetailsPageContent[0]?.fields
                              ?.operatedBy}{" "}
                          {segment.operatingAirline}
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <Tooltip
                  title={formatAirportString(segment.arrivalAirport)}
                  arrow
                >
                  <span className="airport">
                      {formatAirportString(segment.arrivalAirport)}
                  </span>
                </Tooltip>
              </>
            ) : affiliate === affiliates.transavia ? (
              <>
                <Tooltip
                  title={formatAirportString(segment.departureAirport)}
                  arrow
                >
                  <span className="airport">
                      {formatAirportString(segment.departureAirport)}
                  </span>
                </Tooltip>

                <div className="data-container">
                  <div className="flight-info">
                    <div className="flight-box-line">
                      <div className="flight-number">
                        <img src={FlightNumberTransavia} alt="Flight number" />
                        <span>{segment.flightNumber}</span>
                      </div>
                      <div className="distance-info">
                        <img src={EarthIconTransavia} alt="Earth icon" />
                        <span>{segment.distanceInKm}</span>
                      </div>
                    </div>
                    <div className="flight-type">
                      <img src={FlightTypeTransavia} alt="Flight type" />
                      <span>{segment.aircraftType}</span>
                    </div>
                  </div>
                </div>
                <Tooltip
                  title={formatAirportString(segment.arrivalAirport)}
                  arrow
                >
                  <span className="airport">
                      {formatAirportString(segment.arrivalAirport)}
                  </span>
                </Tooltip>
              </>
            ) : null}
          </div>
        </div>
      </div>
      {affiliate === affiliates.klm && (
        <div className="logo-container">
          <img
            src={GetLogoPath(
              data?.airlineCode ?? data?.segments[0]?.airlineCode
            )}
            alt="Company Logo"
          />
        </div>
      )}
    </div>
  );
};

const FlightDetails = ({ data, isHoliday }) => {
  const contentfulEntries = useContext(ContentfulContext);
  const [flightDetailsPageContent, setFlightDetailsPageContent] = useState();
  const numberOfTransfers = data?.numberOfTransfers;
  const numberOfStops = data?.numberOStops;
  const stopInfoText = data?.flightStopsDetails?.flightType;
  const stopInBoundtext = data?.segments[0]?.showTechnicalStopLabel;
  const stopOutBoundText = data.segments[1]?.showTechnicalStopLabel;
  const hasSegments = (data?.segments?.length || 0) > 0;
  const ONE_TRANSFER_DETAILS = 1;
  const TWO_TRANSFERS_DETAILS = 2;
  const stopTextType = "oneTechnicalStop";

  const getFlightDetailsPageContent = () => {
    const contentfulResultsPageResponse = getContentfulByContentType(
      contentfulEntries,
      contentType.GENERAL
    );
    setFlightDetailsPageContent(contentfulResultsPageResponse);
  };

  useEffect(() => {
    getFlightDetailsPageContent();
  }, [contentfulEntries]);

  return (
    <div
      className={isHoliday ? "flight-details holiday-flight" : "flight-details"}
    >
      <p className="flight-destination">{`${data?.legOrigin} ${
        flightDetailsPageContent &&
        flightDetailsPageContent[0]?.fields?.destinationTo
      } ${data?.legDestination}`}</p>

      <div
        className={
          numberOfTransfers > 0 ||
          stopInfoText === stopTextType ||
          stopInBoundtext === true ||
          stopOutBoundText === true ||
          numberOfStops > 0
            ? "segments-container multi-segments" +
              (numberOfTransfers === TWO_TRANSFERS_DETAILS
                ? " two-transfers"
                : "")
            : "segments-container"
        }
      >
        {hasSegments && (
          <div className="container-stop">
            <div className="global-connector">
              {GetAffiliate() === affiliates.klm ? (
                <img
                  src={
                    numberOfTransfers > 0 ||
                    stopInfoText === stopTextType ||
                    stopInBoundtext === true ||
                    stopOutBoundText === true ||
                    numberOfStops > 0
                      ? FlightConnectorStop
                      : FlightConnector
                  }
                  alt="Flight connector"
                  className="global-img-connector"
                />
              ) : GetAffiliate() === affiliates.transavia ? (
                <img
                  src={
                    numberOfTransfers > 0 ||
                    stopInfoText === stopTextType ||
                    stopInBoundtext === true ||
                    stopOutBoundText === true ||
                    numberOfStops > 0
                      ? FlightConnectorStopTransavia
                      : FlightConnectorTransaviaDirect
                  }
                  alt="Flight connector"
                  className="global-img-connector"
                />
              ) : null}
            </div>

            {(numberOfTransfers > 0 ||
              stopInfoText === stopTextType ||
              numberOfStops > 0) && (
              <TransferBox
                waitingTimeH={data.segments[0].waitingTimeHours}
                waitingTimeM={data.segments[0].waitingTimeMinutes}
                text={
                  stopInfoText === stopTextType ||
                  stopInBoundtext === true ||
                  stopOutBoundText === true
                    ? flightDetailsPageContent &&
                      flightDetailsPageContent[0]?.fields.technicalStop
                    : numberOfTransfers >= 1
                    ? flightDetailsPageContent &&
                      flightDetailsPageContent[0]?.fields?.transfer
                    : ""
                }
              />
            )}
          </div>
        )}

        <div className="multiple-expanded-info">
          {data?.segments?.map((segment, index) => (
            <FlightSegment
              key={index}
              segment={segment}
              affiliate={GetAffiliate()}
              flightDetailsPageContent={flightDetailsPageContent}
              data={data}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

FlightDetails.propTypes = {
  data: PropTypes.shape({
    legDestination: PropTypes.string.isRequired,
    legOrigin: PropTypes.string.isRequired,
    segments: PropTypes.arrayOf(
      PropTypes.shape({
        departureTime: PropTypes.string.isRequired,
        arrivalTime: PropTypes.string.isRequired,
        departureAirport: PropTypes.string.isRequired,
        flightNumber: PropTypes.string.isRequired,
        isHoliday: PropTypes.bool,
        aircraftType: PropTypes.string.isRequired,
        distanceInKm: PropTypes.string.isRequired,
        airlineName: PropTypes.string.isRequired,
        arrivalAirport: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default FlightDetails;
