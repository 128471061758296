import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./payment-hotel-summary-overview.component.scss";
import { Tooltip } from "@mui/material";
import { StarRatingComponent } from "../star-rating/star-rating.component";
import { TripadvisorRatingComponent } from "../tripadvisor-rating/tripadvisor-rating.component";
import { ReactComponent as LocationIconKLM } from "../../assets/icons/klm/location-marker-S.svg";
import { ReactComponent as LocationIconTransavia } from "../../assets/icons/transavia/location-marker-transavia.svg";
import { ReactComponent as PassengersIconKLM } from "../../assets/icons/klm/passengers-S.svg";
import { ReactComponent as PassengersIconTransavia } from "../../assets/icons/transavia/Avatar.svg";
import { ReactComponent as HotelIconKLM } from "../../assets/icons/klm/hotel-S.svg";
import { ReactComponent as HotelIconTransavia } from "../../assets/icons/transavia/Hotel.svg";
import { ReactComponent as ClockIconKLM } from "../../assets/icons/klm/clock-S.svg";
import { ReactComponent as ClockIconTransavia } from "../../assets/icons/transavia/Time.svg";
import { GetAffiliate } from "../../services/general.service";
import { affiliates } from "../../common/enums/affiliates";

export const PaymentHotelSummaryOverviewComponent = ({
  contentfulPaymentPage,
  contentfulRoomAllocation,
  checkOutSummary,
  isOrderBookingFailed,
}) => {
  const roomStateData = useSelector((state) => state.roomsData.descriptions);
  const [checkOutSummaryData, setcheckOutSummaryData] = useState(checkOutSummary);

  return (
    <div
      className={`payment-hotel-summary-overview-component ${
        isOrderBookingFailed ? "fail-booking-blur" : ""
      }`}
    >
      <div className="payment-hotel-summary-header">
        {contentfulPaymentPage &&
          contentfulPaymentPage[0]?.fields?.tripOverviewTitle +
            " " +
            checkOutSummaryData?.destination}
      </div>
      <div className="payment-hotel-summary-container">
        <div className="image-container">
          <img src={checkOutSummaryData?.hotelDetails.hotelImage} />
        </div>
        <div className="description-container">
          <div className="hotel-info">
            <Tooltip title={checkOutSummaryData?.hotelDetails.hotelName} arrow>
              <div className="header">
                {checkOutSummaryData?.hotelDetails.hotelName}
              </div>
            </Tooltip>
            <div className="content">
              <div className="rating">
                <div className="star-rating-container">
                  {checkOutSummaryData?.hotelDetails.starRating ? (
                    <StarRatingComponent
                      data={checkOutSummaryData?.hotelDetails.starRating}
                    />
                  ) : null}
                </div>
                <div className="tripadvisor-rating-container">
                  <>
                    <div className="tripadvisor-logo-container"></div>
                    {checkOutSummaryData?.hotelDetails.tripAdvisorRating ? (
                      <TripadvisorRatingComponent
                        data={checkOutSummaryData?.hotelDetails.tripAdvisorRating}
                      />
                    ) : null}
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="description-items">
            <div className="row">
              <div className="row-container">
                {GetAffiliate() === affiliates.klm ? (
                  <LocationIconKLM className={`payment-icon`} />
                ) : (
                  <LocationIconTransavia className={`payment-icon`} />
                )}
                <div className="row-title">
                  {contentfulPaymentPage &&
                    contentfulPaymentPage[0]?.fields?.address}
                </div>
              </div>
              <div className="row-description">
                <Tooltip
                  title={checkOutSummaryData?.hotelDetails.hotelAddress}
                  arrow
                >
                  <div className="header-description">
                    {checkOutSummaryData?.hotelDetails.hotelAddress}
                  </div>
                </Tooltip>
              </div>
            </div>
            <div className="row">
              <div className="row-container">
                {GetAffiliate() === affiliates.klm ? (
                  <PassengersIconKLM className={`payment-icon`} />
                ) : (
                  <PassengersIconTransavia className={`payment-icon`} />
                )}
                <div className="row-title">
                  {contentfulPaymentPage &&
                    contentfulPaymentPage[0]?.fields?.persons}
                </div>
              </div>
              <div className="row-description">
                {checkOutSummaryData?.hotelDetails.numberOfTravellers + " "}
                {contentfulRoomAllocation &&
                  contentfulRoomAllocation[0]?.fields?.traveller}
                {", "}
                {checkOutSummaryData?.hotelDetails.numberOfRooms + " "}
                {contentfulRoomAllocation &&
                  contentfulRoomAllocation[0]?.fields?.travellerSelectorHeader}
              </div>
            </div>

            <div className="row">
              <div className="row-container">
                {GetAffiliate() === affiliates.klm ? (
                  <HotelIconKLM className={`payment-icon`} />
                ) : (
                  <HotelIconTransavia className={`payment-icon`} />
                )}
                <div className="row-title">
                  {contentfulPaymentPage &&
                    contentfulPaymentPage[0]?.fields?.roomType}
                </div>
              </div>
              <div className="row-description">
                <Tooltip
                  title={roomStateData && roomStateData.length > 0 && 
                    roomStateData.map((item, index) => (
                      <div key={index}>{item}</div>
                    ))
                  }
                  arrow
                >
                <div className="header-description">
                  {roomStateData && roomStateData.length > 0 && 
                    roomStateData.map((item, index) => (
                      <div className="sub-description" key={index}>{item}</div>
                    ))
                  }
                </div>
                </Tooltip>
              </div>
            </div>
            <div className="row">
              <div className="row-container">
                {GetAffiliate() === affiliates.klm ? (
                  <ClockIconKLM className={`payment-icon`} />
                ) : (
                  <ClockIconTransavia className={`payment-icon`} />
                )}
                <div className="column-container">
                  <div className="column">
                    <div className="column-title">
                      {contentfulPaymentPage &&
                        contentfulPaymentPage[0]?.fields?.checkIn}
                    </div>
                    <div className="column-description">
                      {checkOutSummaryData?.hotelDetails.checkInDate}
                    </div>
                  </div>
                  <div className="column">
                    <div className="column-title">
                      {contentfulPaymentPage &&
                        contentfulPaymentPage[0]?.fields?.checkOut}
                    </div>
                    <div className="column-description">
                      {checkOutSummaryData?.hotelDetails.checkOutDate}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
