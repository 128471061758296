export const passengerDetailsEnum = {
    GENDER_TITLE: 'GENDER_TITLE',
    FIRST_NAME: 'FIRST_NAME',
    LAST_NAME: 'LAST_NAME',
    BORN_DAY: 'BORN_DAY',
    BORN_MONTH: 'BORN_MONTH',
    BORN_YEAR: 'BORN_YEAR',
    IS_FREQUENT_FLYER: 'IS_FREQUENT_FLYER',
    FREQUENT_FLYER_SOCIETY: 'FREQUENT_FLYER_SOCIETY',
    FREQUENT_FLYER_CARD_NUMBER: 'FREQUENT_FLYER_CARD_NUMBER',
    EMAIL: 'E-MAIL',
    MOBILE_COUNTRY_CODE: 'MOBILE_COUNTRY_CODE',
    MOBILE_PHONE_NUMBER: 'MOBILE_PHONE_NUMBER',
    STREET_NAME: 'STREET_NAME',
    HOUSE_NUMBER: 'HOUSE_NUMBER',
    NUMBER_EXTENSION: 'NUMBER_EXTENSION',
    ZIP_CODE: 'ZIP_CODE',
    PLACE: 'PLACE',
    COUNTRY: 'COUNTRY',
    COMPANY: 'COMPANY',
    BLUE_BIZ: 'BLUE_BIZ',
}

export const frequentFlyerPreferredAirlineCode = "KL"
