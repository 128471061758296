import "./hotel-map.component.scss";
import { Wrapper } from "@googlemaps/react-wrapper";
import config from './../../environments/config.json'
import { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { HotelMapMarkerComponent } from "./marker/hotel-map-marker.component";
import { GoogleMapConst } from "../../common/enums/googleMap";

const HotelMapComponent = ({contentfulHolidayPage, hotelPosition}) =>  {
  return (
    <div className="hotel-map-component">
      <Wrapper
        apiKey={config.GM_KEY}
        version="beta"
        libraries={["marker"]}
      >
          <HotelMap hotelPosition={hotelPosition} contentfulHolidayPage={contentfulHolidayPage}/>
      </Wrapper>
    </div>
  );
}

const HotelMap = ({hotelPosition}) => {
  const [map, setMap] = useState();
  const ref = useRef();

  const mapOptions = {
    mapId: config.GOOGLE_MAP_ID,
    fullscreenControl: true,
    center: hotelPosition,
    zoom: GoogleMapConst.ZOOM.LEVEL_12,
    zoomControl: true,
    streetViewControl:true,
    disableDefaultUI: true,
    controlSize: 24,
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: window?.google?.maps?.MapTypeControlStyle?.HORIZONTAL_BAR,
      position: window?.google?.maps?.ControlPosition?.BOTTOM_LEFT,
      mapTypeIds: [
        window.google?.maps?.MapTypeId?.ROADMAP,
        window.google?.maps?.MapTypeId?.SATELLITE,
        window.google?.maps?.MapTypeId?.TERRAIN,
      ],
    },
  };

  useEffect(() => {
    setMap(new window.google.maps.Map(ref.current, mapOptions));
  }, []);

  return (
    <>
      <div ref={ref} id="map" />
      {map && <Markers map={map} hotelPosition={hotelPosition}/>}
    </>
  );
}

const Markers = ({ map, hotelPosition }) => {
  const markerAlt ="map-marker";

  return (
    <>
     {hotelPosition &&
        <Marker
          map={map}
          position={hotelPosition}
        >
            <img alt={markerAlt} src={HotelMapMarkerComponent()}/>
        </Marker>}
    </>
  );
}

const Marker = ({ map, position, children, onClick }) => {
  const rootRef = useRef();
  const markerRef = useRef();

  useEffect(() => {
    if (!rootRef.current) {
      const container = document.createElement("div");
      rootRef.current = createRoot(container);

      markerRef.current = new window.google.maps.marker.AdvancedMarkerView({
        position,
        content: container,
      });
    }

    return () => (markerRef.current.map = null);
  }, []);

  useEffect(() => {
    rootRef.current.render(children);
    markerRef.current.position = position;
    markerRef.current.map = map;
    const listener = markerRef.current.addListener("gmp-click", onClick);
    return () => listener.remove();
  }, [map, position, children, onClick]);
}

export default HotelMapComponent;
