import './pending-booking.component.scss';
import {parseContentfulDynamicContent, renderDocument} from "../../../services/contentful.service";
import ErrorIcon from '@mui/icons-material/Error';

export const PendingBookingComponent = ({contentfulConfirmationPage, destinationCity}) => {

    return <div className="pending-booking-component">
        <div className="pending-booking-header">
            <ErrorIcon />
            {contentfulConfirmationPage &&
            parseContentfulDynamicContent(contentfulConfirmationPage[0]?.fields?.pendingBookingHeader, destinationCity)}
        </div>
        <div className="pending-booking-body">
            {contentfulConfirmationPage && renderDocument(contentfulConfirmationPage[0]?.fields?.pendingBookingBody)}
        </div>
    </div>
}
