import React, { useState, useEffect, useRef } from "react";
import "./dropdown.scss";

export const DropdownComponent = (
  { children, hasOwnInput, defaultPlaceholder, useFormStlying },
  props
) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [containerRef]);

  return (
    <div className="dropdown-component" onClick={toggleDropdown}>
      <div
        ref={containerRef}
        className={`dropdown-container ${isOpen ? "dropdown-active" : ""}`}
      >
        <span
          className={`dropdown-text ${
            useFormStlying ? " dropdown-text-form-styling" : ""
          }`}
        >
          {hasOwnInput ? (
            children
          ) : (
            <span className={isOpen ? "placeholder-bold" : ""}>
              {defaultPlaceholder}
            </span>
          )}
        </span>

        {!hasOwnInput &&
          ((isOpen && <div className="dropdown-caret rotate"></div>) ||
            (!isOpen && <div className="dropdown-caret"></div>))}

        {isOpen && !hasOwnInput && (
          <>
            <div className="dropdown-options">{children}</div>
            <div className="arrow-indicator" />
          </>
        )}
      </div>
    </div>
  );
};
