import { modalActions } from "../store/modal/action";
import { ErrorCode } from "../common/enums/errorCodes";

const errorsToIgnore = [
  ErrorCode.VOUCHER_NOT_FOUND,
  ErrorCode.VOUCHER_ALREADY_SELECTED,
];

const ignoreError = (apiError) => {
  return errorsToIgnore.some((error) => apiError === error);
};

const onHolidayNotFound = (searchResult, store) => {
  store.dispatch(
    modalActions.showModal({
      reasonCode: searchResult.reasonCode,
      redirectUrl: searchResult.navigateToHolidayList,
    })
  );
  return Promise.reject();
};

const dispatchErrorInfo = (reasonCode, store) => {
  store.dispatch(modalActions.showModal({ reasonCode: reasonCode }));
  return Promise.reject();
};

const handleResponse = (response, store) => {
  const searchValidationInfo = {
    defaultSearchUsed:
      response?.data?.searchInfo?.searchValidationInfo?.defaultSearchUsed,
    informUser: response?.data?.searchInfo?.searchValidationInfo?.informUser,
    reasonCode: response?.data?.searchInfo?.searchValidationInfo?.reasonCode,
    searchChanged:
      response?.data?.searchInfo?.searchValidationInfo?.searchChanged,
  };

  const searchResult = {
    holidayFound: response?.data?.metaInfo?.holidayFound,
    navigateToHolidayList: response?.data?.metaInfo?.navigateToHolidayList,
    informUser: response?.data?.metaInfo?.searchValidationInfo?.informUser,
    reasonCode: response?.data?.metaInfo?.searchValidationInfo?.reasonCode,
  };

  if (!searchResult.holidayFound && searchResult.informUser) {
    return onHolidayNotFound(searchResult, store);
  }

  if (searchValidationInfo.informUser) {
    switch (searchValidationInfo.reasonCode) {
      case ErrorCode.MINIMUM_DAYS_AHEAD:
        dispatchErrorInfo(searchValidationInfo.reasonCode, store);
        break;
      default:
        return response;
    }
  } else if (searchResult.informUser) {
    switch (searchResult.reasonCode) {
      case ErrorCode.MINIMUM_DAYS_AHEAD:
        dispatchErrorInfo(searchResult.reasonCode, store);
        break;
      default:
        return response;
    }
  }

  return response;
};

const handleError = (error, store) => {
  if (ignoreError(error.response.data.code)) {
    return Promise.reject(error);
  } else {
    dispatchErrorInfo(error.response.data.code, store);
    return Promise.reject(error);
  }
};

export default function ErrorInterceptorService(axios, store) {
  axios.interceptors.response.use(
    (response) => {
      return handleResponse(response, store);
    },
    (error) => {
      return handleError(error, store);
    }
  );
}
