import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { breakpoints } from "../../../common/constants/breakpoints";
import { affiliates } from "../../../common/enums/affiliates";
import { LocalStorageEnum } from "../../../common/enums/localStorageEnum";
import { GetMealType } from "../../../common/enums/meal-types";
import { travellerType } from "../../../common/enums/travellerType";
import { useWindowSizeDetector } from "../../../hooks/useWindowSizeDetector";
import { GetAffiliate } from "../../../services/general.service";
import { LocalStorageService } from "../../../services/local-storage.service";
import { setRoomDescriptions } from "../../../store/rooms/slice";
import { ReactComponent as IconTravelKLM } from "./../../../assets/icons/klm/calendar-S.svg";
import { ReactComponent as IconRoomTypeKLM } from "./../../../assets/icons/klm/hotel-S.svg";
import { ReactComponent as IconMealKLM } from "./../../../assets/icons/klm/meals-S.svg";
import { ReactComponent as IconPassengerKLM } from "./../../../assets/icons/klm/passengers-S.svg";
import { ReactComponent as IconLocationMarkerKLM } from "./../../../assets/icons/location-marker-S.svg";
import { ReactComponent as IconPassengerTransavia } from "./../../../assets/icons/transavia/Avatar.svg";
import { ReactComponent as IconTravelTransavia } from "./../../../assets/icons/transavia/Calendar.svg";
import { ReactComponent as IconRoomTypeTransavia } from "./../../../assets/icons/transavia/Hotel.svg";
import { ReactComponent as IconLocationMarkerTransavia } from "./../../../assets/icons/transavia/location-marker-transavia.svg";
import { ReactComponent as IconMealTransavia } from "./../../../assets/icons/transavia/meals-S.svg";
import "./holiday-summary-hotel-overview.component.scss";

export const HolidaySummaryHotelOverviewComponent = ({
  contentfulHolidayPageSummary,
  contentfulRoomAllocation,
  detailedHoliday,
}) => {
  const roomAllocation = JSON.parse(
    LocalStorageService.getLocalStorageItem(LocalStorageEnum.ROOM_ALLOCATION)
  );
  const dispatch = useDispatch();
  const roomStateData = useSelector((state) => state.roomsData.descriptions);
  const windowSizeDetector = useWindowSizeDetector();
  const windowSize = useWindowSizeDetector();
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [isMediumScreen, setIsMediumScreen] = useState(false);
  const DESCRIPTION_MAX_LENGTH = 25;
  const DESCRIPTION_START_VALUE = 0;
  const DESCRIPTION_END_VALUE = 22;


  const selectedRoomOption = detailedHoliday?.roomOptions?.find(
    (option) => option.selected === true
  );

  useEffect(() => {
    const width = windowSizeDetector.width;
    setIsMobile(width < breakpoints.SM);
    setIsTablet(width > breakpoints.SM && width < breakpoints.XL);
    setIsLargeScreen(width >= breakpoints.LG && width < breakpoints.XL);
    setIsMediumScreen(width >= breakpoints.SM && width <= breakpoints.SM645);  
  }, [windowSizeDetector.width]);

  useEffect(() => {
    if (selectedRoomOption?.roomDescriptions) {
      const titleCaseDescriptions = selectedRoomOption.roomDescriptions.map(
        (description) => {
          return description
            .toLowerCase()
            .split(" ")
            .map((word) => {
              if (word === "x") {
                return "x";
              }
              if (!isNaN(word[0])) {
                return word;
              }
              return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(" ");
        }
      );

      dispatch(setRoomDescriptions(titleCaseDescriptions));
    }
  }, [selectedRoomOption, dispatch]);

  const renderDescription = (description) => {
    let truncateLength = DESCRIPTION_MAX_LENGTH;
    if (isLargeScreen || isMediumScreen) {
      truncateLength = DESCRIPTION_END_VALUE;
    }
    if (description.length > truncateLength) {
      return (
        <Tooltip title={description} arrow>
          <span>{description.substring(DESCRIPTION_START_VALUE, truncateLength - 3)}...</span>
        </Tooltip>
      );
    }
    return <span>{description}</span>;
  };

  const getNumberOfPersons = (personType) => {
    return roomAllocation?.reduce((acc, c) => {
      return (acc += c[personType]);
    }, 0);
  };

  const displayLabel = (count, singleLabel, pluralLabel) => {
    if (count > 0) {
      const label = count === 1 ? singleLabel : pluralLabel;
      return `, ${count} ${label.toLowerCase()}`;
    }
    return '';
  };

  const displayRoomAllocationLabels = () => {
    const adults = getNumberOfPersons(travellerType.ADULTS);
    const children = getNumberOfPersons(travellerType.CHILDREN);
    const babies = getNumberOfPersons(travellerType.BABIES);

    if (!contentfulRoomAllocation || contentfulRoomAllocation.length === 0)  return '';
  
    const { travellerSelectorAdultHeader, 
            travellerSelectorAdultsHeader, 
            travellerSelectorChildHeader, 
            travellerSelectorChildrenHeader, 
            travellerSelectorBabyHeader, 
            travellerSelectorBabiesHeader } = contentfulRoomAllocation[0].fields;
  
    const adultsLabel = displayLabel(adults, travellerSelectorAdultHeader, travellerSelectorAdultsHeader).replace(/^, /, '');
    const childrenLabel = displayLabel(children, travellerSelectorChildHeader, travellerSelectorChildrenHeader);
    const babiesLabel = displayLabel(babies, travellerSelectorBabyHeader, travellerSelectorBabiesHeader);
  
    return `${adultsLabel}${childrenLabel}${babiesLabel}`;
  };

  return (
    <div className="holiday-summary-hotel-overview-component">
      <div className="sub-title">
        {contentfulHolidayPageSummary &&
          contentfulHolidayPageSummary[0]?.fields?.tripOverviewTitle}
      </div>
      <div className="overview-hotel-information">
        <div className="overview-hotel-information-item">
          {GetAffiliate() === affiliates.klm ? (
            <IconLocationMarkerKLM className="svg-color" />
          ) : (
            <IconLocationMarkerTransavia className="svg-color" />
          )}
          <div className="overview-hotel-information-item-description">
            <div className="overview-hotel-information-item-title">
              {contentfulHolidayPageSummary &&
                contentfulHolidayPageSummary[0]?.fields?.location}
            </div>
            <div className="overview-hotel-information-item-description">
              {detailedHoliday?.holidayDetailInfo?.destinationCountry}
              {" - "}
              {detailedHoliday?.holidayDetailInfo?.destination}
            </div>
          </div>
        </div>

        <div className="overview-hotel-information-item">
          {GetAffiliate() === affiliates.klm ? (
            <IconRoomTypeKLM className="svg-color" />
          ) : (
            <IconRoomTypeTransavia className="svg-color" />
          )}
          <div className="overview-hotel-information-item-description">
            <div className="overview-hotel-information-item-title">
              {contentfulHolidayPageSummary &&
                contentfulHolidayPageSummary[0]?.fields?.hotel}
            </div>
            <div className="overview-hotel-information-item-description">
              {detailedHoliday?.holidayDetailInfo?.hotelName}
            </div>
          </div>
        </div>

        <div className="overview-hotel-information-item">
          {GetAffiliate() === affiliates.klm ? (
            <IconTravelKLM className="svg-color" />
          ) : (
            <IconTravelTransavia className="svg-color" />
          )}
          <div className="overview-hotel-information-item-description">
            <div className="overview-hotel-information-item-title">
              {contentfulHolidayPageSummary &&
                contentfulHolidayPageSummary[0]?.fields?.trip}
            </div>
            <div className="overview-hotel-information-item-description">
            {detailedHoliday?.holidayDetailInfo?.holidayCheckInCheckOutDescription}
            </div>
          </div>
        </div>

        <div className="overview-hotel-information-item">
          {GetAffiliate() === affiliates.klm ? (
            <IconPassengerKLM className="svg-color" />
          ) : (
            <IconPassengerTransavia className="svg-color" />
          )}
          <div className="overview-hotel-information-item-description">
            <div className="overview-hotel-information-item-title">
              {contentfulHolidayPageSummary &&
                contentfulHolidayPageSummary[0]?.fields?.persons}
            </div>
            <div className="overview-hotel-information-item-description">
              {contentfulRoomAllocation && (
                <>
                  {roomAllocation && displayRoomAllocationLabels()}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="overview-hotel-information-item">
          {GetAffiliate() === affiliates.klm ? (
            <IconRoomTypeKLM className="svg-color" />
          ) : (
            <IconRoomTypeTransavia className="svg-color" />
          )}
          <div className="overview-hotel-information-item-description">
            <div className="overview-hotel-information-item-title">
              {contentfulHolidayPageSummary &&
                contentfulHolidayPageSummary[0]?.fields?.roomType}
            </div>
            <div className="overview-hotel-information-item-description">
              <div className="room-container">
                {" "}
                {roomStateData
                  ? roomStateData.map((description, index) => (
                      <div key={index}>{renderDescription(description)}</div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>

        <div className="overview-hotel-information-item">
          {GetAffiliate() === affiliates.klm ? (
            <IconMealKLM className="svg-color" />
          ) : (
            <IconMealTransavia className="svg-color" />
          )}
          <div className="overview-hotel-information-item-description">
            <div className="overview-hotel-information-item-title">
              {contentfulHolidayPageSummary &&
                contentfulHolidayPageSummary[0]?.fields?.meals}
            </div>
            <div className="overview-hotel-information-item-description">
              {GetMealType(detailedHoliday?.holidayDetailInfo?.mealType)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
