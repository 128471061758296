import { useEffect, useState } from "react";
import { DropdownComponent } from "../../dropdown/dropdown.component";
import config from "./../../../environments/config.json";
import { LocalStorageService } from "../../../services/local-storage.service";
import { languageDictionary } from "../../../common/constants/language";
import { breakpoints } from "../../../common/constants/breakpoints";
import { useWindowSizeDetector } from "../../../hooks/useWindowSizeDetector";

export const LanguageSelectorComponent = ({
  queryParameters,
  culture,
  language,
  handleLanguageChange,
  isMobile,
  setIsMobile,
}) => {
  const languages = config.AVAILABLE_LANGUAGES;
  const windowSizeDetector = useWindowSizeDetector();

  useEffect(() => {
    windowSizeDetector.width < breakpoints.SM
      ? setIsMobile(true)
      : setIsMobile(false);
  });

  return (
    <div className="language-selector-component">
      <DropdownComponent
        defaultPlaceholder={
          <div className="language-input">
            <img
              className="flag-small"
              alt={`${language}`}
              src={`${config.COUNTRY_FLAGS}/${culture
                ?.substring(culture?.length - 2)
                .toLocaleLowerCase()}.png`}
            />
            <span>
              {isMobile
                ? culture?.substring(0, 2).toUpperCase()
                : languageDictionary[culture]}
            </span>
          </div>
        }
      >
        <div>
          {languages.map((language, i) => (
            <div
              className={`language-row ${
                language === culture ? "selected-in-dropdown-row" : ""
              }`}
              key={i}
            >
              <div
                className={`language-selection-highlight-bar ${
                  language === culture ? "selected-language-in-dropdown" : ""
                }`}
              ></div>

              <div
                className="language-row-content"
                onClick={() => handleLanguageChange(language)}
              >
                <img
                  className="flag-small"
                  alt={`${language}`}
                  src={`${config.COUNTRY_FLAGS}/${language
                    ?.substring(language.length - 2)
                    .toLocaleLowerCase()}.png`}
                />
                <span>
                  {isMobile
                    ? Object.keys(languageDictionary)
                        [i]?.substring(0, 2)
                        .toUpperCase()
                    : languageDictionary[language]}
                </span>
              </div>
            </div>
          ))}
        </div>
      </DropdownComponent>
    </div>
  );
};
