import { createSlice } from '@reduxjs/toolkit';

export const contentfulSlice = createSlice({
  name: 'contentfulEntries',
  initialState: { contentfulEntries: null },
  reducers: {
    setContentfulEntriesReducer: (state, action) => {
      state.contentfulEntries = action.payload;
    },
  },
});

export const { setContentfulEntriesReducer } = contentfulSlice.actions;
export default contentfulSlice;