import "./data-input-component.scss";
import React, { useRef } from "react";
import { Controller } from "react-hook-form";
import { InputComponent } from "../../../../components/input-component/input-component";
import {
  dateInputsPlaceholdersEng,
  dateInputsPlaceholdersNL,
} from "../../../../common/enums/dateInputsPlaceholders";
import { LocalStorageService } from "../../../../services/local-storage.service";
import { culturesCodeEnum } from "../../../../common/enums/cultureCodesEnum";
import { PassengerDetailsFormFields } from "../../../../common/constants/passengerDetailsFormFields";
import { ValidationPattern } from "../../../../common/enums/validationRulesEnum";

export const DataInputComponent = ({
  name,
  setDateOfBirthValues,
  travellerInfo,
  setDay,
  setMonth,
  setYear,
  control,
  errors,
  contentfulCheckoutPassengerDetails,
  contentfulCheckoutFormValidators,
}) => {
  const {
    day: dayError,
    month: monthError,
    year: yearError,
    dateOfBirth: dateOfBirthError,
  } = errors[name] ?? "";
  const dayInputRef = useRef(null);
  const monthInputRef = useRef(null);
  const yearInputRef = useRef(null);
  const SINGLE_DIGIT_THRESHOLD = 10;
  const ZERO_PREFIX = "0"

  const moveToNextField = (nextFieldRef) => {
    nextFieldRef.current?.focus();
  };

  const getDayOfBirth = () => {
    if (travellerInfo.dateOfBirth) {
      const dayOfBirth = new Date(travellerInfo.dateOfBirth).getDate();
      const dayOfBirthWithPrefix =
        dayOfBirth < SINGLE_DIGIT_THRESHOLD ? ZERO_PREFIX + dayOfBirth : dayOfBirth;
      setDay(dayOfBirthWithPrefix.toString());
      return dayOfBirthWithPrefix ?? "";
    }
  };

  const getMonthOfBirth = () => {
    if (travellerInfo.dateOfBirth) {
      const monthOfBirth = new Date(travellerInfo.dateOfBirth).getMonth() + 1;
      const monthOfBirthWithPrefix =
        monthOfBirth < SINGLE_DIGIT_THRESHOLD ? ZERO_PREFIX + monthOfBirth : monthOfBirth;
      setMonth(monthOfBirthWithPrefix.toString());
      return monthOfBirthWithPrefix ?? "";
    }
  };

  const getYearOfBirth = () => {
    if (travellerInfo.dateOfBirth) {
      const yearOfBirth = new Date(travellerInfo.dateOfBirth).getFullYear();
      setYear(yearOfBirth.toString());
      return yearOfBirth ?? "";
    }
  };

  const culture = LocalStorageService.getCulture();
  const placeholder =
    culture === culturesCodeEnum.NETHERLANDS
      ? dateInputsPlaceholdersNL
      : dateInputsPlaceholdersEng;

  return (
    <div className="date-of-born">
      <div
        className={`date-of-birth-label ${
          dateOfBirthError || dayError || monthError || yearError ? "error" : ""
        }`}
      >
        {contentfulCheckoutPassengerDetails &&
          contentfulCheckoutPassengerDetails[0]?.fields?.dateOfBirth}
      </div>
      <Controller
        name={`${name}.${PassengerDetailsFormFields.DAY_OF_BIRTH}`}
        control={control}
        defaultValue={getDayOfBirth()}
        rules={{
          required:
            contentfulCheckoutFormValidators &&
            contentfulCheckoutFormValidators[0]?.fields?.invalidDate,
          min: {
            value: 1,
            message:
              contentfulCheckoutFormValidators &&
              contentfulCheckoutFormValidators[0]?.fields?.invalidDate,
          },
          max: {
            value: 31,
            message:
              contentfulCheckoutFormValidators &&
              contentfulCheckoutFormValidators[0]?.fields?.invalidDate,
          },
        }}
        render={({ field, fieldState }) => (
          <div className="birthday-day">
            <InputComponent
              label={
                contentfulCheckoutPassengerDetails &&
                contentfulCheckoutPassengerDetails[0]?.fields?.day
              }
              name={`${name}.${PassengerDetailsFormFields.DAY_OF_BIRTH}`}
              defaultValue=""
              value={field.value}
              onChange={field.onChange}
              maxLength={2}
              isError={fieldState.error || dayError || dateOfBirthError}
              setDateOfBirthValues={setDateOfBirthValues}
              placeholder={placeholder.day}
              ref={(e) => {
                field.ref(e);
                dayInputRef.current = e;
              }}
              onMaxLengthReached={() => moveToNextField(monthInputRef)}
              inputMode="numeric"
            />
          </div>
        )}
      />

      <Controller
        name={`${name}.${PassengerDetailsFormFields.MONTH_OF_BIRTH}`}
        control={control}
        defaultValue={getMonthOfBirth()}
        rules={{
          required:
            contentfulCheckoutFormValidators &&
            contentfulCheckoutFormValidators[0]?.fields?.invalidDate,
          pattern: {
            value: ValidationPattern.ONLY_DIGITS_BETWEEN_1_AND_2_CHARS,
            message:
              contentfulCheckoutFormValidators &&
              contentfulCheckoutFormValidators[0]?.fields?.invalidDate,
          },
          min: {
            value: 1,
            message:
              contentfulCheckoutFormValidators &&
              contentfulCheckoutFormValidators[0]?.fields?.invalidDate,
          },
          max: {
            value: 12,
            message:
              contentfulCheckoutFormValidators &&
              contentfulCheckoutFormValidators[0]?.fields?.invalidDate,
          },
        }}
        render={({ field, fieldState }) => (
          <div className="birthday-month">
            <InputComponent
              label={
                contentfulCheckoutPassengerDetails &&
                contentfulCheckoutPassengerDetails[0]?.fields?.month
              }
              name={`${name}.${PassengerDetailsFormFields.MONTH_OF_BIRTH}`}
              value={field.value}
              onChange={field.onChange}
              maxLength={2}
              isError={fieldState.error || monthError || dateOfBirthError}
              setDateOfBirthValues={setDateOfBirthValues}
              placeholder={placeholder.month}
              ref={(e) => {
                field.ref(e);
                monthInputRef.current = e;
              }}
              onMaxLengthReached={() => moveToNextField(yearInputRef)}
              inputMode="numeric"
            />
          </div>
        )}
      />
      <Controller
        name={`${name}.${PassengerDetailsFormFields.YEAR_OF_BIRTH}`}
        control={control}
        defaultValue={getYearOfBirth()}
        rules={{
          required:
            contentfulCheckoutFormValidators &&
            contentfulCheckoutFormValidators[0]?.fields?.invalidDate,
          pattern: {
            value: ValidationPattern.ONLY_DIGITS_BETWEEN_1_AND_4_CHARS,
            message:
              contentfulCheckoutFormValidators &&
              contentfulCheckoutFormValidators[0]?.fields?.invalidDate,
          },
        }}
        render={({ field, fieldState }) => (
          <div className="birthday-year">
            <InputComponent
              label={
                contentfulCheckoutPassengerDetails &&
                contentfulCheckoutPassengerDetails[0]?.fields?.year
              }
              name={`${name}.${PassengerDetailsFormFields.YEAR_OF_BIRTH}`}
              value={field.value}
              onChange={field.onChange}
              isError={fieldState.error || yearError || dateOfBirthError}
              maxLength={4}
              setDateOfBirthValues={setDateOfBirthValues}
              placeholder={placeholder.year}
              ref={(e) => {
                field.ref(e);
                yearInputRef.current = e;
              }}
              inputMode="numeric"
            />
          </div>
        )}
      />
      {!dateOfBirthError && (dayError || monthError || yearError) && (
        <div className="error-message-placeholder">
          <span className="error error-message">
            {contentfulCheckoutFormValidators &&
              contentfulCheckoutFormValidators[0]?.fields?.invalidDate}
          </span>
        </div>
      )}
    </div>
  );
};
