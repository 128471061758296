import { useEffect, useState } from "react";
import { Accordion } from "../../accordion/accordion";
import Slider from "@mui/material/Slider";
import './price-filter.component.scss';

export const PriceFilterComponent = ({
  contentfulFilters,
  data,
  setGenericFilter,
}) => {
  const [apiDefaultPriceRange, setStateApiDefaultPriceRange] = useState([0, 0]);
  const [sliderPriceRange, setStateSliderPriceRange] = useState([0, 0]);

  useEffect(() => {
    setStateApiDefaultPriceRange([
      data?.filterInfo?.filterOptions?.priceFilterOptions?.minValue,
      data?.filterInfo?.filterOptions?.priceFilterOptions?.maxValue
    ]);

    setStateSliderPriceRange([
      data?.filterInfo?.requestedFilters?.minPrice
        ? data?.filterInfo?.requestedFilters?.minPrice
        : data?.filterInfo?.filterOptions?.priceFilterOptions?.minValue,
      data?.filterInfo?.requestedFilters?.maxPrice
        ? data?.filterInfo?.requestedFilters?.maxPrice
        : data?.filterInfo?.filterOptions?.priceFilterOptions?.maxValue
    ]);
  }, [data]);

  const handleOnChangeEvent = (event, value) => {
    setStateSliderPriceRange([value[0], value[1]])
  };

  const handleOnChangeCommittedEvent = (event, value) => {
    setGenericFilter([
      { minPrice: value[0] },
      { maxPrice: value[1] }
    ]);
  };

  return (
    <div className="price-filter-component-container">
      <Accordion
        startingExpandedState="false"
        labelText={
          contentfulFilters
            ? contentfulFilters[0]?.fields?.price
            : "Price"
        }
      >
        <div
          className="filter-options-container"
        >
          <Slider
            min={apiDefaultPriceRange[0]}
            max={apiDefaultPriceRange[1]}
            value={sliderPriceRange}
            onChange={handleOnChangeEvent}
            onChangeCommitted={handleOnChangeCommittedEvent}
            valueLabelDisplay="on"
            valueLabelFormat={(value) => `€${value}`}
            step={1}
            sx={{ mt: 6 }}
          />
        </div>
        <div className="price-container">
          <div className="price-box">
            <div className="price-container-values">€{apiDefaultPriceRange[0]}</div>
            <div className="price-container-values">€{apiDefaultPriceRange[1]}</div>
          </div>
        </div>
      </Accordion>
    </div>
  );
};
