import { LocalStorageService } from "../../services/local-storage.service";

export const hotelAmenities = [
  {
    culture: "en-GB",
    types: [
      { Spa: "Spa Onsite" },
      { Outdoorpool: "Outdoor pool" },
      { Indoorpool: "Indoor pool" },
      { Airconditioning: "Air conditioning" },
      { Airportshuttle: "Airport shuttle (fee)" },
      { Parking: "Parking" },
      { Wlan: "Internet Access Available" },
      { Fitness: "Fitness Center" },
      { Childrenspool: "Children's pool" },
      { Childrensclub: "Kids Activities" },
      { Accessiblerooms: "Inroom Accessibility" },
      { Restaurant: "Restaurant" },
      { Beachaccess: "Beach access" },
      { Bikesrental: "Bikes rental" },
      { Businessfacilities: "Business Center" },
      { Skifacilities: "Ski facilities" },
      { Petswelcome: "Pets welcome" },
    ],
  },
  {
    culture: "nl-NL",
    types: [
      { Spa: "Spa" },
      { Outdoorpool: "Buitenzwembad" },
      { Indoorpool: "Binnenzwembad" },
      { Airconditioning: "Airconditioning" },
      { Airportshuttle: "Luchthavenshuttle (toeslag)" },
      { Parking: "Parkeermogelijkheden" },
      { Wlan: "Internettoegang" },
      { Fitness: "Fitnessruimte" },
      { Childrenspool: "Kinderzwembad" },
      { Childrensclub: "Kinderclub" },
      { Accessiblerooms: "Kamers voor minder validen" },
      { Restaurant: "Restaurant" },
      { Beachaccess: "Toegang tot strand" },
      { Bikesrental: "Fietsverhuur" },
      { Businessfacilities: "Zakelijke faciliteiten" },
      { Skifacilities: "Skifaciliteiten" },
      { Petswelcome: "Huisdieren toegestaan" },
    ],
  },
];

export const GetAmenityDescription = (amenityIdentifier) => {
  if (amenityIdentifier) {
    const amenitiesForCulture = hotelAmenities.filter(
      (x) => x.culture === LocalStorageService.getCulture()
    );
    const relevantAmenityTypes = amenitiesForCulture[0].types.find(
      (x) => Object.keys(x)[0].toLowerCase() === amenityIdentifier.toLowerCase()
    );

    return Object.values(relevantAmenityTypes)[0];
  }
  return "Unknown theme type";
};
