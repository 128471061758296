import "./filters-modal.component.scss";
import ReactDOM from "react-dom";
import { FiltersComponent } from "../filters.component";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { LocalStorageService } from "../../../services/local-storage.service";
import { LocalStorageEnum } from "../../../common/enums/localStorageEnum";
import { searchParamsEnum } from "../../../common/enums/searchParamsEnum";
import { methods } from "../filters.methods";
import { filterType } from "../../../common/constants/filterType";
import { action } from "../../../common/constants/action";

export const FiltersModalComponent = ({
  isFiltersButtonClicked,
  isModalClose,
  performSearch,
  performFilter,
  holidayFullData,
  contentfulButtons,
  contentfulResultsPageResponse,
  contentfulFilters,
  defaultSearchUsed,
}) => {
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(null);
  const [isClickedOutside, setIsClickedOutside] = useState(false);
  const [countriesLocations, setCountriesLocations] = useState([]);
  const [citiesLocations, setCitiesLocations] = useState([]);
  const [destinationFilters, setDestinationFilters] = useState([]);
  const [destinationModalFilter, setDestinationModalFilter] = useState(null);
  const [apiData, setApiData] = useState(null);
  const [genericFilter, setGenericFilterModal] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setIsOpen(isFiltersButtonClicked);
    setIsClickedOutside(false);
    handleOnClickSave();
  }, [isFiltersButtonClicked, isOpen]);

  const setClose = () => {
    setIsClickedOutside(false);
  };

  const Backdrop = () => {
    return <div className="backdrop" onClick={setClose} ref={containerRef} />;
  };

  const checkSelectionChanged = (holidayData, filterOptions) => {
    if (filterOptions?.length === 0) {
      return false;
    }
    return holidayData?.some(
      (item, index) => item?.selected !== filterOptions[index]?.selected
    );
  };

  const handleOnClickSave = () => {
    if (
      apiData &&
      (countriesLocations?.length > 0 || citiesLocations?.length > 0)
    ) {
      const isCountriesSelectionChanged = checkSelectionChanged(
        apiData?.data?.filterInfo?.filterOptions?.countryFilterOptions,
        countriesLocations
      );
      const isCitiesSelectionChanged = checkSelectionChanged(
        apiData?.data?.filterInfo?.filterOptions?.cityFilterOptions,
        citiesLocations
      );
      if (isCountriesSelectionChanged || isCitiesSelectionChanged) {
        const output = [...countriesLocations, ...citiesLocations]
          .filter((element) => element.selected)
          .map((element) => {
            return {
              type: filterType.destination,
              value: element.destinationCode,
              text: element.displayName,
              action: action.add,
            };
          });

        const destinationParams = output
          .map((selectedFilter) => selectedFilter.value)
          .join(",");

        setSearchParams((params) => {
          params.set(searchParamsEnum.destinationLocation, destinationParams);
          return params;
        });

        LocalStorageService.setLocalStorageItem(
          LocalStorageEnum.DESTINATION_LOCATIONS,
          destinationParams
        );
        setDestinationModalFilter(destinationParams);

        performSearch(
          methods.setSearchRequestBody(
            apiData,
            genericFilter,
            destinationParams
          )
        );
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && containerRef.current.contains(event.target)) {
        setIsClickedOutside(true);
        setIsOpen(false);
        isModalClose(true);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [containerRef]);

  const handleOnClose = () => {
    setIsOpen(false);
    isModalClose(true);
  };

  return (
    isOpen && (
      <>
        {ReactDOM.createPortal(
          <div className="side-filter-bar-modal">
            <FiltersComponent
              className="filter-bar-modal-component"
              contentfulButtons={contentfulButtons}
              contentfulFilters={contentfulFilters}
              contentfulResultsPageResponse={contentfulResultsPageResponse}
              holidayFullData={holidayFullData}
              performSearch={performSearch}
              performFilter={performFilter}
              handleOnClose={handleOnClose}
              defaultSearchUsed={defaultSearchUsed}
              containerRef={containerRef}
              isOpen={isOpen}
              setCountriesLocations={setCountriesLocations}
              setCitiesLocations={setCitiesLocations}
              setDestinationFilters={setDestinationFilters}
              destinationModalFilter={destinationModalFilter}
              setApiData={setApiData}
              setGenericFilterModal={setGenericFilterModal}
            />
          </div>,
          document.getElementById("overlay-root")
        )}
        {ReactDOM.createPortal(
          <Backdrop onConfirm={true} />,
          document.getElementById("backdrop-root")
        )}
      </>
    )
  );
};
