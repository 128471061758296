import "./holiday-themes.component.scss";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { affiliateStyleRules } from "../../../common/affiliate-style-rules";
import { GetAffiliate } from "../../../services/general.service";
import { klmThemeIcons } from "./klm-holiday-themes";
import { transaviaThemeIcons } from "./transavia-holiday-themes";
import { affiliates } from "../../../common/enums/affiliates";

export const HolidayThemesComponent = ({ holidayDetails }) => {
  const themeIcons = GetAffiliate() === affiliates.klm ? klmThemeIcons : transaviaThemeIcons;
  const maxNumberOfThemes = 5;

  const limitedThemes = () => {
    if (holidayDetails) {
      let themes = [...holidayDetails.themes];
      if (themes.length > maxNumberOfThemes) {
        themes.length = maxNumberOfThemes - 1;
        themes.push(
          "+" + (holidayDetails.themes.length - themes.length).toString()
        );
      }
      return themes;
    }
    return [];
  };

  const structureTooltipText = (currentTheme, index) => {
    if (index + 1 >= maxNumberOfThemes) {
      let tooltipText = "";
      for (
        let i = maxNumberOfThemes - 1;
        i < holidayDetails.themes.length;
        i++
      ) {
        tooltipText += holidayDetails.themes[i] + ",";
      }
      if (tooltipText) {
        return tooltipText.substring(0, tooltipText.length - 1);
      }
    }
    return currentTheme;
  };

  const FormattedTooltip = styled(
    ({ className, currentTheme, index, ...props }) => (
      <Tooltip
        title={structureTooltipText(currentTheme, index)}
        arrow
        key={index}
        componentsProps={{
          tooltip: {
            className: className,
            sx: {
              "& .MuiTooltip-arrow": {
                color:
                  affiliateStyleRules[GetAffiliate()].tooltipBackgroundColour,
              },
            },
          },
        }}
      >
        <div>
          {index + 1 >= maxNumberOfThemes && (
            <div className="icon-outline theme-icon-container">
              {currentTheme}
            </div>
          )}
          {index + 1 < maxNumberOfThemes && (
            <img
              src={themeIcons[currentTheme]}
              className="icon-outline"
              alt={currentTheme}
            />
          )}
        </div>
      </Tooltip>
    )
  )(`
      background: ${
        affiliateStyleRules[GetAffiliate()].tooltipBackgroundColour
      };
      .MuiTooltip-arrow: ${
        affiliateStyleRules[GetAffiliate()].tooltipBackgroundColour
      };
  `);

  return (
    <div className="theme-icons-box">
      {limitedThemes().map((currentTheme, i) => {
        return (
          <FormattedTooltip currentTheme={currentTheme} key={i} index={i} />
        );
      })}
    </div>
  );
};
