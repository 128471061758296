import * as contentful from "contentful";
import config from "./../environments/config.json";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { LocalStorageService } from "./local-storage.service";
import { LocalStorageEnum } from "../common/enums/localStorageEnum";

const client = contentful.createClient({
  space: config.SPACE,
  accessToken: process.env?.REACT_APP_CONTENTFUL_TOKEN,
  host: process.env?.REACT_APP_CONTENTFUL_URL,
});

export const getContentfulAllContextualFields = async (culture) => {
  try {
    const contentfulEntries = await contentfulHttpRequest(culture);
    return contentfulEntries;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getContentfulByContentType = (content, contentType) => {
  return content?.contentfulData?.filter(
    (item) => item.sys.contentType.sys.id === contentType
  );
};

export const getContentFulEntries = async (culture, contentType) => {
  try {
    const response = await client.getEntries({
      locale: culture,
      content_type: contentType,
      "fields.affiliate": process.env?.REACT_APP_AFFILIATE ?? config.ENV,
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const formatContentfulImageUrl = (baseUrl) => {
  return `https://${baseUrl}`;
};

export const parseContentfulDynamicContent = (input, ...params) => {
  if (input && params.length > 0) {
    let result = input;
    const regexStr = /\${([^}]+)}/;
    params.forEach((value, index) => {
      result = result.replace(regexStr, value);
    });
    return result;
  }
  return input;
};

export const renderDocument = document => {
  const Text = ({ children }) => <p>{children}</p>;
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>
    },
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
  };
  return documentToReactComponents(document, options);
};

export const getStoredLocale = () => {
  const storedLocale = LocalStorageService.getCulture();
  return storedLocale;
}

export const getContentfulEntriesLocale = (storedContentfulEntries) => {
  let contentfulEntriesLocale;
  if (
    storedContentfulEntries
    && Array.isArray(storedContentfulEntries)
    && storedContentfulEntries.length > 0
  ) {
    const entry = storedContentfulEntries.find(entry => entry.sys?.locale);
    contentfulEntriesLocale = entry ? entry.sys.locale : false;
  }
  else {
    contentfulEntriesLocale = false;
  }
  return contentfulEntriesLocale;
}

const contentfulHttpRequest = async (culture) => {
  const contentfulEntries = await client.getEntries({ locale: culture });

  const filteredContentfulEntries = contentfulEntries.items.filter((item) =>
    item?.fields?.affiliate?.startsWith(
      process.env?.REACT_APP_AFFILIATE ?? config.ENV
    )
  );
  LocalStorageService.setLocalStorageItem(LocalStorageEnum.CONTENTFUL_ENTRIES, JSON.stringify(filteredContentfulEntries));
  return filteredContentfulEntries;
}
