import { useEffect, useState } from "react";
import { Button } from "../../components/button/button";
import config from "../../environments/config.json";
import "./search.scss";
import axios from "axios";
import {
  SampleSearchClientKey,
  SampleSearchRequestBody,
} from "../../services/mockDataService";
import { GetApiUrl } from "../../services/general.service";

export const SearchPageComponent = () => {
  const [responseData, setResponseData] = useState({});

  const handleSearchClicked = async () => {
    await fetchData();
  };

  const fetchData = async () => {
    const requestUrl =
      GetApiUrl() +
      config.FONT_END_API_SEARCH_PATH +
      "?clientKey=" +
      SampleSearchClientKey;

    axios
      .post(requestUrl, SampleSearchRequestBody)
      .then((response) => setResponseData(response))
      .catch((error) => error);
  };

  return (
    <div>
      <Button onClickAction={handleSearchClicked} buttonText={"Get data"} />
      <div className="code-block">{JSON.stringify(responseData)}</div>
    </div>
  );
};
