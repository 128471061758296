import React, {useEffect, useState} from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import "./terms-and-conditions.scss";

function highlightWords(text, wordsToLinks) {
  return text
    .split(" ")
    .map((word) => {
      const link = wordsToLinks[word];
      if (link) {
        return (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="highlight"
          >
            {word}
          </a>
        );
      }
      return word;
    })
    .reduce((acc, curr) => (acc === null ? [curr] : [...acc, " ", curr]), null);
}

const CheckboxSection = ({ check, setCheck, termsCheck }) => (
  <div className="checkbox-section">
    <input 
        type="checkbox" 
        id="checkbox-item" 
        style={{ display: 'none' }} 
        checked={check}
        onChange={() => setCheck(!check)}
    />
    <div
      className={`checkbox-container ${check ? "active" : "inactive"}`}
      onClick={() => setCheck(!check)}
    >
      <div className="checkbox"></div>
    </div>
    <label htmlFor="checkbox-item"> {termsCheck} </label>
  </div>
);

const TermsAndConditions = ({ contentfulPaymentPage, setTermsAndConditionsCheck }) => {
  const [check, setCheck] = useState(false);
  const fields = contentfulPaymentPage && contentfulPaymentPage[0]?.fields;
  const klmLink = fields && fields.links.content[0].content[1];
  const transaviaLink = fields && fields.links.content[0].content[3];

  useEffect(()=> {
      setTermsAndConditionsCheck(check);
  },[check])

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ),
    },
  };

  return (
    <>
      <div className={`terms-container`}>
        <div className="links-box">
          {fields && (
            <CheckboxSection
              check={check}
              setCheck={setCheck}
              termsCheck={fields.termsCheck}
            />
          )}
          <div className="pdf-section">
            <a
              href={fields && fields.anvrFile[0]?.fields?.file?.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>{fields && fields.anvr}</p>
            </a>
            <a
              href={fields && fields.arTermsFile[0]?.fields?.file?.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>{fields && fields.airtradeTerms}</p>
            </a>
            <a target="_blank" rel="noopener noreferrer">
              <p>
                {fields &&
                  highlightWords(fields.transportCondition, {
                    KLM: klmLink.data.uri,
                    Transavia: transaviaLink.data.uri,
                  })}
              </p>
            </a>
          </div>
        </div>
        <div className="rules-box">
          <p className="complaints-paragraph">{fields && fields.complaints}</p>
          <div className="rights-paragraph">
            {fields && documentToReactComponents(fields.righstLink, options)}
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
