export const searchParamsEnum = {
  allInclusive: "allinclusive",
  culture: "culture",
  returnDate: "returndate",
  departureDate: "departuredate",
  departureDateFlex: "departuredateflex",
  departureLocation: "departurelocation",
  destinationAirportCode: "destinationairportcode",
  destinationLocation: "destinationlocation",
  roomAllocation: "roomallocation",
  hotelId: "hotelid",
};
