export const flight = {
    numberOfItemsToDisplay: 3,
    type: {
        directFlight: "directFlight",
        oneTransfer: "oneTransfer",
        multipleTransfers: "multipleTransfers",
        oneTechnicalStop: "oneTechnicalStop",
        multipleTechnicalStops: "multipleTechnicalStops"
    },
    legType: {
        outbound: "outbound-leg",
        inbound: "inbound-leg"
    }
};