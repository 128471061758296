import { useEffect, useState } from "react";
import { Accordion } from "../../accordion/accordion";
import { StarRatingComponent } from "../../star-rating/star-rating.component";
import { filterType } from "../../../common/constants/filterType";
import "./star-rating-filter.component.scss";

export const StarRatingFilterComponent = ({ contentfulFilters, data, setGenericFilter }) => {
    const [starRatingFilterOptions, setStateStarRatingFilterOptions] = useState([]);

    useEffect(() => {
        setStateStarRatingFilterOptions(data);
    }, [data, starRatingFilterOptions]);

    const handleOnChange = (index) => {
        const output = starRatingFilterOptions.map((item, i) =>
            index === i
                ? item.selected = true
                : item.selected = false
        );
        setStateStarRatingFilterOptions(output);
    };

    return (
        <div className="star-rating-filter-component-container">
            <Accordion
                startingExpandedState="false"
                labelText={
                    contentfulFilters
                        ? contentfulFilters[0]?.fields?.numberOfStars
                        : "Number of stars"
                }
            >
                <div
                    className="filter-options-container"
                >
                    {
                        data?.map((element, index) => {
                            return (
                                <div
                                    key={index}
                                    className="filter-option"
                                    onClick={() => [handleOnChange(index), setGenericFilter([{ minStars: element.code }])]}
                                >
                                    <div className={
                                        starRatingFilterOptions && starRatingFilterOptions[index]?.selected
                                        ? "checkbox-container active"
                                        : "checkbox-container inactive"
                                        }>
                                        <div
                                            className="checkbox"
                                        ></div>
                                    </div>

                                    <div className="star-rating-container">
                                        <StarRatingComponent
                                            data={parseInt(element.code)}
                                        />
                                    </div>

                                    <div className="text-container">
                                        {
                                            element.code !== filterType.excellent
                                                ? <>
                                                    {
                                                        contentfulFilters
                                                            ? contentfulFilters[0]?.fields?.orMore
                                                            : "or more"
                                                    }
                                                </>
                                                : null
                                        }
                                    </div>

                                    <div className="counter-container">
                                        {element.count}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Accordion>
        </div>
    );
};