import { useEffect, useState } from "react";
import { Accordion } from "../../accordion/accordion";
import './theme-filter.component.scss';

export const ThemeFilterComponent = ({
    contentfulFilters,
    data,
    setGenericFilter
}) => {
    const [themeFilterOptions, setStateThemeFilterOptions] = useState([]);

    useEffect(() => {
        setStateThemeFilterOptions(data?.filterOptions?.themeFilterOptions);
    }, [data, themeFilterOptions]);

    const handleOnClick = (index) => {
        const updatedThemeFilterOptions = [...themeFilterOptions];
        updatedThemeFilterOptions[index].selected = !updatedThemeFilterOptions[index].selected;

        const selectedThemes = updatedThemeFilterOptions
            .filter(element => element.selected)
            .map(element => element.code);

        setGenericFilter([{ selectedThemes: selectedThemes }]);
    };

    return (
        <div className="theme-filter-component-container">
            <Accordion
                startingExpandedState="true"
                labelText={
                    contentfulFilters
                        ? contentfulFilters[0]?.fields?.themas
                        : "Themes"
                }
            >
                 <div
                    className="filter-options-container"
                >
                    {
                        themeFilterOptions?.map((element, index) => {
                            return (
                                <div
                                    key={index}
                                    className="filter-option"
                                    onClick={() => { handleOnClick(index) }}
                                >
                                    <div className={
                                        themeFilterOptions && themeFilterOptions[index]?.selected
                                        ? "checkbox-container active"
                                        : "checkbox-container inactive"
                                        }>
                                        <div
                                            className="checkbox"
                                        ></div>
                                    </div>

                                    <div className="text-container">
                                        { element.code }
                                    </div>

                                    <div className="counter-container">
                                        {element.count}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Accordion>
        </div>
    );
}
