import "./holiday-icon-box.scss";
import { MdAirplanemodeActive, MdHotel } from "react-icons/md";
import { IoBedSharp } from "react-icons/io5";
import { GetAffiliate } from "../../../services/general.service";
import { affiliateStyleRules } from "../../../common/affiliate-style-rules";
import { affiliates } from "../../../common/enums/affiliates.js";
import FlightIconKLM from "../../../assets/icons/klm/flight.svg"

export const HolidayIconBox = () => {
  const usesKLMIcon = GetAffiliate() === affiliates.klm;

  return (
    <div className="holiday-icon-box">
      <div className="holiday-icon-parent">
        {affiliateStyleRules[GetAffiliate()].usesFlightIcon && (
          <img
            className="holiday-icon aeroplane-icon"
            src={usesKLMIcon ? FlightIconKLM : "./icons/flight.svg"}
            alt=""
          />
        )}
        {!affiliateStyleRules[GetAffiliate()].usesFlightIcon && (
          <MdAirplanemodeActive className="holiday-icon aeroplane-icon" />
        )}
      </div>
      <b className="holiday-action-symbol">+</b>
      {affiliateStyleRules[GetAffiliate()].holidayCardUsesSideBedIcon && (
        <MdHotel className="holiday-icon hotel-icon" />
      )}
      {affiliateStyleRules[GetAffiliate()].holidayCardUsesFrontBedIcon && (
        <IoBedSharp className="holiday-icon hotel-icon" />
      )}
    </div>
  );
};
