import {GetAffiliate} from "../../../services/general.service";
import {affiliates} from "../../../common/enums/affiliates";

export const HotelMapMarkerComponent = () => {
    const affiliate = GetAffiliate();
    const svgStringKLM = `<svg width="30px" height="52px" viewBox="0 0 40 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>KLM_MapNumberOfHotelsIndicator_empty</title>
    <g id="KLM_MapNumberOfHotelsIndicator_empty" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M20,64 C33.3333333,42.0304633 40,27.3637967 40,20 C40,8.954305 31.045695,0 20,0 C18.1738012,0 16.4047694,0.244760808 14.7239197,0.703267293 C6.23821261,3.01802016 0,10.7805038 0,20 C0,27.3637967 6.66666667,42.0304633 20,64 Z" id="Oval" fill="#E37222"></path>
        <circle id="Oval" fill="#FFFFFF" cx="20" cy="20" r="17"></circle>
    </g>
</svg>`;

    const svgStringTransavia = `
    <svg width="30px" height="52px" viewBox="0 0 40 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Transavia_MapNumberOfHotelsIndicator_empty</title>
        <g id="Transavia_MapNumberOfHotelsIndicator_empty" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M20,64 C33.3333333,42.0304633 40,27.3637967 40,20 C40,8.954305 31.045695,0 20,0 C18.1738012,0 16.4047694,0.244760808 14.7239197,0.703267293 C6.23821261,3.01802016 0,10.7805038 0,20 C0,27.3637967 6.66666667,42.0304633 20,64 Z" id="Oval" fill="#2800A0"></path>
            <circle id="Oval" fill="#FFFFFF" cx="20" cy="20" r="17"></circle>
        </g>
    </svg>`;

    const svgString = affiliate === affiliates.klm ? svgStringKLM: svgStringTransavia;
    const encodedSvgString = encodeURIComponent(svgString);
    return `data:image/svg+xml;charset=UTF-8,${encodedSvgString}`;
};
