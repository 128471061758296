import "./company-name-input.component.scss";
import { Controller } from "react-hook-form";
import { PassengerDetailsFormFields } from "../../../../../common/constants/passengerDetailsFormFields";
import { InputComponent } from "../../../../../components/input-component/input-component";
import React from "react";

export const CompanyNameInputComponent = ({
  name,
  defaultCompanyName,
  control,
  errors,
  contentfulCheckoutPassengerDetails,
  contentfulCheckoutFormValidators,
}) => {
  return (
    <div className="company-label-component">
      <label className="company-label-input">
        <Controller
          name={`${name}.${PassengerDetailsFormFields.COMPANY_NAME}`}
          control={control}
          defaultValue={defaultCompanyName}
          render={({ field, fieldState }) => (
            <div className="post-code-input">
              <InputComponent
                label={
                  contentfulCheckoutPassengerDetails &&
                  contentfulCheckoutPassengerDetails[0]?.fields?.companyName
                }
                name={`${name}.${PassengerDetailsFormFields.COMPANY_NAME}`}
                value={field.value}
                onChange={field.onChange}
                isError={fieldState.error}
                inputMode="text"
              />
            </div>
          )}
        />
      </label>
    </div>
  );
};
