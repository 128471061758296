import './static-map.component.scss'
import config from "./../../environments/config.json";
import HotelMapMarkerKLM from '../../assets/icons/klm/klm-map-marker.svg'
import HotelMapMarkerHV from '../../assets/icons/transavia/transavia-map-marker.svg'
import { GetAffiliate } from '../../services/general.service';
import { affiliates } from '../../common/enums/affiliates';
import { GoogleMapConst } from '../../common/enums/googleMap';

export const StaticMapComponent = ({ hotelPosition }) => {
  const mapUrl = `${config.GOOGLE_MAP_STATIC_URL}?center=
                  ${hotelPosition?.lat},${hotelPosition?.lng}
                  &zoom=${GoogleMapConst.ZOOM.LEVEL_12}&size=342x228&maptype=roadmap&key=${config.GM_KEY}`;
  
  return (
    <div className="static-map-component">
        <img className="static-map" src={mapUrl} alt="Hotel on the Map" />   
        <img
          src={ GetAffiliate() === affiliates.klm ? HotelMapMarkerKLM : HotelMapMarkerHV}
          alt="Hotel Marker"
          className="custom-marker"
        /> 
    </div>
  );
};