import './filters-overview.component.scss'
import { FiltersModalComponent } from "../filters-modal/filters-modal.component";
import { useState } from "react";
import { SelectedFilterComponent } from "../selected-filters/selected-filters.component";

export const FiltersOverviewComponent = ({
    contentfulButtons,
    contentfulResultsPageResponse,
    contentfulFilters,
    isMapExtended,
    holidayFullData,
    performSearch,
    performFilter,
    filtersLength,
    children,
    handleOnClose,
    defaultSearchUsed,
}) => {
    const [filtersButtonClicked, setFiltersButtonClicked] = useState(false);
    const [mapExtended, setMapExtended] = useState(false);

    const handleOnFiltersClick = () => {
        setFiltersButtonClicked(!filtersButtonClicked);
    };

    const handleIsMapExtended = () => {
        isMapExtended(!mapExtended);
        setMapExtended(!mapExtended);
    };

    const handleModalClose = (value) => {
        setFiltersButtonClicked(!value);
    };

    return (
        <div className="filters-overview-component">
            <div className="filters-overview-component-content">
                <div className="filters-overview" onClick={handleOnFiltersClick}>
                    <div className="filters-overview-container">
                        <div className="filters-icon"></div>
                        <div className="filters-text">{contentfulFilters && contentfulFilters[0]?.fields?.filters}</div>
                        <div className="filters-counter-container">
                            <div className="filters-counter">
                                {filtersLength}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="filters-overview-map-button" onClick={handleIsMapExtended}>
                    <div className="map-icon"></div>
                    {contentfulButtons?.length > 0 && contentfulButtons[0]?.fields?.showOnMap}
                </div>
            </div>
            <div className="selected-filters-component">
                {children}
            </div>
            <FiltersModalComponent
                isFiltersButtonClicked={filtersButtonClicked}
                isModalClose={handleModalClose}
                performSearch={performSearch}
                performFilter={performFilter}
                holidayFullData={holidayFullData}
                contentfulButtons={contentfulButtons}
                contentfulResultsPageResponse={contentfulResultsPageResponse}
                contentfulFilters={contentfulFilters}
                handleOnClose={handleOnClose}
                defaultSearchUsed={defaultSearchUsed}
            />
        </div>
    )
}
