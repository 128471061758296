export const getAdyenConfiguration = (
  adyenClientEnv,
  clientKey,
  locale = "en_US",
  paymentMethodsResponse,
  handleOnAdditionalDetails,
  handleOnChange
) => {
  return {
    environment: adyenClientEnv,
    locale: locale,
    clientKey: clientKey,
    showPayButton: false,
    onAdditionalDetails: handleOnAdditionalDetails,
    // paymentMethodsConfiguration: paymentMethodsResponse.data.paymentInfo.adyenPaymentMethodsData,
    analytics: {
      enabled: true, // Set to false to not send analytics data to Adyen.
    },
    onChange: handleOnChange,
    onPaymentCompleted: (result, component) => {
      console.info(result, component);
    },
    onError: (error, component) => {
      console.error(error.name, error.message, error.stack, component);
    },
    // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
    // For example, this is 3D Secure configuration for cards:
  };
};
