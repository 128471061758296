import "./gender-radio-box.component.scss";
import { useState } from "react";
import { apiGenderRepresentationEnum } from "../../../../common/enums/apiGenderRepresentationEnum";
import { PassengerDetailsFormFields } from "../../../../common/constants/passengerDetailsFormFields";

export const GenderRadioBoxComponent = ({
  name,
  label,
  gender,
  maleLabel,
  femaleLabel,
  register,
  errors,
  contentfulCheckoutFormValidators,
}) => {
  const genderEnum = { male: "male", female: "female" };
  const [genderTitle, setGenderTitle] = useState(
    genderEnum[Object.keys(genderEnum)[gender]]
  );
  const [selectedGender, setSelectedGender] = useState(false);
  const isError = (
    errors
    && errors[name]
    && errors[name][PassengerDetailsFormFields.GENDER]
  )
    ? true
    : false;

  const handleGenderChange = (selectedGender) => {
    setSelectedGender(true);
    setGenderTitle(genderEnum[Object.keys(genderEnum)[selectedGender]]);
  };

  return (
    <div className="gender-radios-container">
      <label
        className={
          (isError && !selectedGender)
            ? "gender-label error"
            : "gender-label"
        }>
        {label}
      </label>

      <div className="gender-radio-buttons">
        <label className="gender-radio-label">
          <input
            type="radio"
            name={name}
            value={apiGenderRepresentationEnum.MALE}
            {...register(`${name}.gender`, {
              required:
                contentfulCheckoutFormValidators &&
                contentfulCheckoutFormValidators[0]?.fields?.selectGender,
            })}
            checked={genderTitle === genderEnum.male}
            onClick={() => handleGenderChange(apiGenderRepresentationEnum.MALE)}
          />

          <div
            className={
              genderTitle === genderEnum.male
                ? "radio-box-container active"
                : "radio-box-container inactive"
            }
          >
            {genderTitle === genderEnum.male && (
              <div className="checkbox"></div>
            )}
          </div>

          <span
            className={
              (isError && !selectedGender)
                ? "error"
                : ""
            }>
            {maleLabel}
          </span>
        </label>

        <label className="gender-radio-label">
          <input
            type="radio"
            name={name}
            value={apiGenderRepresentationEnum.FEMALE}
            {...register(`${name}.gender`, {
              required:
                contentfulCheckoutFormValidators &&
                contentfulCheckoutFormValidators[0]?.fields?.selectGender,
            })}
            checked={genderTitle === genderEnum.female}
            onClick={() =>
              handleGenderChange(apiGenderRepresentationEnum.FEMALE)
            }
          />

          <div
            className={
              genderTitle === genderEnum.female
                ? "radio-box-container active"
                : "radio-box-container inactive"
            }
          >
            {genderTitle === genderEnum.female && (
              <div className="checkbox"></div>
            )}
          </div>

          <span
            className={
              (isError && !selectedGender)
                ? "error"
                : ""
            }>
            {femaleLabel}
          </span>
        </label>
      </div>

      {
        (isError && !selectedGender)
          ? <div className="error-message-placeholder">
            <span className="error error-message">
              {contentfulCheckoutFormValidators &&
                contentfulCheckoutFormValidators[0]?.fields?.selectGender}
            </span>
          </div>
          : null
      }
    </div>
  );
};
