import "./city-input.component.scss";
import { Controller } from "react-hook-form";
import { PassengerDetailsFormFields } from "../../../../../common/constants/passengerDetailsFormFields";
import { InputComponent } from "../../../../../components/input-component/input-component";
import React, { useRef } from "react";
import { ValidationPattern } from "../../../../../common/enums/validationRulesEnum";

export const CityInputComponent = ({
  name,
  defaultCity,
  control,
  errors,
  contentfulCheckoutPassengerDetails,
  contentfulCheckoutFormValidators,
}) => {
  const { city: cityError } = errors[name] ?? "";
  const cityInputRef = useRef(null);

  return (
    <label className="city-label-component">
      <Controller
        name={`${name}.${PassengerDetailsFormFields.CITY}`}
        control={control}
        defaultValue={defaultCity}
        rules={{
          required: {
            value: true,
            message:
              contentfulCheckoutFormValidators &&
              contentfulCheckoutFormValidators[0]?.fields?.cityRequired,
          },
          pattern: {
            value: ValidationPattern.ONLY_CHARS,
            message:
              contentfulCheckoutFormValidators &&
              contentfulCheckoutFormValidators[0]?.fields?.onlyLettersAllowed,
          },
        }}
        render={({ field, fieldState }) => (
          <div className="city-label-input">
            <InputComponent
              label={
                contentfulCheckoutPassengerDetails &&
                contentfulCheckoutPassengerDetails[0]?.fields?.place
              }
              name={`${name}.${PassengerDetailsFormFields.CITY}`}
              value={field.value}
              onChange={field.onChange}
              isError={fieldState.error}
              inputMode="text"
              ref={(e) => {
                field.ref(e);
                cityInputRef.current = e;
              }}
            />
          </div>
        )}
      />
      {cityError && (
        <div className="error-message-placeholder">
          <span className="error error-message">{cityError?.message}</span>
        </div>
      )}
    </label>
  );
};
