import React, { useEffect } from 'react';

const ThemeSwitcher = () => {
    const affiliateName = process.env?.REACT_APP_AFFILIATE ?? "KLM"

    const getStyleModule = () => {
        import(`./../../assets/scss/theme-${affiliateName.toLowerCase().trim()}.scss`)
    }

    useEffect(() => {
        getStyleModule();
    }, []);
    return null;
}
export default ThemeSwitcher;
