import "./accommodation-options.component.scss";
import { HotelImageFlipper } from "../hotel/hotel-image-flipper/hotel-image-flipper.component";
import { Button } from "../button/button";
import { ReactComponent as IconRoomTypeKLM } from "../../assets/icons/klm/hotel-S.svg";
import { ReactComponent as IconRoomTypeTransavia } from "../../assets/icons/transavia/Hotel.svg";
import { ReactComponent as IconMealKLM } from "../../assets/icons/klm/meals-S.svg";
import { ReactComponent as IconMealTransavia } from "../../assets/icons/transavia/meals-S.svg";
import { ReactComponent as SelectedIconKLM } from "../../assets/icons/check-round-S.klm.svg";
import { GetAffiliate } from "../../services/general.service";
import { affiliates } from "../../common/enums/affiliates";
import { GetMealType } from "../../common/enums/meal-types";
import { useEffect, useState } from "react";
import { GenericButton } from "../generic-button/generic-button";
import { accommodationOptionsEnum } from "../../common/enums/accommodationOptions";
import { CircularProgress } from "@mui/material";

export const AccommodationOptionsComponent = ({
  accommodationOptions,
  contentfulButtons,
  contentfulAccommodationSection,
  performRoomOptionSelection,
}) => {
  const [moreOptions, setMoreOptions] = useState(false);
  const [isWaitingForNetwork, setIsWaitingForNetwork] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);

  const handleOnExpandButtonClick = () => {
    setMoreOptions(!moreOptions);
  };

  const handleOnSelected = async (roomOptionKey) => {
    if(!isWaitingForNetwork) {
        setSelectedRoom(roomOptionKey);
        setIsWaitingForNetwork(true);
        await performRoomOptionSelection(roomOptionKey);
        setMoreOptions(false);
        setIsWaitingForNetwork(false);
    }
  };

  const numberOfOptionsToShow = () => {
    if (moreOptions) {
      return accommodationOptions?.length;
    }
    return accommodationOptionsEnum.defaultNumberOfOptions;
  };

  return (
    <div className="accommodation-options-component">
      {accommodationOptions && (
        <>
          <div className="accommodation-options-title">
            <div className="header">
              {contentfulAccommodationSection &&
                contentfulAccommodationSection.accommodation}
            </div>
            <div className="sub-header">
              {contentfulAccommodationSection &&
                contentfulAccommodationSection.roomType}
            </div>
          </div>
          <div className="accommodation-options-wrapper">
            {accommodationOptions?.map(
              (room, index) =>
                numberOfOptionsToShow() > index && (
                  <div
                    key={index}
                    className={`accommodation-options-container ${
                      isWaitingForNetwork && selectedRoom !== room.roomOptionKey
                        ? "opacity-50"
                        : ""
                    } ${room.selected ? "accommodation-options-selected" : ""}`}
                  >
                    <div className="hotel-image">
                      <HotelImageFlipper hotelImages={room.images} />
                    </div>
                    <div className="hotel-description">
                      <div className="accommodation-info">
                        <div className="accommodation-description">
                          {contentfulAccommodationSection &&
                            `
                                             ${room?.numberOfRooms} 
                                             ${
                                               room?.numberOfRooms === 1
                                                 ? contentfulAccommodationSection.room
                                                 : contentfulAccommodationSection.rooms
                                             },
                                             ${room?.numberOfPassengers} ${
                              contentfulAccommodationSection.travellers
                            }`}
                        </div>
                        <div className="amenities-options">
                          <div>
                            <div className="amenities-item">
                              {GetAffiliate() === affiliates.klm ? (
                                <IconMealKLM
                                  className={`${
                                    room?.mealIncluded
                                      ? "amenities-icon"
                                      : "amenities-icon-not-selected"
                                  }`}
                                />
                              ) : (
                                <IconMealTransavia
                                  className={`${
                                    room?.mealIncluded
                                      ? "amenities-icon-selected"
                                      : "amenities-icon-not-selected"
                                  }`}
                                />
                              )}
                              <div>{GetMealType(room.mealTypeCode)}</div>
                            </div>
                            <div className="amenities-item">
                              {GetAffiliate() === affiliates.klm ? (
                                <IconRoomTypeKLM className="amenities-icon" />
                              ) : (
                                <IconRoomTypeTransavia className="amenities-icon" />
                              )}
                              <div>
                                {room.roomDescriptions.map(
                                  (roomDescription) => (
                                    <div key={roomDescription}>
                                      {roomDescription}
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="price-selection-control">
                        <div
                          className={
                            room.selected ? "inclusive" : "additional-cost"
                          }
                        >
                          {room.price !== "" && Number(room.price) > 0 && (
                            <span className="price-sign">+</span>
                          )}
                          {Number(room.price) < 0 && (
                            <span className="price-sign">-</span>
                          )}
                          {room.price === "" && contentfulAccommodationSection
                            ? contentfulAccommodationSection.inclusive
                            : ` € 
                                            ${
                                              Number(room.price) > 0
                                                ? room.price
                                                : room.price.replace("-", "")
                                            }`}
                          {room.price !== "" && (
                            <span>
                              {" "}
                              {contentfulAccommodationSection &&
                                contentfulAccommodationSection.pricePerPerson}
                            </span>
                          )}
                        </div>
                        <div className="select-accommodation-button">
                          {!room.selected ? (
                            <GenericButton
                              className="button-selection"
                              disabledState={isWaitingForNetwork && selectedRoom === room.roomOptionKey}
                              onClick={() =>
                                handleOnSelected(room.roomOptionKey)
                              }
                            >
                              {
                              isWaitingForNetwork &&
                              selectedRoom === room.roomOptionKey ?
                              (
                                <>
                                  <div className="spinner-button-wrapper">
                                    <div className="spinner-button">
                                      <CircularProgress
                                        className="button-loading-spinner"
                                        size={6}
                                      />
                                      <div className="loading-text">
                                        {
                                        contentfulButtons &&
                                        contentfulButtons[0]?.fields?.loadingState
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                contentfulButtons &&
                                contentfulButtons[0]?.fields?.select
                              )}
                            </GenericButton>
                          ) : (
                            <div className="selected-accommodation">
                              <SelectedIconKLM className="selected-accommodation-icon" />
                              {contentfulButtons &&
                                contentfulButtons[0]?.fields?.selected}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
          {accommodationOptions.length > 3 ? (
            <GenericButton className="more-accommodation-button">
              {moreOptions && (
                <div
                  className="button-container"
                  onClick={handleOnExpandButtonClick}
                >
                  <div>
                    {contentfulButtons &&
                      contentfulButtons[0]?.fields?.showLessRooms}
                  </div>
                  <div className="dropdown-caret rotate"></div>
                </div>
              )}
              {!moreOptions && (
                <div
                  className="button-container"
                  onClick={handleOnExpandButtonClick}
                >
                  {contentfulButtons &&
                    contentfulButtons[0]?.fields?.showAllRooms}
                  <div className="dropdown-caret"></div>
                </div>
              )}
            </GenericButton>
          ) : (
            <div className="spacer-sm"></div>
          )}
        </>
      )}
    </div>
  );
};
