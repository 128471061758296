import React from "react";
import PropTypes from "prop-types";
import { affiliates } from "../../common/enums/affiliates";
import { GetAffiliate } from "../../services/general.service";
import { ReactComponent as FlightClockTransfer } from "../../assets/icons/klm/clock-fligts.svg";
import { ReactComponent as FlightClockTransferTransavia } from "../../assets/icons/transavia/clock-flighs-transfer.svg";
import "./transfer-box.scss";

const TransferBox = ({ text, waitingTimeH, waitingTimeM, className }) => {
  return (
    <div className={`transfer-container ${className}`}>
      <div className="icon-container">
        {GetAffiliate() === affiliates.klm ? (
          <FlightClockTransfer />
        ) : GetAffiliate() === affiliates.transavia ? (
          <FlightClockTransferTransavia />
        ) : null}
      </div>
      <div className="information-container">
        <div className="time-box">{`${waitingTimeH}h ${waitingTimeM}m`}</div>
        <div className="text-box">{text}</div>
      </div>
    </div>
  );
};

TransferBox.propTypes = {
  waitingTimeH: PropTypes.string,
  waitingTimeM: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string
};

export default TransferBox;
