import './fail-booking.component.scss';
import {parseContentfulDynamicContent, renderDocument} from "../../../services/contentful.service";
import ErrorIcon from '@mui/icons-material/Error';

export const FailBookingComponent = ({contentfulConfirmationPage, destinationCity}) => {

    return <div className="fail-booking-component">
        <div className="fail-booking-header">
            <ErrorIcon />
            {contentfulConfirmationPage &&
            parseContentfulDynamicContent(contentfulConfirmationPage[0]?.fields?.failedBookingHeader, destinationCity)}
        </div>
        <div className="fail-booking-body">
            {contentfulConfirmationPage && renderDocument(contentfulConfirmationPage[0]?.fields?.failedBookingBody)}
        </div>
    </div>
}
