import { useEffect, useState } from "react";
import { Accordion } from "../../accordion/accordion";
import { filterType } from "../../../common/constants/filterType";
import './amenities-filter.component.scss';
import {GetAmenityDescription} from "../../../common/enums/amenities";

export const AmenitiesFilterComponent = ({
    contentfulFilters,
    data,
    setGenericFilter
}) => {
    const [amenityFilterOptions, setStateAmenityFilterOptions] = useState([]);
    const [numberOfOptionsToShow, setStateNumberOfOptionsToShow] = useState(filterType.amenitiesFilterNumberOfOptionsToShow);

    useEffect(() => {
        if (contentfulFilters && data) {
            setStateAmenityFilterOptions(sortAmenityFilterOptions());
        }
    }, [contentfulFilters, data, amenityFilterOptions]);

    const sortAmenityFilterOptions = () => {
        return data?.filterOptions?.amenityFilterOptions
            .sort((a, b) => {
                return (
                    GetAmenityDescription(a.code) <
                    GetAmenityDescription(b.code)
                ) ? -1 : 1
            });
    };

    const handleOnClick = (index) => {
        const updatedAmenityFilterOptions = [...amenityFilterOptions];
        updatedAmenityFilterOptions[index].selected = !updatedAmenityFilterOptions[index].selected;

        const selectedAmenities = updatedAmenityFilterOptions
            .filter(element => element.selected)
            .map(element => element.code);

        setGenericFilter([{ selectedAmenities: selectedAmenities }]);
    };

    const toggleFilterOptionsView = () => {
        setStateNumberOfOptionsToShow(
            numberOfOptionsToShow === filterType.amenitiesFilterNumberOfOptionsToShow
                ? amenityFilterOptions.length
                : filterType.amenitiesFilterNumberOfOptionsToShow
        );
    };

    return (
        <div className="amenities-filter-component-container">
            <Accordion
                startingExpandedState="true"
                labelText={
                    contentfulFilters
                        ? contentfulFilters[0]?.fields?.amenitiesFilter?.header
                        : "Amenities"
                }
            >
                <div
                    className="filter-options-container"
                >
                    {
                        amenityFilterOptions?.slice(0, numberOfOptionsToShow).map((element, index) => {
                            return (
                                <div
                                    key={index}
                                    className="filter-option"
                                    onClick={() => { handleOnClick(index) }}
                                >
                                    <div className={
                                        amenityFilterOptions && amenityFilterOptions[index]?.selected
                                            ? "checkbox-container active"
                                            : "checkbox-container inactive"
                                    }>
                                        <div
                                            className="checkbox"
                                        ></div>
                                    </div>

                                    <div className="text-container">
                                        {
                                            GetAmenityDescription(element.code)
                                        }
                                    </div>

                                    <div className="counter-container">
                                        {element.count}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div
                    className="filter-options-view-switch"
                    onClick={toggleFilterOptionsView}
                >
                    <div className="text-container">
                        {
                            numberOfOptionsToShow !== filterType.amenitiesFilterNumberOfOptionsToShow
                                ? <>
                                    {
                                        contentfulFilters
                                            ? contentfulFilters[0]?.fields?.amenitiesFilter?.showLessAmenities
                                            : "Show less amenities"
                                    }
                                </>
                                : <>
                                    {
                                        contentfulFilters
                                            ? contentfulFilters[0]?.fields?.amenitiesFilter?.showAllAmenities
                                            : "Show all amenities"
                                    }
                                </>
                        }
                    </div>
                    <div className="icon">
                        <div className={
                            numberOfOptionsToShow !== filterType.amenitiesFilterNumberOfOptionsToShow
                                ? "icon-drop-up"
                                : "icon-drop-down"
                        }
                        ></div>
                    </div>

                </div>
            </Accordion>
        </div>
    );
}
