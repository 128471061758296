import "./address-details-input-component.scss";
import { InputComponent } from "../../../../../components/input-component/input-component";
import { Controller } from "react-hook-form";
import { PassengerDetailsFormFields } from "../../../../../common/constants/passengerDetailsFormFields";
import { ValidationPattern } from "../../../../../common/enums/validationRulesEnum";
import React, { useRef } from "react";

export const AddressDetailsInputComponent = ({
  name,
  defaultAddress,
  control,
  errors,
  contentfulCheckoutPassengerDetails,
  contentfulCheckoutFormValidators,
}) => {
  const { street: streetError, houseNumber: houseNumberError } =
    errors[name] ?? "";

  const streetInputRef = useRef(null);
  const houseNumberInputRef = useRef(null);
  const houseNumberExtensionInputRef = useRef(null);

  return (
    <div className="address-details-input-component">
      <div className="address-details-container">
        <div className="street-name">
          <Controller
            name={`${name}.${PassengerDetailsFormFields.STREET}`}
            control={control}
            defaultValue={defaultAddress?.street}
            rules={{
              required: {
                value: true,
                message:
                  contentfulCheckoutFormValidators &&
                  contentfulCheckoutFormValidators[0]?.fields
                    ?.streetNameRequired,
              },
              pattern: {
                value: ValidationPattern.ALPHA_NUMERIC_AND_SPACE,
                message:
                  contentfulCheckoutFormValidators &&
                  contentfulCheckoutFormValidators[0]?.fields
                    .streetNameOnlyLetters,
              },
            }}
            render={({ field, fieldState }) => (
              <>
                <label className={streetError ? "error" : ""}>
                  {contentfulCheckoutPassengerDetails &&
                    contentfulCheckoutPassengerDetails[0]?.fields?.street}
                </label>
                <InputComponent
                  name={`${name}.${PassengerDetailsFormFields.STREET}`}
                  defaultValue=""
                  value={field.value}
                  onChange={field.onChange}
                  isError={fieldState.error}
                  inputMode="text"
                  ref={(e) => {
                    field.ref(e);
                    streetInputRef.current = e;
                  }}
                />
              </>
            )}
          />
          {streetError && (
            <div className="error-message-placeholder">
              <span className="error error-message">
                {streetError?.message}
              </span>
            </div>
          )}
        </div>

        <div className="extended-address-wrapper">
          <div className="house-number">
            <Controller
              name={`${name}.${PassengerDetailsFormFields.HOUSE_NUMBER}`}
              control={control}
              defaultValue={defaultAddress?.houseNumber}
              rules={{
                required: {
                  value: true,
                  message:
                    contentfulCheckoutFormValidators &&
                    contentfulCheckoutFormValidators[0]?.fields
                      .houseNumberRequired,
                },
                pattern: {
                  value: ValidationPattern.ONLY_DIGITS,
                  message:
                    contentfulCheckoutFormValidators &&
                    contentfulCheckoutFormValidators[0]?.fields
                      .houseNumberValidation,
                },
              }}
              render={({ field, fieldState }) => (
                <>
                  <label className={houseNumberError ? "error" : ""}>
                    {contentfulCheckoutPassengerDetails &&
                      contentfulCheckoutPassengerDetails[0]?.fields
                        ?.houseNumber}
                  </label>
                  <InputComponent
                    name={`${name}.${PassengerDetailsFormFields.HOUSE_NUMBER}`}
                    defaultValue=""
                    value={field.value}
                    onChange={field.onChange}
                    isError={fieldState.error}
                    inputMode="numeric"
                    ref={(e) => {
                      field.ref(e);
                      houseNumberInputRef.current = e;
                    }}
                  />
                </>
              )}
            />
            {houseNumberError && (
              <div className="error-message-placeholder">
                <span className="error error-message">
                  {houseNumberError?.message}
                </span>
              </div>
            )}
            {houseNumberError && <div className="error-spacer"></div>}
          </div>

          <div className="extension-address">
            <Controller
              name={`${name}.${PassengerDetailsFormFields.HOUSE_NUMBER_EXTENSION}`}
              control={control}
              defaultValue={defaultAddress?.houseNumberExtension}
              render={({ field, fieldState }) => (
                <>
                  <label>
                    {contentfulCheckoutPassengerDetails &&
                      contentfulCheckoutPassengerDetails[0]?.fields?.flatNumber}
                  </label>
                  <InputComponent
                    name={`${name}.${PassengerDetailsFormFields.HOUSE_NUMBER_EXTENSION}`}
                    defaultValue=""
                    value={field.value}
                    onChange={field.onChange}
                    ref={(e) => {
                      field.ref(e);
                      houseNumberExtensionInputRef.current = e;
                    }}
                  />
                </>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
