import { Tooltip } from "@mui/material";
import "./hotel.styles.scss";
import { useEffect, useState } from "react";

export const HotelComponent = ({ data, maxComponentWidth }) => {
  const [hotel, setHotel] = useState(null);

  useEffect(() => {
    setHotel(data?.hotel)
  }, [data])

  return (
    <Tooltip title={hotel?.name} arrow>
      <div className="hotel-name-text" style={{ maxWidth: maxComponentWidth }}>
        {hotel?.name}
      </div>
    </Tooltip>
  );
};
