export const dateInputsPlaceholdersEng = {
    day: 'dd',
    month: 'mm',
    year: 'yyyy'
};

export const dateInputsPlaceholdersNL = {
    day: 'dd',
    month: 'mm',
    year: 'jjjj'
};
