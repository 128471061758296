import { useEffect, useState } from "react";
import { PassengerDetailsFormFields } from "../../../common/constants/passengerDetailsFormFields";
import { parseContentfulDynamicContent } from "../../../services/contentful.service";
import { BaggageDropdownComponent } from "./baggage-dropdown.component";
import "./baggage.component.scss";

export const BaggageComponent = ({
  baggageDetails,
  onBaggageSelectionChanged,
  contentfulCheckoutPassengerDetails,
  contentfulExtrasPage,
  isLoading,
  loadingPassengerId,
}) => {
  const [baggageOptions, setBaggageOptions] = useState([]);

  const findPreselectedItem = (currentTraveller) => {
    if (
      !currentTraveller ||
      currentTraveller.ancillaryServiceOptionsList.length === 0
    ) {
      return null;
    }
    return currentTraveller.ancillaryServiceOptionsList.find(
      (item) => item.isSelected
    );
  };

  const getTravellerTypeDescription = (travelerType, index) => {
    switch (travelerType) {
      case PassengerDetailsFormFields.ADULT:
        return parseContentfulDynamicContent(
          contentfulExtrasPage &&
            contentfulExtrasPage[0]?.fields?.adultTravelerTitle,
          ++index
        );

      case PassengerDetailsFormFields.CHILD:
        return parseContentfulDynamicContent(
          contentfulExtrasPage &&
            contentfulExtrasPage[0]?.fields?.childTravelerTitle,
          ++index
        );

      default:
        return parseContentfulDynamicContent(
          contentfulExtrasPage &&
            contentfulExtrasPage[0]?.fields?.adultTravelerTitle,
          ++index
        );
    }
  };

  useEffect(() => {
    setBaggageOptions(baggageDetails?.baggageProducts);
  }, [baggageDetails]);

  return (
    <>
      {baggageDetails?.showBaggageOptions && (
        <>
          <p className="baggage-title-selection">
            {contentfulCheckoutPassengerDetails &&
              contentfulCheckoutPassengerDetails[0].fields?.addHoldBaggage}
          </p>
          <div className="baggage-component">
            <div className="baggage-container">
              {baggageOptions &&
                baggageOptions.map((currentItem, index) => {
                  return (
                    <div key={index} className="baggage-per-person">
                      <div className="passenger-section">
                        <div className="traveller-description">
                          {getTravellerTypeDescription(
                            currentItem.travellerType,
                            index
                          )}
                        </div>
                      </div>
                      <div className="bagage-selection-parent">
                        <div className="baggage-section">
                          <BaggageDropdownComponent
                            currentPassenger={currentItem}
                            preselectedValue={findPreselectedItem(currentItem)}
                            onBaggageSelectionChanged={
                              onBaggageSelectionChanged
                            }
                            isLoading={isLoading}
                            loadingPassengerId={loadingPassengerId}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      )}
    </>
  );
};
