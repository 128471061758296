export const ValidationRulesEnum = {
    REQUIRED: "required",
    MIN_LENGTH: "minLength",
    FIRST_NAME_MIN_LENGTH: "firstNameMinLength",
    FIRST_NAME_MAX_LENGTH: "firstNameMaxLength",
    LAST_NAME_MIN_LENGTH: "lastNameMinLength",
    LAST_NAME_MAX_LENGTH: "lastNameMaxLength",
    MAX_LENGTH: "maxLength",
    INVALID_DATE: "invalidDate",
    PATTERN: "pattern",
    PATTERN_DAYS: "patternDays",
    MIN_REQUIRED_AGE: 'minRequiredAge',
    MAX_REQUIRED_AGE: 'maxRequiredAge',
    INVALID_DATE_RANGE: 'INVALID_DATE_RANGE'
}

export const ValidationPattern = {
    ALPHA_NUMERIC: /^[a-zA-Z0-9]+$/,
    ALPHA_NUMERIC_AND_SPACE: /^[a-zA-Z0-9 ]+$/,
    ONLY_2_DIGITS_FOR_DAYS: /^(?:0?[1-9]|[12]\d|3[01])$/,
    ONLY_2_DIGITS_FOR_MONTH: /^(?:0?[1-9]|1[0-2])$/,
    ONLY_DIGITS: /^\d+$/,
    ONLY_2_DIGITS: /^\d{2}$/,
    ONLY_4_DIGITS: /^\d{4}$/,
    ONLY_DIGITS_BETWEEN_1_AND_2_CHARS: /^\d{1,2}$/,
    ONLY_DIGITS_BETWEEN_1_AND_4_CHARS: /^\d{1,4}$/,
    ONLY_CHARS: /^[a-zA-Z\s]+$/,
    EMAIL_RULE: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    PHONE_NUMBER_RULE: /\b(?:\+?[\d-]*\s*)?(?:\(\d+\)\s*)?(?:[\d-]*\s*)?\d+\b/,
    ONLY_DIGITS_AND_LETTERS_ALLOWED: /^[a-zA-Z0-9 ]+$/,
}
