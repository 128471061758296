import { useState } from "react";
import "./accordion.scss";

export const Accordion = ({
  children,
  labelText,
  startingExpandedState,
  onAccordionClick,
  defaultSearchUsed
}) => {
  const [isExpanded, setIsExpanded] = useState(startingExpandedState);

  const handleLabelClicked = () => {
    setIsExpanded(!isExpanded);

    if (onAccordionClick && typeof onAccordionClick === "function") {
      onAccordionClick();
    }
  };

  return (
    <div className={`accordion-content ${defaultSearchUsed? "remove-border" : ""}`}>
      <div
        className="accordion-header-container"
        onClick={() => handleLabelClicked()}
      >
        <span className="accordion-label">{labelText}</span>
        <div className="icon">
          <div className={isExpanded ? "icon-drop-up" : "icon-drop-down"}></div>
        </div>
      </div>
      {isExpanded && children}
    </div>
  );
};
