import React, {useEffect, useState} from 'react';
import {Stepper, Step} from '@mui/material';
import './stepper-bar.component.scss';

import HotelInformationKLM from "../../assets/icons/klm/ic_building.svg";
import HotelInformationTransavia from "../../assets/icons/transavia/ic_building.svg";
import TravelKLM from "../../assets/icons/klm/calendar-S.svg";
import TravelTransavia from "../../assets/icons/transavia/Calendar.svg";
import FlightKLM from "../../assets/icons/klm/flights-S.svg";
import FlightTransavia from "../../assets/icons/transavia/outbound.svg";
import AccommodationKLM from "../../assets/icons/klm/hotel-S.svg";
import AccommodationTransavia from "../../assets/icons/transavia/transavia_icon_bed.svg";
import SummaryKLM from "../../assets/icons/klm/trip-report-S.svg";
import SummaryTransavia from "../../assets/icons/transavia/Terms-a.svg";
import {GetAffiliate} from "../../services/general.service";
import {affiliates} from "../../common/enums/affiliates";
import {useWindowSizeDetector} from "../../hooks/useWindowSizeDetector";
import {breakpoints} from "../../common/constants/breakpoints";
import {holidaySteps} from "../../common/enums/holidaySteps";

const affiliate = GetAffiliate();

const stepsDictionary = () => {
    return {
        hotelInformation: <img src={affiliate === affiliates.klm ? HotelInformationKLM : HotelInformationTransavia}/>,
        travel: <img src={affiliate === affiliates.klm ? TravelKLM : TravelTransavia}/>,
        flight: <img src={affiliate === affiliates.klm ? FlightKLM : FlightTransavia} className="flightIcon"/>,
        accommodation: <img src={affiliate === affiliates.klm ? AccommodationKLM : AccommodationTransavia}/>,
        summary: <img src={affiliate === affiliates.klm ? SummaryKLM : SummaryTransavia}/>,
    }
}

export const StepperBarComponent = ({contentfulHolidayStepper, setStepperClick}) => {
    const steps = stepsDictionary();
    const windowSize = useWindowSizeDetector();
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [toggleStepClicked, setToggleStepClicked] = useState(false);

    useEffect(() => {
        windowSize.width > breakpoints.SM
            ? setIsMobile(false)
            : setIsMobile(true)
        windowSize.width > breakpoints.SM && windowSize.width < breakpoints.XL
            ? setIsTablet(true)
            : setIsTablet(false);
    },[windowSize.width]);

    const getContent = (key) => {
        if (key === holidaySteps.HOTEL_INFORMATION && (isMobile || isTablet)) {
            return contentfulHolidayStepper[0]?.fields['hotel'];
        }
        return contentfulHolidayStepper[0]?.fields[key]
    }

    const handleOnStepClick = (key) => {
        setToggleStepClicked(!toggleStepClicked)
        setStepperClick({key,toggleStepClicked});
    }

    return (
        <div className="stepper-bar-component">
            <div className="stepper">
                <Stepper>
                    {Object.entries(steps).map(([key, value], index) => (
                        <Step
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            className={`step ${index === 0 ? 'first-stepper-element' : ''}`}
                            onClick={() => handleOnStepClick(key)}
                            key={key}>
                            {value}
                            <div className="step-description">
                                {
                                    contentfulHolidayStepper && getContent(key)
                                }
                            </div>
                        </Step>
                    ))}
                </Stepper>
            </div>
        </div>
    );
}

export default StepperBarComponent;
