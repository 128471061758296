import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { PassengerDetailsFormFields } from "../../../common/constants/passengerDetailsFormFields";
import { parseContentfulDynamicContent } from "../../../services/contentful.service";
import CabinErrorComponent from "./cabin-error-message.component";

const CabinTripComponent = ({
  luggageData,
  baggageDetails,
  contentfulExtrasPage,
  onCabinBaggageSelectionChanged,
  cabinSelectionState,
  errorState,
  setErrorState,
  isBaggageValid,
  onValidate,
}) => {
  const [cabinBaggageOptions, setCabinBaggageOptions] = useState([]);
  const [selectedPassengers, setSelectedPassengers] = useState({});
  const [loadingPassengers, setLoadingPassengers] = useState({});
  const [isCabinBaggageSelected, setIsCabinBaggageSelected] = useState(false);
  const [isAnyPassengerSelected, setIsAnyPassengerSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const getTravellerTypeDescription = (travellerType, index) => {
    switch (travellerType) {
      case PassengerDetailsFormFields.ADULT:
        return parseContentfulDynamicContent(
          contentfulExtrasPage &&
            contentfulExtrasPage[0]?.fields?.adultTravelerTitle,
          ++index
        );
      case PassengerDetailsFormFields.CHILD:
        return parseContentfulDynamicContent(
          contentfulExtrasPage &&
            contentfulExtrasPage[0]?.fields?.childTravelerTitle,
          ++index
        );
      default:
        return parseContentfulDynamicContent(
          contentfulExtrasPage &&
            contentfulExtrasPage[0]?.fields?.adultTravelerTitle,
          ++index
        );
    }
  };

  const handleChange = async (event, passenger) => {
    const travellerIdentifier = passenger.travellerIdentifier;

    if (loadingPassengers[travellerIdentifier] || isLoading) {
      return;
    }

    const isSelected = !passenger.isSelected;

    setIsLoading(true);
    setLoadingPassengers((prevState) => ({
      ...prevState,
      [travellerIdentifier]: true,
    }));

    const newSelectedPassengers = {
      ...selectedPassengers,
      [travellerIdentifier]: isSelected,
    };
    setSelectedPassengers(newSelectedPassengers);

    if (passenger) {
      const optionCode = isSelected ? passenger.ancillaryOptionCode : "";
      const requestData = {
        travellerIdentifier,
        ancillaryOptionCode: optionCode,
      };

      try {
        await onCabinBaggageSelectionChanged(requestData);
      } catch (error) {
        setSelectedPassengers((prevState) => ({
          ...prevState,
          [travellerIdentifier]: !isSelected,
        }));
      } finally {
        setLoadingPassengers((prevState) => ({
          ...prevState,
          [travellerIdentifier]: false,
        }));
        setIsLoading(false);
        onValidate(true);
      }
    }
  };

  useEffect(() => {
    setCabinBaggageOptions(baggageDetails.cabinBaggageProducts);
    setIsAnyPassengerSelected(
      baggageDetails.cabinBaggageProducts.some(
        (baggagePerPassenger) => baggagePerPassenger.isSelected
      )
    );
  }, [baggageDetails]);

  useEffect(() => {
    if (isCabinBaggageSelected) {
      setErrorState({ ...errorState, noCabinPerPassengerSelected: false });
    }
  }, [isCabinBaggageSelected]);

  useEffect(() => {
    if (errorState?.noCabinPerPassengerSelected && !isBaggageValid) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [errorState, isBaggageValid]);

  useEffect(() => {
    const updatedPassengers = {};
    cabinBaggageOptions.forEach((baggagePerPassenger) => {
      if (baggagePerPassenger.ancillaryOptionCode === "") {
        updatedPassengers[baggagePerPassenger.travellerIdentifier] = false;
      }
    });

    setSelectedPassengers((prev) => ({ ...prev, ...updatedPassengers }));
  }, []);

  return (
    <>
      {cabinSelectionState?.cabinSelected && cabinBaggageOptions.length > 0 && (
        <div>
          <CabinErrorComponent luggageData={luggageData} isError={isError} />
          <p className="cabin-luggage-trip-title">
            {luggageData && luggageData[0]?.fields?.cabinBaggageTripTitle}
          </p>
          <div
            className={`cabin-trip-baggage-container ${isError ? "error" : ""}`}
          >
            {cabinBaggageOptions.map((baggagePerPassenger, index) => (
              <div className="cabin-baggage-item" key={index}>
                <label className="checkbox-label">
                  <div
                    className={`checkbox-container ${
                      baggagePerPassenger.isSelected ? "active" : "inactive"
                    } ${isError ? "error" : ""}${
                      isLoading ? "loading-checkbox" : ""
                    }`}
                    onClick={() => handleChange(null, baggagePerPassenger)}
                  >
                    <div className="checkbox"></div>
                  </div>
                  <span
                    className={`form-cabin-label ${
                      isLoading ? "loading-text-cabin" : ""
                    }`}
                  >
                    {getTravellerTypeDescription(
                      baggagePerPassenger.travellerType,
                      index
                    )}
                  </span>
                </label>
                <span
                  className={`price ${
                    loadingPassengers[baggagePerPassenger.travellerIdentifier]
                      ? "loading-text-price"
                      : ""
                  }`}
                >
                  {loadingPassengers[
                    baggagePerPassenger.travellerIdentifier
                  ] ? (
                    <CircularProgress
                      className="button-loading-spinner-cabin"
                      size={6}
                    />
                  ) : baggagePerPassenger.price ? (
                    <span
                      className={isLoading ? "loading-text-price" : ""}
                    >{`€${baggagePerPassenger.price}`}</span>
                  ) : (
                    "€0,-"
                  )}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default CabinTripComponent;
