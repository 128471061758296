import {StarRatingComponent} from "../../star-rating/star-rating.component";
import {FaTripadvisor} from "react-icons/fa";
import {TripAdvisorRatingComponent} from "../../trip-advisor-rating/trip-advisor-rating.component";
import React from "react";

const InfoWindowContentComponent = ({activeMarker, contentfulHolidaysComponent, contentfulButtons}) => {
    
    const navigateToHolidayPage = () => {
        window.location.href = `${activeMarker.holidayPageLink}`
    }
        return (
                <div className="info-marker-container">
                <img
                    src={activeMarker.imageUrl}
                    alt={activeMarker.hotelName}
                />
                <div className="info-window-content-component">
                    <div>
                        <div className="hotel-name-info">
                            {activeMarker?.hotelName}
                        </div>
                        <div className="ratings-container">
                            <div className="star-rating-container">
                                <StarRatingComponent data={activeMarker?.rating?.starRating}/>
                            </div>
                            <span className="vertical-spacer"></span>
                            <div className="trip-advisor-rating-container">
                                <FaTripadvisor fontSize="1.25rem"/>
                                <TripAdvisorRatingComponent rating={activeMarker?.rating.tripAdvisorRating}/>
                            </div>
                        </div>
                    </div>

                    <div className="price-marker-container">
                        <div className="holiday-price-wrapper">
                            <div className="holiday-card-price-block">
                                <span className="info-price-description">{contentfulHolidaysComponent && contentfulHolidaysComponent[0]?.fields?.priceFromText}</span>
                                <span className="info-price-text">
                                € {activeMarker?.pricePerPerson}
                                </span>
                                <span className="info-price-description">{contentfulHolidaysComponent && contentfulHolidaysComponent[0]?.fields?.perPersonText}</span>
                            </div>
                        </div>
                        <div className="button-wrapper">
                            <button onTouchStart={navigateToHolidayPage} onClick={navigateToHolidayPage} className="button button-accept">
                                {contentfulButtons && contentfulButtons[0]?.fields?.lookAt}
                            </button>
                        </div>
                    </div>
                </div>
                </div>
            );
}

export default InfoWindowContentComponent;
