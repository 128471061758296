import * as React from "react";
import "../../../assets/scss/common/flag.scss";

export const FlagComponent = ({ country, size }) => {
  const formatCountryCode = () => {
    return country.toLowerCase();
  };
  return (
    <img
      src={`https://countryflags.airtrade.com/${formatCountryCode()}.png`}
      className={[`flag-${size} flag-affiliate`]}
    />
  );
};
