import "./tripadvisor-rating.component.scss";

export const TripadvisorRatingComponent = ({ data }) => {
  const validData = typeof data === 'number';
  const maxRating = 5;
  const filledIcons = validData ? Math.floor(data) : null;
  const hasHalfFilledIcon = validData ? (data % 1 >= 0.5) : false;
  const emptyIcons = validData ? Math.floor(maxRating - data) : null;

  return (
    <div className="tripadvisor-rating-component">
      {
        validData
          ? <>
            {
              [...Array(filledIcons).keys()].map((e, i) => {
                return (
                  <div key={i} className="tripadvisor-filled-icon"></div>
                );
              })
            }
            {
              hasHalfFilledIcon
                ? <div className="tripadvisor-half-filled-icon"></div>
                : null
            }
            {
              [...Array(emptyIcons).keys()].map((e, i) => {
                return (
                  <div key={i} className="tripadvisor-empty-icon"></div>
                );
              })
            }
          </>
          : <>
            {
              [...Array(maxRating).keys()].map((e, i) => {
                return (
                  <div key={i} className="tripadvisor-empty-icon"></div>
                );
              })
            }
          </>
      }
    </div>
  );
};