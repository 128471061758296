import "./form-input-dropdown.component.scss";
import React, { useEffect, useRef, useState } from "react";
import { GetAffiliate } from "../../services/general.service";
import { affiliates } from "../../common/enums/affiliates";

export const FormInputDropdownComponent = ({
  name,
  label,
  value,
  onChange,
  isError,
  inputOptions,
  inputOptionObjects,
  setStateUp,
  isStateLifted,
  isTypeAheadAllowed,
  preselectedValue,
}) => {
  const selectedOptionRef = useRef(null);
  const containerRef = useRef(null);
  const [isDropdownExtended, setDropdownExtended] = useState(false);
  const [inputValue, setInputValue] = useState(
    inputOptions[0] || preselectedValue.name
  );
  const [inputHelper, setInputHelper] = useState(
    inputOptions[0] || preselectedValue
  );
  const affiliate = GetAffiliate();
  const isKLM = affiliates.klm === affiliate;

  useEffect(() => {
    if (isDropdownExtended && selectedOptionRef.current) {
      selectedOptionRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }, [inputHelper, isDropdownExtended]);

  const onDropdownOptionClick = (option) => {
    if (
      typeof option === "object" &&
      !(option instanceof String) &&
      option !== null
    ) {
      setInputHelper(option);
      if (isStateLifted) {
        setStateUp(option.name);
      }
      setInputValue(option.name);
      onChange({ name: option.name, code: option.code });
    } else {
      if (isStateLifted) {
        setStateUp(option);
      }
      setInputValue(option);
      onChange(option);
    }
    setDropdownExtended(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setDropdownExtended(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [containerRef]);

  return (
    <div className="form-input-dropdown-component" ref={containerRef}>
      {isTypeAheadAllowed && (
        <input
          onClick={() => setDropdownExtended(true)}
          type="text"
          className="input-placeholder-text"
          value={inputValue}
        />
      )}
      <label className="label-description">
        <span className={isError ? "error" : ""}>{label}</span>
        <div
          className={`form-input-dropdown ${
            isDropdownExtended ? "custom-focus" : ""
          }`}
          onClick={() => setDropdownExtended(!isDropdownExtended)}
        >
          <div
            className="input-placeholder"
          >
            {!isTypeAheadAllowed && inputValue}
          </div>
          <div
            className="icon-arrow"
          >
            <div
              className={isDropdownExtended ? "icon-drop-up" : "icon-drop-down"}
            ></div>
          </div>
          {isDropdownExtended && (
            <ul className="dropdown">
              {inputOptions?.length > 0 &&
                inputOptions.map((option, index) => (
                  <div className="dropdown-item-wrapper" key={option}>
                    {isKLM && option === inputValue && (
                      <div className="dropdown-item-spacer selected"></div>
                    )}
                    <li
                      className={`dropdown-item ${
                        option === inputValue ? "dropdown-item-selected" : ""
                      }`}
                      onClick={() => onDropdownOptionClick(option)}
                      key={index}
                      value={option}
                    >
                      {option}
                    </li>
                  </div>
                ))}
              {inputOptionObjects?.length > 0 &&
                inputOptionObjects.map((option, index) => (
                  <div className="dropdown-item-wrapper" key={option?.code}>
                    {isKLM && option?.code === inputHelper?.code && (
                      <div className="dropdown-item-spacer selected"></div>
                    )}
                    <li
                      className={`dropdown-item ${
                        option?.code === inputHelper?.code
                          ? "dropdown-item-selected"
                          : ""
                      }`}
                      ref={
                        option?.code === inputHelper?.code
                          ? selectedOptionRef
                          : null
                      }
                      onClick={() => onDropdownOptionClick(option)}
                      key={option?.code}
                      value={option.name}
                    >
                      {option.name}
                    </li>
                  </div>
                ))}
            </ul>
          )}
        </div>
      </label>
    </div>
  );
};
