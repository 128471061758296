import { PagesConstant } from "../../../common/constants/pages";
import "./check-availability.helper.scss";

const inputData = {
  holidayKey: null,
  metaInfo: null,
  priceDetailInfo: null,
  contentfulCheckAvailability: null,
  showCustomModal: null,
  hideCustomMobile: null,
};

const setCheckAvailabilityModalContent = (
  header,
  info,
  buttonReturnText,
  buttonContinueText,
  priceChange,
  oldTotalPriceText,
  oldTotalPrice,
  newTotalPriceText,
  newTotalPrice
) => {
  return (
    <div className="checkAvailabilityModalContent">
      <div className="checkAvailabilityModalContent-header">{header}</div>
      <div className="checkAvailabilityModalContent-info">{info}</div>
      {priceChange ? (
        <div className="checkAvailabilityModalContent-price-change">
          <div>{oldTotalPriceText}</div>
          <div>€&nbsp;{oldTotalPrice}</div>
          <div>{newTotalPriceText}</div>
          <div>€&nbsp;{newTotalPrice}</div>
        </div>
      ) : null}
      <div className="checkAvailabilityModalContent-buttons">
        {buttonReturnText ? (
          <div onClick={inputData.hideCustomMobile} className="return">
            {buttonReturnText}
          </div>
        ) : null}
        {buttonContinueText ? (
          <div onClick={navigateToCheckOut} className="continue">
            {buttonContinueText}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const processCheckAvailabilityResponse = (
  checkAvailabilityResponse,
  contentfulCheckAvailability,
  showCustomModal,
  hideCustomMobile
) => {
  inputData.holidayKey = checkAvailabilityResponse?.data?.holidayKey;
  inputData.metaInfo = checkAvailabilityResponse?.data?.metaInfo;
  inputData.priceDetailInfo =
    checkAvailabilityResponse?.data?.holidayDetailInfo?.priceDetailInfo;
  inputData.contentfulCheckAvailability = contentfulCheckAvailability;
  inputData.showCustomModal = showCustomModal;
  inputData.hideCustomMobile = hideCustomMobile;
  checkScenario(showCustomModal);
};

const checkScenario = (showCustomModal) => {
  if (inputData.metaInfo.holidayUnavailable) {
    scenarioNotAvailable(showCustomModal);
  } else {
    if (inputData.metaInfo.holidayPriceChanged) {
      scenarioPriceChange(showCustomModal);
    } else {
      scenarioAvailable(showCustomModal);
    }
  }
};

const scenarioAvailable = (showCustomModal) => {
  showCustomModal(
    false,
    setCheckAvailabilityModalContent(
      inputData.contentfulCheckAvailability.scenarios.available.header,
      inputData.contentfulCheckAvailability.scenarios.available.info,
      false,
      inputData.contentfulCheckAvailability.buttons.continue,
      false
    ),
    false,
    false
  );
};

const scenarioPriceChange = (showCustomModal) => {
  showCustomModal(
    false,
    setCheckAvailabilityModalContent(
      inputData.contentfulCheckAvailability.scenarios.priceChange.header,
      inputData.contentfulCheckAvailability.scenarios.priceChange.info,
      inputData.contentfulCheckAvailability.buttons.return,
      inputData.contentfulCheckAvailability.buttons.continue,
      true,
      inputData.contentfulCheckAvailability.scenarios.priceChange
        .previousTotalPrice,
      inputData.priceDetailInfo.oldTotal,
      inputData.contentfulCheckAvailability.scenarios.priceChange.newTotalPrice,
      inputData.priceDetailInfo.total
    ),
    false,
    false
  );
};

const scenarioNotAvailable = (showCustomModal) => {
  showCustomModal(
    false,
    setCheckAvailabilityModalContent(
      inputData.contentfulCheckAvailability.scenarios.notAvailable.header,
      inputData.contentfulCheckAvailability.scenarios.notAvailable.info,
      inputData.contentfulCheckAvailability.buttons.return,
      false,
      false
    ),
    false,
    false
  );
};

const scenarioTimeout = () => {
  // To be discussed with Steven
};

const navigateToCheckOut = () => {
  const newUrl =
    window.location.origin +
    `${PagesConstant.BASEPATH}/${PagesConstant.EXTRAS}`;
  window.location = newUrl;
};

export const checkAvailabilityHelper = {
  processCheckAvailabilityResponse: processCheckAvailabilityResponse,
};
