import AdultOnly from "./../../../assets/icons/themes/TRANSAVIA/AdultOnly.svg";
import Beach from "./../../../assets/icons/themes/TRANSAVIA/Beach.svg";
import Boutique from "./../../../assets/icons/themes/TRANSAVIA/Boutique.svg";
import Business from "./../../../assets/icons/themes/TRANSAVIA/Business.svg";
import Designhotel from "./../../../assets/icons/themes/TRANSAVIA/Designhotel.svg";
import Family from "./../../../assets/icons/themes/TRANSAVIA/Family.svg";
import Golf from "./../../../assets/icons/themes/TRANSAVIA/Golf.svg";
import Greatlocation from "./../../../assets/icons/themes/TRANSAVIA/Greatlocation.svg";
import LGBT from "./../../../assets/icons/themes/TRANSAVIA/LGBT.svg";
import Luxury from "./../../../assets/icons/themes/TRANSAVIA/Luxury.svg";
import Romantic from "./../../../assets/icons/themes/TRANSAVIA/Romantic.svg";
import Wellness from "./../../../assets/icons/themes/TRANSAVIA/Wellness.svg";

export const transaviaThemeIcons = {
  AdultOnly: AdultOnly,
  Beach: Beach,
  Boutique: Boutique,
  Business: Business,
  Designhotel: Designhotel,
  Family: Family,
  Golf: Golf,
  Greatlocation: Greatlocation,
  LGBT: LGBT,
  Luxury: Luxury,
  Romantic: Romantic,
  Wellness: Wellness,
}