export const insuranceSpinnerMessage = [
    {
        affiliate: "KLM",
        content: [
            {
                cultureCode: "nl-NL",
                spinnerDescription: "Verzekeringen worden geladen..."
            },
            {
                cultureCode: "en-GB",
                spinnerDescription: "Insurance is loading..."
            }
        ]
    },
    {
        affiliate: "TRANSAVIA",
        content: [
            {
                cultureCode: "nl-NL",
                spinnerDescription: "Verzekeringen worden geladen..."
            },
            {
                cultureCode: "en-GB",
                spinnerDescription: "Insurance is loading..."
            }
        ]
    }
]