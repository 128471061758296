import "./transfers-card.component.scss";
import { ReactComponent as PassengerIcon } from "../../../../assets/icons/passengers-S.klm.svg";
import { ReactComponent as PassengerIconHV } from "../../../../assets/icons/transavia/Avatar.svg";
import { ReactComponent as BaggageIcon } from "../../../../assets/icons/bagage-S.svg";
import { ReactComponent as TransferIcon } from "../../../../assets/icons/klm/ic_compare_arrows.svg";
import { ReactComponent as TransferIconHV } from "../../../../assets/icons/transavia/hv_ic_compare_arrows.svg";

import { GenericButton } from "../../../../components/generic-button/generic-button";
import { GetAffiliate } from "../../../../services/general.service";
import { affiliates } from "../../../../common/enums/affiliates";
import { useEffect, useRef } from "react";
import { CircularProgress } from "@mui/material";

export const TransferCardComponent = ({
  transferOption,
  contentfulTransfer,
  contentfulButtons,
  onSelectTransfer,
  isWaitingForNetwork,
  activeCard,
  setActiveCard,
}) => {
  const disabledState = useRef(null);

  useEffect(() => {
    if (transferOption) {
      disabledState.current = false;
    }
  }, [transferOption]);

  const setClassName = () => {
    const classList = transferOption.selected
      ? `card-component transfer-selected`
      : `card-component`;

    const classOpacity =
      isWaitingForNetwork && activeCard !== transferOption.optionKey
        ? " opacity-50"
        : "";

    return classList + classOpacity;
  };

  const setDisabledState = () => {
    return isWaitingForNetwork && disabledState.current ? true : false;
  };

  const handleAddTransferOption = (transferOption) => {
    setActiveCard(transferOption?.optionKey);
    disabledState.current = true;
    onSelectTransfer(transferOption?.optionKey);
  };

  const handleRemoveTransferOption = () => {
    setActiveCard(transferOption?.optionKey);
    disabledState.current = true;
    onSelectTransfer("");
  };

  return (
    <div className={setClassName()}>
      {transferOption?.image && (
        <div className="card-image">
          <img
            className="transfer-image"
            src={transferOption?.image}
            alt={transferOption?.bookingClass}
          />
        </div>
      )}

      <div className="card-description">
        <div className="card-content-title">
          <div className="card-title">{transferOption?.bookingClass}</div>
          <div className="icon-with-description">
            {GetAffiliate() === affiliates.klm ? (
              <PassengerIcon className="icon svg-icon" />
            ) : (
              <PassengerIconHV className="icon svg-icon" />
            )}
            {transferOption?.numberOfTravellers} {contentfulTransfer?.passenger}
          </div>

          <div className="icon-with-description">
            <BaggageIcon className="icon svg-icon" />
            {transferOption?.numberOfBags} {contentfulTransfer?.suitcases}
          </div>

          <div className="icon-with-description">
            {GetAffiliate() === affiliates.klm ? (
              <TransferIcon className="icon svg-icon" />
            ) : (
              <TransferIconHV className="icon svg-icon" />
            )}
            {contentfulTransfer?.airportAndHotel}
          </div>
        </div>

        <div className="card-action">
          <div className="card-button-container">
            {!transferOption?.selected ? (
              <div className="card-price-block">
                <span className="card-price-block">
                  {"+ € "} {transferOption?.price}
                </span>
                <span className="total-description">
                  {" "}
                  {contentfulTransfer?.total}
                </span>
              </div>
            ) : (
              <div className="spacer"></div>
            )}

            {!transferOption?.selected ? (
              <GenericButton
                type="button"
                className="transfer-select-button"
                disabledState={setDisabledState()}
                onClick={() => handleAddTransferOption(transferOption)}
              >
                {setDisabledState() ? (
                  <>
                    <div className="spinner-button-wrapper">
                      <div className="spinner-button">
                        <CircularProgress
                          className="button-loading-spinner"
                          size={6}
                        />
                        <div className="loading-text">
                          {contentfulButtons?.adding}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  contentfulButtons?.add
                )}
              </GenericButton>
            ) : (
              <div className="transfer-option-selected">
                {!setDisabledState() && (
                  <div>
                    <div className="icon-container">
                      <div className="icon"></div>
                    </div>
                    <div className="selected">{contentfulButtons?.added}</div>
                  </div>
                )}
                <div
                  className="deselect-transfer-option"
                  onClick={handleRemoveTransferOption}
                >
                  {setDisabledState() ? (
                    <div className="spinner-button-wrapper">
                      <div className="spinner-button">
                        <CircularProgress
                          className="button-loading-spinner"
                          size={6}
                        />
                        <div className="loading-text-removing">
                          {contentfulButtons?.removing}
                        </div>
                      </div>
                    </div>
                  ) : (
                    contentfulButtons?.remove
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
