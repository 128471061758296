import './home-stayer.component.scss';
import {TravelerNameComponent} from "../traveler-details/traveler-name/traveler-name.component";
import {PhoneNumberInputComponent} from "../traveler-details/main-booker/phone-number-input/phone-number-input.component";
import Tooltip from "../../../components/tooltip/tooltip";
import React, {useState} from "react";
import {ReactComponent as IconInfo} from "../../../assets/icons/klm/icon-information.svg";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export const HomeStayerComponent = ({
                                        contactDetailInfo,
                                        name,
                                        register,
                                        control,
                                        clearErrors,
                                        errors,
                                        contentfulCheckoutPassengerDetails,
                                        contentfulCheckoutFormValidators,
                                    }) => {

    const [tooltipVisible, setTooltipVisible] = useState({});

    const handleMouseOver = (key) => {
        setTooltipVisible((prevState) => ({...prevState, [key]: true}));
    };

    const handleMouseOut = (key) => {
        setTooltipVisible((prevState) => ({...prevState, [key]: false}));
    };

    return <div className="home-stayer-component">
        <div
            className="home-stayer-title">{contentfulCheckoutPassengerDetails && contentfulCheckoutPassengerDetails[0]?.fields?.homeStayerTitle}
            <div
                className="content-item-icon"
                onMouseOver={() => handleMouseOver("home-stayer")}
                onMouseOut={() => handleMouseOut("home-stayer")}
            >
            </div>
        </div>

        {contactDetailInfo?.homeStayer &&
        <TravelerNameComponent
            control={control}
            travellerInfo={contactDetailInfo?.homeStayer}
            name={name}
            register={register}
            contentfulCheckoutPassengerDetails={contentfulCheckoutPassengerDetails}
            contentfulCheckoutFormValidators={contentfulCheckoutFormValidators}
            errors={errors}
            clearErrors={clearErrors}
        />
        }
        {contactDetailInfo?.phonePrefixCodeOptions &&
        <PhoneNumberInputComponent
            phoneCodes={contactDetailInfo?.phonePrefixCodeOptions}
            defaultPhoneCode={contactDetailInfo?.homeStayer?.phoneNumberCountryCode}
            defaultPhoneSuffix={contactDetailInfo?.homeStayer?.phoneNumberSuffix}
            register={register}
            control={control}
            errors={errors}
            name={name}
            contentfulCheckoutPassengerDetails={contentfulCheckoutPassengerDetails}
            contentfulCheckoutFormValidators={contentfulCheckoutFormValidators}
            clearErrors={clearErrors}
        />
        }
    </div>
}
