import React, { useEffect, useState } from "react";
import { GetAffiliate } from "../../services/general.service";
import { ReactComponent as IconInfo } from "../../assets/icons/klm/icon-information.svg";
import Tooltip from "../tooltip/tooltip";
import { affiliates } from "../../common/enums/affiliates";
import { ShoppingCartTooltipEnums } from "../../common/enums/shoppingCartTooltipEnums";

export const PriceOverviewComponent = ({
  data,
  contentfulHolidayPageSummary,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState({});
  const [priceOverview, setPriceOverview] = useState(data);

  useEffect(() => {
    setPriceOverview(data);
  }, [data]);

  const handleMouseOver = (key) => {
    setTooltipVisible((prevState) => ({ ...prevState, [key]: true }));
  };

  const handleMouseOut = (key) => {
    setTooltipVisible((prevState) => ({ ...prevState, [key]: false }));
  };

  return (
    <div className="price-overview-component">
      <div className="shopping-cart-primary-header">
        {contentfulHolidayPageSummary &&
          contentfulHolidayPageSummary[0]?.fields?.priceOverview}
      </div>

      {priceOverview?.pricePerTraveller !== "" && (
        <div className="content-item">
          <div>
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields?.traveller}{" "}
            ({"x" + priceOverview?.travellers})
          </div>{" "}
          <div className="content-item-price">
            € {priceOverview?.pricePerTraveller}
          </div>
        </div>
      )}

      {priceOverview?.discount !== "" && (
        <div className="content-item klm-green">
          {priceOverview?.discount === "" ? null : (
            <>
              <div>
                {contentfulHolidayPageSummary &&
                  contentfulHolidayPageSummary[0]?.fields
                    ?.holidayPageSummaryExtension?.packageHolidayDiscount}
              </div>{" "}
              <div className="content-item-price">
                - € {priceOverview?.discount}
              </div>
            </>
          )}
        </div>
      )}

      {/* Voucher Code Discount */}
      {priceOverview?.voucherDiscount !== "" && (
        <div className="content-item klm-green">
          {priceOverview?.voucherDiscount === "" ? null : (
            <>
              <div>
                {contentfulHolidayPageSummary &&
                  contentfulHolidayPageSummary[0]?.fields
                    ?.holidayPageSummaryExtension?.voucherCode}
              </div>{" "}
              <div className="content-item-price">
                - € {priceOverview?.voucherDiscount}
              </div>
            </>
          )}
        </div>
      )}

      {/* Baggage price */}
      {priceOverview?.baggageCost && (
        <div className="content-item">
          <div>
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields?.bagage}
          </div>{" "}
          <div className="content-item-price">
            € {priceOverview?.baggageCost}
          </div>
        </div>
      )}

      {priceOverview?.rentalCarCost && (
        <div className="content-item">
          <div>
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields
                ?.holidayPageSummaryExtension?.auto}
          </div>{" "}
          <div className="content-item-price">
            € {priceOverview?.rentalCarCost}
          </div>
        </div>
      )}

      {priceOverview?.transferCost && (
        <div className="content-item">
          <div>
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields
                ?.holidayPageSummaryExtension?.transfers}
          </div>{" "}
          <div className="content-item-price">
            € {priceOverview?.transferCost}
          </div>
        </div>
      )}

      {/* Insurance */}
      {priceOverview?.insuranceCost && (
        <div className="content-item">
          <div>
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields?.insurance.title}
          </div>{" "}
          <div className="content-item-price">
            € {priceOverview?.insuranceCost}
          </div>
        </div>
      )}

      {priceOverview?.guaranteeFundPerTraveller !== "" && (
        <div className="content-item">
          <div>
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields?.sgr}{" "}
            (x{priceOverview?.guaranteeFundQuantity}){" "}
            <div
              className="content-item-icon"
              onMouseOver={() => handleMouseOver(ShoppingCartTooltipEnums.SGR)}
              onMouseOut={() => handleMouseOut(ShoppingCartTooltipEnums.SGR)}
            >
              <IconInfo className="icon-inform" />
              {GetAffiliate() === affiliates.klm
                ? tooltipVisible["SGR"] && (
                    <Tooltip
                      header={
                        contentfulHolidayPageSummary &&
                        contentfulHolidayPageSummary[0]?.fields?.sgrTitle
                      }
                      body={
                        contentfulHolidayPageSummary &&
                        contentfulHolidayPageSummary[0]?.fields?.sgrContent
                      }
                    />
                  )
                : GetAffiliate() === affiliates.transavia
                ? tooltipVisible[ShoppingCartTooltipEnums.SGR] && (
                    <Tooltip
                      header={
                        contentfulHolidayPageSummary &&
                        contentfulHolidayPageSummary[0]?.fields?.sgrTitle
                      }
                      body={
                        contentfulHolidayPageSummary &&
                        contentfulHolidayPageSummary[0]?.fields?.sgrContent
                      }
                    />
                  )
                : null}
            </div>
          </div>
          <div className="content-item-price">
            € {priceOverview?.guaranteeFundPerTraveller}
          </div>
        </div>
      )}

      {priceOverview?.calamityFund !== "" && (
        <div className="content-item">
          <div>
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields?.calamityFund}
            <div
              className="content-item-icon"
              onMouseOver={() =>
                handleMouseOver(ShoppingCartTooltipEnums.Calamityfund)
              }
              onMouseOut={() =>
                handleMouseOut(ShoppingCartTooltipEnums.Calamityfund)
              }
            >
              <IconInfo className="icon-inform" />
              {tooltipVisible[ShoppingCartTooltipEnums.Calamityfund] && (
                <Tooltip
                  header={
                    contentfulHolidayPageSummary &&
                    contentfulHolidayPageSummary[0]?.fields?.calamityFund
                  }
                  body={
                    contentfulHolidayPageSummary &&
                    contentfulHolidayPageSummary[0]?.fields
                      ?.calamiteitenfondsContent
                  }
                />
              )}
            </div>
          </div>{" "}
          <div className="content-item-price">
            € {priceOverview?.calamityFund}
          </div>
        </div>
      )}

      {priceOverview?.bookingCost !== "" && (
        <div className="content-item">
          <div>
            {" "}
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields?.bookingCost}
          </div>{" "}
          <div className="content-item-price">
            € {priceOverview?.bookingCost}
          </div>
        </div>
      )}

      {priceOverview?.paymentCost !== "" && (
        <div className="content-item">
          <div>
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields?.cardFeesLable}
          </div>
          <div className="content-item-price">
            € {priceOverview?.paymentCost}
          </div>
        </div>
      )}
    </div>
  );
};
