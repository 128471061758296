import { createSlice } from '@reduxjs/toolkit';

export const holidaySlice = createSlice({
  name: 'holiday',
  initialState: { holidayKey: null },
  reducers: {
    setHolidayKey: (state, action) => {
      state.holidayKey = action.payload;
    },
  },
});

export const { setHolidayKey } = holidaySlice.actions;

export default holidaySlice;