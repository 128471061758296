import "./traveler-details.component.scss";
import React, { useEffect, useState } from "react";
import { Alert } from "@mui/material";
import { parseContentfulDynamicContent } from "../../../services/contentful.service";
import { ValidationRulesEnum } from "../../../common/enums/validationRulesEnum";
import { GenderRadioBoxComponent } from "./gender-radio-box/gender-radio-box.component";
import { DataInputComponent } from "./data-inputs-component/data-inputs-component";
import { PassengerDetailsFormFields } from "../../../common/constants/passengerDetailsFormFields";
import { TravelerNameComponent } from "./traveler-name/traveler-name.component";
import { Controller } from "react-hook-form";
import { MainBookerComponent } from "./main-booker/main-booker.component";
import { FrequentFlyerComponent } from "./frequent-flyer/frequent-flyer.component";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export const TravelerDetailsComponent = ({
    index,
    name,
    checkOutData,
    travellerInfo,
    register,
    unregister,
    control,
    setValue,
    setError,
    clearErrors,
    errors,
    contentfulCheckoutPassengerDetails,
    contentfulCheckoutFormValidators,
}) => {
    const [day, setDay] = useState();
    const [month, setMonth] = useState();
    const [year, setYear] = useState();
    const [dayOfBirth, setDayOfBirth] = useState();
    const [lastEditedDateField, setLastEditedDateField] = useState(null);
    const [headerText, setStateHeaderText] = useState(null);
    const [gender, setGender] = useState(travellerInfo.gender);

    useEffect(() => {
        if (contentfulCheckoutPassengerDetails) {
            setStateHeaderText(setHeaderText());
        }
    }, [contentfulCheckoutPassengerDetails]);

    useEffect(() => {
        validateDates({ day, month, year });

        if (day && day.length === 2 && day > 0 && day <= 31) {
            clearErrors(`${name}.${PassengerDetailsFormFields.DAY}`);
        }
        if (month && month.length === 2 && month > 0 && month <= 12) {
            clearErrors(`${name}.${PassengerDetailsFormFields.MONTH}`);
        }
    }, [day, month, year, contentfulCheckoutFormValidators]);

    const formatToTwoDigits = (value) => {
        return value.toString().length === 1 ? `0${value}` : value.toString();
    };

    const setDateOfBirthValues = (data) => {
        const { day, month, year } = data;
        let normalizedYear = year;

        if (year && year.length === 2) {
            const currentYear = new Date().getFullYear();
            const currentCentury = Math.floor(currentYear / 100) * 100;
            normalizedYear = currentCentury + parseInt(year);
        }

        if (day) {
            setDay(formatToTwoDigits(day));
            setLastEditedDateField(PassengerDetailsFormFields.DAY);
        }
        if (month) {
            setMonth(formatToTwoDigits(month));
            setLastEditedDateField(PassengerDetailsFormFields.MONTH);
        }
        if (normalizedYear) {
            setYear(normalizedYear.toString());
            setLastEditedDateField(PassengerDetailsFormFields.YEAR);
        }
        setDayOfBirth(`${year}-${month}-${day}`)
    };

    const formatStringToDate = (date) => {
        const dateArr = date.split("-");
        return new Date(dateArr.join("-"));
    };

    const checkIfDateIsValid = (date) => {
        const isValidDate = formatStringToDate(date);
        return isValidDate instanceof Date && !isNaN(isValidDate);
    };

    const checkForMinRangeAgeValidDate = (minDate, selectedDate) => {
        return new Date(minDate) <= formatStringToDate(selectedDate);
    };

    const checkForMaxRangeAgeValidDate = (maxDate, selectedDate) => {
        return new Date(maxDate) >= formatStringToDate(selectedDate);
    };

    const reverseDateFormat = (originalDate) => {
        const parts = originalDate.split("-");
        if (parts.length === 3) {
            const [year, month, day] = parts;
            return `${day}-${month}-${year}`;
        }
        return originalDate;
    };

    const validateDates = () => {
        const minDateAllowed = travellerInfo.minDateOfBirth;
        const maxDateAllowed = travellerInfo.maxDateOfBirth;

        const dateOfBirth =
            day !== "" && month !== "" && year !== ""
                ? `${year}-${month}-${day}`
                : "";

        if (!day && !month && !year) {
            return (
                contentfulCheckoutFormValidators &&
                contentfulCheckoutFormValidators[0]?.fields?.dateRequired
            );
        }

        if (year && year.length !== 4) {
            setError(`${name}.dateOfBirth`, {
                type: ValidationRulesEnum.INVALID_DATE,
                message:
                    contentfulCheckoutFormValidators &&
                    contentfulCheckoutFormValidators[0]?.fields?.invalidDate,
            });
            return (
                contentfulCheckoutFormValidators &&
                contentfulCheckoutFormValidators[0]?.fields?.invalidDate
            );
        }

        const isDateValid = checkIfDateIsValid(dateOfBirth);

        const isMaxDateRangeAgeValid = checkForMaxRangeAgeValidDate(
            maxDateAllowed,
            dateOfBirth
        );

        const isMinDateRangeAgeValid = checkForMinRangeAgeValidDate(
            minDateAllowed,
            dateOfBirth
        );

        if (day && month && year) {
            setValue(
                `${name}.${PassengerDetailsFormFields.DATE_OF_BIRTH}`,
                dateOfBirth
            );
        }

        if (!day && !month && !year) {
            return (
                contentfulCheckoutFormValidators &&
                contentfulCheckoutFormValidators[0]?.fields?.dateRequired
            );
        }

        if (day && month && year && !isDateValid) {
            return (
                contentfulCheckoutFormValidators &&
                contentfulCheckoutFormValidators[0]?.fields?.invalidDate
            );
        }

        if (
            travellerInfo?.travellerType === PassengerDetailsFormFields.CHILD ||
            travellerInfo?.travellerType === PassengerDetailsFormFields.INFANT
        ) {
            if (day && day.length === 2 && day > 0 && day <= 31) {
                clearErrors(`${name}.${PassengerDetailsFormFields.DAY}`);
            }

            if (month && month.length === 2 && month > 0 && month <= 12) {
                clearErrors(`${name}.${PassengerDetailsFormFields.MONTH}`);
            }

            clearErrors(`${name}.${PassengerDetailsFormFields.DATE_OF_BIRTH}`);
        }

        if (isDateValid && isMaxDateRangeAgeValid && isMinDateRangeAgeValid) {
            clearErrors(`${name}.${PassengerDetailsFormFields.DATE_OF_BIRTH}`);
            return true;
        }

        if (day && day.length === 2 && day > 0 && day <= 31) {
            clearErrors(`${name}.${PassengerDetailsFormFields.DAY}`);
        }

        if (month && month.length === 2 && month > 0 && month <= 12) {
            clearErrors(`${name}.${PassengerDetailsFormFields.MONTH}`);
        }

        if (year && year.length === 4) {
            clearErrors(`${name}.${PassengerDetailsFormFields.YEAR}`);
        }

        if (!day || !month || !year) {
            const fieldName = `${name}.${lastEditedDateField}`;

            if (
                contentfulCheckoutFormValidators &&
                contentfulCheckoutFormValidators[0]
            ) {
                setError(fieldName, {
                    type: ValidationRulesEnum.INVALID_DATE,
                    message: contentfulCheckoutFormValidators[0]?.fields?.invalidDate,
                });
                return contentfulCheckoutFormValidators[0]?.fields?.invalidDate;
            } else {
                return PassengerDetailsFormFields.INVALID_DATE;
            }
        }

        if (isDateValid) {
            setValue(
                `${name}.${PassengerDetailsFormFields.DATE_OF_BIRTH}`,
                dateOfBirth
            );
            const isMaxDateRangeAgeValid = checkForMaxRangeAgeValidDate(
                maxDateAllowed,
                dateOfBirth
            );
            const isMinDateRangeAgeValid = checkForMinRangeAgeValidDate(
                minDateAllowed,
                dateOfBirth
            );

            if (travellerInfo?.isMainBooker) {
                if (!isMaxDateRangeAgeValid) {
                    const age = travellerInfo?.isDriver || 
                                travellerInfo.minimumAge.toString() === PassengerDetailsFormFields.DEFAULT_ADULT_AGE_US
                        ? travellerInfo?.minimumAge
                        : PassengerDetailsFormFields.DEFAULT_ADULT_AGE;

                    setError(`${name}.${PassengerDetailsFormFields.DATE_OF_BIRTH}`, {
                        type: ValidationRulesEnum.MAX_REQUIRED_AGE,
                        message: parseContentfulDynamicContent(
                            contentfulCheckoutFormValidators[0]?.fields?.minAgeMainbooker,
                            age
                        )
                    });
                    return parseContentfulDynamicContent(
                        contentfulCheckoutFormValidators[0]?.fields?.minAgeMainbooker,
                        age
                    );
                }

                if (!isMinDateRangeAgeValid) {
                    setError(`${name}.${PassengerDetailsFormFields.DATE_OF_BIRTH}`, {
                        type: ValidationRulesEnum.MIN_REQUIRED_AGE,
                        message: parseContentfulDynamicContent(
                            contentfulCheckoutFormValidators[0]?.fields?.maxRequiredAge,
                            reverseDateFormat(minDateAllowed)
                        )
                    });
                    return parseContentfulDynamicContent(
                        contentfulCheckoutFormValidators[0]?.fields?.maxRequiredAge,
                        reverseDateFormat(minDateAllowed)
                    );
                }
            }

            if (
                travellerInfo?.travellerType === PassengerDetailsFormFields.ADULT &&
                (!isMaxDateRangeAgeValid || !isMinDateRangeAgeValid)
            ) {
                if (isDateValid && isMaxDateRangeAgeValid && isMinDateRangeAgeValid) {
                    clearErrors(`${name}.${PassengerDetailsFormFields.DATE_OF_BIRTH}`);
                    return true;
                }
                const age = travellerInfo?.minimumAge === PassengerDetailsFormFields.MINIMUM_ADULT_AGE_RESPONSE 
                ? travellerInfo?.minimumAge + 1 : travellerInfo?.minimumAge;
                
                setError(`${name}.${PassengerDetailsFormFields.DATE_OF_BIRTH}`, {
                    type: ValidationRulesEnum.INVALID_DATE,
                    message: contentfulCheckoutFormValidators && parseContentfulDynamicContent(contentfulCheckoutFormValidators[0]?.fields?.adultAge, age),
                });

                if (!isMinDateRangeAgeValid) {
                    setError(`${name}.${PassengerDetailsFormFields.DATE_OF_BIRTH}`, {
                        type: ValidationRulesEnum.MIN_REQUIRED_AGE,
                        message: parseContentfulDynamicContent(
                            contentfulCheckoutFormValidators &&
                            contentfulCheckoutFormValidators[0]?.fields?.maxRequiredAge,
                            reverseDateFormat(minDateAllowed)
                        ),
                    });
                    return parseContentfulDynamicContent(
                        contentfulCheckoutFormValidators[0]?.fields?.maxRequiredAge,
                        reverseDateFormat(minDateAllowed)
                    );
                }
            }
            if (
                travellerInfo?.travellerType === PassengerDetailsFormFields.CHILD &&
                (!isMaxDateRangeAgeValid || !isMinDateRangeAgeValid)
            ) {
                if (isDateValid && isMaxDateRangeAgeValid && isMinDateRangeAgeValid) {
                    clearErrors(`${name}.${PassengerDetailsFormFields.DATE_OF_BIRTH}`);
                    return true;
                }
                setError(`${name}.${PassengerDetailsFormFields.DATE_OF_BIRTH}`, {
                    type: ValidationRulesEnum.INVALID_DATE,
                    message: contentfulCheckoutFormValidators[0]?.fields?.childAge,
                });

                if (!isMinDateRangeAgeValid) {
                    setError(`${name}.${PassengerDetailsFormFields.DATE_OF_BIRTH}`, {
                        type: ValidationRulesEnum.MIN_REQUIRED_AGE,
                        message: parseContentfulDynamicContent(
                            contentfulCheckoutFormValidators[0]?.fields?.maxRequiredAge,
                            reverseDateFormat(minDateAllowed)
                        ),
                    });
                    return parseContentfulDynamicContent(
                        contentfulCheckoutFormValidators[0]?.fields?.maxRequiredAge,
                        reverseDateFormat(minDateAllowed)
                    );
                }
            }
            if (
                travellerInfo?.travellerType === PassengerDetailsFormFields.INFANT &&
                (!isMaxDateRangeAgeValid || !isMinDateRangeAgeValid)
            ) {
                setError(`${name}.${PassengerDetailsFormFields.DATE_OF_BIRTH}`, {
                    type: ValidationRulesEnum.INVALID_DATE,
                    message: contentfulCheckoutFormValidators[0]?.fields?.babyAge,
                });
                if (!isMinDateRangeAgeValid) {
                    setError(`${name}.${PassengerDetailsFormFields.DATE_OF_BIRTH}`, {
                        type: ValidationRulesEnum.MIN_REQUIRED_AGE,
                        message: parseContentfulDynamicContent(
                            contentfulCheckoutFormValidators[0]?.fields?.maxRequiredAge,
                            reverseDateFormat(minDateAllowed)
                        ),
                    });
                    return parseContentfulDynamicContent(
                        contentfulCheckoutFormValidators[0]?.fields?.maxRequiredAge,
                        reverseDateFormat(minDateAllowed)
                    );
                }
            }

            if (isMaxDateRangeAgeValid && isMinDateRangeAgeValid) {
                clearErrors(`${name}.${PassengerDetailsFormFields.DATE_OF_BIRTH}`);
            }
            return true;
        } else {
            setError(`${name}.${PassengerDetailsFormFields.DATE_OF_BIRTH}`, {
                type: ValidationRulesEnum.INVALID_DATE,
                message: contentfulCheckoutFormValidators[0]?.fields?.invalidDate,
            });
            return contentfulCheckoutFormValidators[0]?.fields?.invalidDate;
        }
    };

    const setHeaderText = () => {
        let output;

        const age = travellerInfo?.isDriver || 
        travellerInfo.minimumAge.toString() === PassengerDetailsFormFields.DEFAULT_ADULT_AGE_US
        ? travellerInfo?.minimumAge
        : PassengerDetailsFormFields.DEFAULT_ADULT_AGE;

        if (travellerInfo?.isMainBooker) {
            if (travellerInfo?.isDriver) {
                output = contentfulCheckoutPassengerDetails && parseContentfulDynamicContent(
                    contentfulCheckoutPassengerDetails[0]?.fields?.mainBookerAndDriverDataTitle,
                    index,
                    age
                )
            }
            else {
                output = contentfulCheckoutPassengerDetails && parseContentfulDynamicContent(
                    contentfulCheckoutPassengerDetails[0]?.fields?.mainBookerDataTitle,
                    index,
                    age
                )
            }
        }
        else {
            const age = travellerInfo?.minimumAge === PassengerDetailsFormFields.MINIMUM_ADULT_AGE_RESPONSE 
            ? travellerInfo?.minimumAge + 1 : travellerInfo?.minimumAge
            if (travellerInfo?.travellerType === PassengerDetailsFormFields.ADULT) {
                output = parseContentfulDynamicContent(
                    contentfulCheckoutPassengerDetails[0]?.fields?.passengerDataTitle,
                    index,
                    age
                );
            }
            else if (travellerInfo?.travellerType === PassengerDetailsFormFields.CHILD) {
                output = parseContentfulDynamicContent(
                    contentfulCheckoutPassengerDetails[0]?.fields?.childDataTitle,
                    index
                );
            }
            else if (travellerInfo?.travellerType === PassengerDetailsFormFields.INFANT) {
                output = parseContentfulDynamicContent(
                    contentfulCheckoutPassengerDetails[0]?.fields?.infantDataTitle,
                    index
                );
            }
            else {
                output = PassengerDetailsFormFields.DEFAULT_HEADER_TEXT;
            }
        }
        return output;
    };

    return (
        <div className="traveler-details-component">
            <div className="traveller-details-component-header">
                {headerText}
            </div>

            {travellerInfo.isMainBooker && (
                <div className="main-booker-heading-description">
                    <Alert
                        className="main-booker-information-description"
                        severity="info"
                    >
                        {contentfulCheckoutPassengerDetails &&
                            documentToReactComponents(
                                contentfulCheckoutPassengerDetails[0]?.fields
                                    .mainBookerInformationDescription
                            )}
                    </Alert>
                </div>
            )}

            <div>
                <div>
                    <GenderRadioBoxComponent
                        control={control}
                        clearErrors={clearErrors}
                        gender={gender}
                        travellerInfo={travellerInfo}
                        name={name}
                        index={index}
                        label={
                            contentfulCheckoutPassengerDetails &&
                            contentfulCheckoutPassengerDetails[0]?.fields?.personTitle
                        }
                        maleLabel={
                            contentfulCheckoutPassengerDetails &&
                            contentfulCheckoutPassengerDetails[0]?.fields?.male
                        }
                        femaleLabel={
                            contentfulCheckoutPassengerDetails &&
                            contentfulCheckoutPassengerDetails[0]?.fields?.female
                        }
                        register={register}
                        errors={errors}
                        contentfulCheckoutFormValidators={contentfulCheckoutFormValidators}
                    />
                </div>
            </div>

            <TravelerNameComponent
                control={control}
                name={name}
                travellerInfo={travellerInfo}
                register={register}
                contentfulCheckoutPassengerDetails={contentfulCheckoutPassengerDetails}
                contentfulCheckoutFormValidators={contentfulCheckoutFormValidators}
                errors={errors}
                clearErrors={clearErrors}
            />

            <DataInputComponent
                control={control}
                name={name}
                checkOutData={checkOutData}
                travellerInfo={travellerInfo}
                setValue={setValue}
                contentfulCheckoutPassengerDetails={contentfulCheckoutPassengerDetails}
                contentfulCheckoutFormValidators={contentfulCheckoutFormValidators}
                errors={errors}
                setDay={setDay}
                setMonth={setMonth}
                setYear={setYear}
                validations={[
                    {
                        rule: ValidationRulesEnum.INVALID_DATE,
                        message:
                            contentfulCheckoutFormValidators &&
                            contentfulCheckoutFormValidators[0]?.fields?.invalidDate,
                    },
                    {
                        rule: ValidationRulesEnum.MIN_REQUIRED_AGE,
                        message:
                            contentfulCheckoutFormValidators &&
                            contentfulCheckoutFormValidators[0]?.fields?.minRequiredAge,
                    },
                    {
                        rule: ValidationRulesEnum.MAX_REQUIRED_AGE,
                        message:
                            contentfulCheckoutFormValidators &&
                            contentfulCheckoutFormValidators[0]?.fields?.maxRequiredAge,
                    },
                ]}
                setDateOfBirthValues={setDateOfBirthValues}
            />

            <Controller
                name={`${name}.${PassengerDetailsFormFields.DATE_OF_BIRTH}`}
                control={control}
                defaultValue=""
                rules={{
                    validate: (value) => validateDates(value),
                }}
                render={({ field, fieldState }) => (
                    <div className="">
                        <div className="error-message-placeholder">
                            <span className="error error-message">
                                {fieldState?.error?.message}
                            </span>
                        </div>
                    </div>
                )}
            />

            <FrequentFlyerComponent
                frequentFlyerAirlineOptions={
                    checkOutData.travellerInfo.frequentFlyerAirlineOptions
                }
                travellerInfo={travellerInfo}
                name={name}
                setValue={setValue}
                unregister={unregister}
                register={register}
                control={control}
                errors={errors}
                contentfulCheckoutPassengerDetails={contentfulCheckoutPassengerDetails}
                contentfulCheckoutFormValidators={contentfulCheckoutFormValidators}
            />

            {travellerInfo?.isMainBooker && (
                <MainBookerComponent
                    contactDetailInfo={checkOutData.contactDetailInfo}
                    name={name}
                    register={register}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    contentfulCheckoutPassengerDetails={
                        contentfulCheckoutPassengerDetails
                    }
                    contentfulCheckoutFormValidators={contentfulCheckoutFormValidators}
                />
            )}
        </div>
    );
};
