export const insurance = {
    action: {
        selectInsuranceOption: "selectInsuranceOption",
        approveInsuranceTerms: "approveInsuranceTerms"
    },
    type: {
        selectCancellationInsurance: "selectCancellationInsurance",
        selectTravelInsurance: "selectTravelInsurance",
        selectCombinationInsurance: "selectCombinationInsurance",
        selectedInsurance: "initialLoadingState",
        selectNoInsurance: "selectNoInsurance",
        selectRemoveInsurance: "selectRemoveInsurance",
    }
};