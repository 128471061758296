import { ReactComponent as PassengerIcon } from "../../assets/icons/passengers-S.klm.svg";
import { ReactComponent as BaggageComponent } from "../../assets/icons/bagage-S.svg";
import { ReactComponent as CarIcon } from "../../assets/icons/klm/car-door.svg";

// Features
import { ReactComponent as ManualTransmission } from "../../assets/icons/klm/ic_car_shift_pattern.svg";
import { ReactComponent as AutomaticTransmission } from "../../assets/icons/klm/ic_car_shift_pattern.svg";
import { ReactComponent as AirConditioning } from "../../assets/icons/klm/ic_ac_unit.svg";
import { ReactComponent as NavigationSystem } from "../../assets/icons/klm/ic_gps_fixed.svg";
import { ReactComponent as ElectricCar } from "../../assets/icons/klm/ic_car_electric.svg";
import { ReactComponent as GuaranteedModel } from "../../assets/icons/klm/ic_car_select.svg";
import { ReactComponent as Diesel } from "../../assets/icons/klm/ic_fuel.svg";
import { ReactComponent as FourWheelDrive } from "../../assets/icons/klm/ic_fuel_copy.svg";

// Services
import { ReactComponent as ExtraDriver } from "../../assets/icons/klm/ic_account_plus.svg";
import { ReactComponent as NoDeposit } from "../../assets/icons/klm/KLM_icon_creditcard.svg";
import { ReactComponent as FastLine } from "../../assets/icons/klm/ic_human_queue.svg";
import { ReactComponent as FullPaid } from "../../assets/icons/klm/ic_gas_station_copy.svg";
import { ReactComponent as GPS } from "../../assets/icons/klm/ic_gps_fixed.svg";

// Check-in and drop-off
import { ReactComponent as DeskAtAirport } from "../../assets/icons/klm/Icons_Airport.svg";

import { ReactComponent as CreditCardKLM } from "../../assets/icons/klm/KLM_icon_creditcard.svg";
import { ReactComponent as CreditCardTransavia } from "../../assets/icons/transavia/transavia_icon_creditcard.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/klm/check-round-S.svg";

import "./car-item.component.scss";
import { useEffect, useState } from "react";
import { GetAffiliate } from "../../services/general.service";
import { affiliates } from "../../common/enums/affiliates";
import { GenericButton } from "../generic-button/generic-button";
import { CircularProgress } from "@mui/material";

const FeaturesIcons = {
  Manual: <ManualTransmission />,
  AutomaticTransmission: <AutomaticTransmission />,
  AirConditioning: <AirConditioning />,
  NavigationSystem: <NavigationSystem />,
  ElectricCar: <ElectricCar />,
  GuaranteedModel: <GuaranteedModel />,
  Diesel: <Diesel />,
  FourWheelDrive: <FourWheelDrive />,
};

const ServicesIcons = {
  AD: <ExtraDriver />,
  ND:
    GetAffiliate() === affiliates.klm ? (
      <CreditCardKLM />
    ) : (
      <CreditCardTransavia />
    ),
  FL: <FastLine />,
  FP: <FullPaid />,
  GP: <GPS />,
};

export const CarItemComponent = ({
  rentalCarInfo,
  contentfulCarComponent,
  showCustomModal,
  handleViewCarConditions,
  selectCar,
  deleteCar,
  isWaitingForNetwork,
}) => {
  const [isCarSelected, setIsCarSelected] = useState(null);
  const [isCarPreSelected, setIsPreCarSelected] = useState(null);

  useEffect(() => {
    setIsPreCarSelected(false);
    setIsCarSelected(rentalCarInfo.selected);
  }, [rentalCarInfo]);

  const renderCarConditionContent = (carConditionContent) => {
    return (
      <div className="car-condition-modal-body">
        {carConditionContent.map((condition) => (
          <div className="condition-section">
            <div className="condition-section-title">{condition.title}</div>
            <ul>
              {condition.textLines.map((textLine) => (
                <li>{textLine}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };

  const handleCarConditions = async (carConditionContent) => {
    showCustomModal(
      contentfulCarComponent &&
        contentfulCarComponent[0].fields?.carViewCondtionsTitle,
      renderCarConditionContent(carConditionContent)
    );
  };

  const onViewCarConditions = async () => {
    const carCondtions = await handleViewCarConditions(rentalCarInfo.optionKey);
    handleCarConditions(carCondtions.data.conditions);
  };

  const handleCarToDelete = async (optionKey) => {
    if (!isWaitingForNetwork) {
      setIsPreCarSelected(true);
      await deleteCar(optionKey);
    }
  };

  const handleCarSelection = (optionKey) => {
    if (!isWaitingForNetwork) {
      setIsPreCarSelected(true);
      selectCar(optionKey);
    }
  };

  return (
    <div
      className={`car-item-component ${isCarSelected ? "car-item-selected" : ""}
      ${isWaitingForNetwork && !isCarPreSelected ? "car-item-opacity-50" : ""}`}
    >
      <div className="car-item-main-container">
        <div
          className={`car-image-details-wrapper ${
            isCarSelected ? "car-is-selected" : ""
          }`}
        >
          <div className="car-item-image">
            <img
              src={rentalCarInfo && rentalCarInfo?.image}
              alt={rentalCarInfo && rentalCarInfo?.description}
            />
            <div className="car-general-information">
              <div className="number-of-passengers">
                <PassengerIcon />
                {rentalCarInfo?.numberOfTravellers}
              </div>
              <div className="number-of-baggage">
                <BaggageComponent />
                {rentalCarInfo?.numberOfBags}
              </div>
              <div className="number-of-doors">
                <CarIcon />
                {rentalCarInfo?.numberOfDoors}
              </div>
            </div>
          </div>

          <div className="car-item-details">
            <div className="car-item-title">
              <div className="car-model">
                {rentalCarInfo && rentalCarInfo?.description}&nbsp;
                {contentfulCarComponent &&
                  contentfulCarComponent[0].fields?.equivalent}
              </div>
              <div className="car-model-description">
                {rentalCarInfo && rentalCarInfo?.carType}
              </div>
            </div>
            <div className="section-row">
              {/*Features*/}
              <div className="characteristics-section">
                <div className="car-section-title">
                  {contentfulCarComponent &&
                    contentfulCarComponent[0].fields?.carCharacteristics}
                </div>
                {rentalCarInfo?.features.map((feature) => (
                  <div key={feature.code} className="characteristics-item">
                    {FeaturesIcons[feature.code]}
                    {feature.displayName}
                  </div>
                ))}
              </div>

              {/*Services*/}
              {rentalCarInfo.services.length > 0 && (
                <div className="services-section">
                  <div>
                    {contentfulCarComponent &&
                      contentfulCarComponent[0].fields?.carServices}
                  </div>

                  {rentalCarInfo?.services.map((service) => (
                    <div key={service.displayName} className="service-item">
                      {ServicesIcons[service.code]}
                      {service?.displayName}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="section-row">
              {rentalCarInfo?.pickUpDropOffInfo?.pickUpAtAirport && (
                <div className="pick-up-section">
                  <div>
                    {contentfulCarComponent &&
                      contentfulCarComponent[0].fields?.carCheckIn}
                  </div>
                  <div className="pick-up-item">
                    <DeskAtAirport />
                    {rentalCarInfo?.pickUpDropOffInfo?.pickUpLocation}
                  </div>
                </div>
              )}
              {rentalCarInfo?.pickUpDropOffInfo?.dropOffAtAirport && (
                <div className="drop-off-section">
                  <div>
                    {contentfulCarComponent &&
                      contentfulCarComponent[0].fields?.carDropOff}
                  </div>
                  <div className="drop-off-item">
                    <DeskAtAirport />
                    {rentalCarInfo?.pickUpDropOffInfo?.dropOffLocation}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="car-item-action">
          <div
            className={`additional-cost-container ${
              isCarSelected ? "additional-cost-container-selected" : ""
            }`}
          >
            <div className="card-container">
              <div className="card-container-wrapper">
                <div>
                  {GetAffiliate() === affiliates.klm ? (
                    <CreditCardKLM />
                  ) : (
                    <CreditCardTransavia />
                  )}
                </div>

                <div className="disclaimers">
                  <span>
                    {contentfulCarComponent &&
                      contentfulCarComponent[0].fields?.carCreditCardHolder}
                  </span>
                  <div
                    className="view-all-conditons"
                    onClick={onViewCarConditions}
                  >
                    {contentfulCarComponent &&
                      contentfulCarComponent[0].fields?.carViewAllConditions}
                  </div>
                </div>
              </div>
            </div>
            {!isCarSelected && (
              <div className="select-car-container">
                <div className="car-price-block">
                  <span className="price">{`+ € ${rentalCarInfo?.price} `}</span>
                  <span className="total-description">
                    {contentfulCarComponent &&
                      contentfulCarComponent[0].fields?.carTotal}
                  </span>
                </div>
                <GenericButton
                  type="button"
                  className={`car-select-button ${
                    isCarPreSelected ? "car-select-button-disabled" : ""
                  }`}
                  disabledState={isCarPreSelected || isWaitingForNetwork}
                  onClick={() => handleCarSelection(rentalCarInfo.optionKey)}
                >
                  {contentfulCarComponent &&
                  isWaitingForNetwork &&
                  isCarPreSelected ? (
                    <div className="spinner-button-wrapper">
                      <div className="spinner-button">
                        <CircularProgress
                          className="button-loading-spinner"
                          size={6}
                        />
                        <div className="loading-text">
                          {contentfulCarComponent &&
                            contentfulCarComponent[0].fields?.loadingButton}
                        </div>
                      </div>
                    </div>
                  ) : (
                    contentfulCarComponent &&
                    contentfulCarComponent[0].fields?.carSelectButton
                  )}
                </GenericButton>
              </div>
            )}
            {isCarSelected && (
              <div className="car-container-selected">
                <div className="car-price-block">
                  <span className="selected">
                    <CheckIcon />
                    <div className="car-selected">
                      {contentfulCarComponent &&
                        contentfulCarComponent[0].fields?.carSelected}
                    </div>
                  </span>
                </div>
                <div
                  className="car-delete"
                  onClick={() => handleCarToDelete(rentalCarInfo.optionKey)}
                >
                  {isWaitingForNetwork && isCarPreSelected && (
                    <div className="spinner-button-wrapper">
                      <div className="spinner-button">
                        <CircularProgress
                          className="button-loading-spinner"
                          size={8}
                        />
                      </div>
                    </div>
                  )}
                  {contentfulCarComponent &&
                    contentfulCarComponent[0].fields?.carToDelete}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
