import { useSelector } from "react-redux";
import { ReactComponent as AutoIcon } from "../../assets/icons/klm/car-S.svg";

export const AutoComponent = ({
  contentfulHolidayPageSummary,
}) => {
  const checkOutDataCart = useSelector((state) => state.checkOut.checkOutData);
  
  return (
    <div className="car-component">
      <div className="section-header">
        <AutoIcon />
        {contentfulHolidayPageSummary &&
          contentfulHolidayPageSummary[0]?.fields?.holidayPageSummaryExtension?.auto}
      </div>
      <div className="small-space">
        {checkOutDataCart && contentfulHolidayPageSummary && (
          <span>
            {`${checkOutDataCart?.shoppingCart?.rentalCarInfo?.description}`}
          </span>
        )}
      </div>
    </div>
  );
};
