import "./input-component.scss";
import React, { useEffect, useState, forwardRef } from "react";
import { PassengerDetailsFormFields } from "../../common/constants/passengerDetailsFormFields";

export const InputComponent = forwardRef(
  (
    {
      name,
      label,
      type,
      placeholder,
      isError,
      maxLength,
      value,
      onChange,
      setDateOfBirthValues,
      onMaxLengthReached,
      inputMode,
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
      if (name.includes(PassengerDetailsFormFields.DAY_OF_BIRTH)) {
        setDateOfBirthValues({ day: inputValue });
      } else if (name.includes(PassengerDetailsFormFields.MONTH_OF_BIRTH)) {
        setDateOfBirthValues({ month: inputValue });
      } else if (name.includes(PassengerDetailsFormFields.YEAR_OF_BIRTH)) {
        setDateOfBirthValues({ year: inputValue });
      }
    }, [inputValue, name, setDateOfBirthValues]);

    const handleChange = (e) => {
      const newValue = e.target.value;
      setInputValue(newValue);
      onChange(e);

      if (newValue.length === maxLength) {
        onMaxLengthReached && onMaxLengthReached();
      }
    };

    return (
      <div className="input-component">
        <label className="label-description">
          <span className={isError ? "error" : ""}>{label}</span>
          <input
            ref={ref}
            className={isError ? "error-input" : ""}
            type={type}
            maxLength={maxLength}
            placeholder={placeholder}
            value={inputValue}
            onChange={handleChange}
            inputMode={inputMode ?? "text"}
          />
        </label>
      </div>
    );
  }
);
