import Rating from "@mui/material/Rating";
import { styled } from "@mui/system";
import { GetAffiliate } from "../../../services/general.service";
import { affiliates } from "../../../common/enums/affiliates";
import StarIconKLM from "../../../assets/icons/klm/klm-star-summary.svg";
import StarIconTransavia from "../../../assets/icons/transavia/star-summary-transavia.svg";
import { useEffect, useState } from "react";

const StyledIconContainer = styled("span")({
  padding: "0 3px",
  marginTop: "10px",
  marginBottom: "10px",
});

export const HotelStarRatingComponent = ({starRating}) => {
  const [starRatingData, setStarRatingData] = useState(starRating);
  const isKlm = GetAffiliate() === affiliates.klm;
  
  useEffect(() => {
    setStarRatingData(starRating)
  }, [starRating])
  
  return (
    starRating && <div className="hotel-star-rating-component">
        <Rating
          className="star-rating"
          readOnly
          value={starRatingData ?? ""}
          precision={0.5}
          size="small"
          icon={<img src={isKlm ? StarIconKLM : StarIconTransavia} alt="Star Icon" />}
          IconContainerComponent={StyledIconContainer}
        />
    </div>
  );
};
