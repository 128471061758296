// This service is here to help us
// When you set your .env variable REACT_APP_DEV_HELPER is set to TRUE
// Then these tools can appear
// Then, of course, we don't use these on any environment but our own local pc

import axios from "axios";
import { GetApiUrl } from "./general.service";

// Feel free to add to these if you see a way we can save time

// This one is used to pre-populate the Passenger Details Page
// You're welcome :P

export const devHelperDataTypes = {
  passengerDetails: "passengerDetails",
};

export const ShowDevHelperButtons = () => {
  return process.env?.REACT_APP_DEV_HELPER;
};

// Make sure to always use the words DevHelper somewhere in the name
// So we don't confuse it with real methods

export const SavePassengerDetailsDataForDevHelper = (dataToSave) => {
  localStorage.setItem(
    devHelperDataTypes.passengerDetails,
    JSON.stringify(dataToSave)
  );
};

export const LoadPassengerDetailsDataFromDevHelper = () => {
  return JSON.parse(localStorage.getItem(devHelperDataTypes.passengerDetails));
};

export const DevHelperHasPassengerDetailsData = () => {
  return localStorage.getItem(devHelperDataTypes.passengerDetails) !== null;
};

export const DevHelperUpdateDetails = async (devHelperData, checkoutKey) => {
  const response = await axios.put(
    `${GetApiUrl()}/CheckOut/${checkoutKey}/TravellerAndContactUpdate`,
    devHelperData
  );
  return response;
};

export const DevHelperRemapPassengerData = async (checkoutData) => {
  // Will only work if you test with the same number of passengers as when you saved last time
  let passengerHelperData = await LoadPassengerDetailsDataFromDevHelper();
  const travellers = checkoutData.travellerInfo.travellers;
  passengerHelperData.travellers = mockPassengers(checkoutData);
  let remappedTravellers = [];
  for (let i = 0; i < travellers.length; i++) {
    let newTraveller = { ...passengerHelperData.travellers[i] };
    newTraveller.identifier = travellers[i].travellerIdentifier;
    remappedTravellers.push(newTraveller);
  }
  passengerHelperData.travellers = remappedTravellers;
  return passengerHelperData;
};

const mockPassengers = (identifier) => {
  return [
    {
      identifier: identifier,
      firstName: "Joe",
      lastName: "Blogs",
      gender: 0,
      dateOfBirth: "1990-01-01",
      frequentFlyer: {},
    },
    {
      identifier: identifier,
      firstName: "Josephine",
      lastName: "Blogs",
      gender: 0,
      dateOfBirth: "1990-02-02",
      frequentFlyer: {},
    },
  ];
};
