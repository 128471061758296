import React from "react";
import { useSelector } from "react-redux";
import ErrorIcon from "../../../assets/icons/transavia/error-trip-message.svg";

const CabinErrorComponent = ({ luggageData, isError }) => {
  return (
    <React.Fragment>
      {isError && (
        <div className="cabin-error-container">
          <img src={ErrorIcon} alt="error message" />
          <p>{luggageData && luggageData[0]?.fields?.noSelectedErrorMessage}</p>
        </div>
      )}
    </React.Fragment>
  );
};

export default CabinErrorComponent;
