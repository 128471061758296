import './pages-stepper-bar.component.scss'
import React, {useContext, useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {GetAffiliate} from "../../services/general.service";
import {affiliates} from "../../common/enums/affiliates";
import {Stepper, Step, StepConnector} from '@mui/material';
import {ReactComponent as CarIconKLM} from "../../assets/icons/klm/car-S.svg";
import {ReactComponent as CarIconTransavia} from "../../assets/icons/transavia/icon-car.svg";
import {ReactComponent as PassengersIconKLM} from "../../assets/icons/klm/passengers-S.svg";
import {ReactComponent as PassengersIconTransavia} from "../../assets/icons/transavia/Avatar.svg";
import {ReactComponent as PaymentIconKLM} from "../../assets/icons/klm/KLM_icon_creditcard.svg";
import {ReactComponent as PaymentIconTransavia} from "../../assets/icons/transavia/transavia_icon_creditcard.svg";
import {ReactComponent as ConfirmationIconKLM} from "../../assets/icons/klm/check-sl.svg";
import {ReactComponent as ConfirmationIconTransavia} from "../../assets/icons/transavia/Check.svg";
import {makeStyles} from "@mui/styles";
import ContentfulContext from "../../store/contentful/contentful-context";
import {getContentfulByContentType} from "../../services/contentful.service";
import {contentType} from "../../common/constants/contentType";
import {MAIN_ROUTE} from "../../common/constants/pages";

const stepsDictionary = {
    extras: 0,
    checkout: 1,
    payment: 2,
    confirmation: 3,
}

export const PagesStepperBarComponent = () => {
    const [activeStep, setActiveStep] = useState(0);
    const route = useLocation().pathname.substring(1);
    const affiliate = GetAffiliate();
    const isKLM = affiliate === affiliates.klm;
    const [generalPageContent, setGeneralPageContent] = useState();
    const contentfulEntries = useContext(ContentfulContext);
    const getGeneralPageContent = () => {
        const contentfulResultsPageResponse = getContentfulByContentType(
            contentfulEntries,
            contentType.GENERAL
        );
        setGeneralPageContent(contentfulResultsPageResponse);
    };

    useEffect(() => {
        getGeneralPageContent();
    }, [contentfulEntries]);

    const getActiveStep = () => {
        return Object.entries(stepsDictionary)
            .findIndex(([key, value], i) => `${key}/`.startsWith(route))
    }

    useEffect(() => {
        setActiveStep(getActiveStep());
    }, [])

    const useStyles = makeStyles((theme) => (
        isKLM ? {
                customConnector: {
                    '& .MuiStepConnector-line': {
                        margin: '0 4px',
                        background: '#B3D6E5',
                    },
                    '&.Mui-active .MuiStepConnector-line': {
                        background: '#00A1DE',
                    },
                    '&.Mui-completed .MuiStepConnector-line': {
                        background: '#003F72',
                    },
                },
            }
            : {
                customConnector: {
                    '& .MuiStepConnector-line': {
                        margin: '0 4px',
                        height: "5px",
                        background: '#BCBCBC',
                    },
                    '&.Mui-active .MuiStepConnector-line': {
                        background: '#2800A0',
                    },
                    '&.Mui-completed .MuiStepConnector-line': {
                        background: '#120145',
                    },
                },
            }
    ));

    const CustomStepConnector = ({active, completed}) => {
        const classes = useStyles();
        return <StepConnector className={classes.customConnector}/>;
    };

    return (
        <div className="pages-stepper-bar-wrapper">
        <div className="pages-stepper-bar-component">
            <Stepper
                activeStep={activeStep}
                connector={<CustomStepConnector active={activeStep}/>}
                className="pages-stepper-bar-container">

                <Step
                    active={activeStep === stepsDictionary.extras}
                    completed={activeStep > stepsDictionary.extras}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    {
                        isKLM ? <CarIconKLM
                                className={`pages-stepper-bar-icon 
                                    ${activeStep === stepsDictionary.extras ? 'pages-stepper-bar-active' : ''}
                                    ${activeStep > stepsDictionary.extras ? 'pages-stepper-bar-done' : ''}`
                                }
                            /> :
                            <CarIconTransavia
                                className={`pages-stepper-bar-icon 
                                            ${activeStep === stepsDictionary.extras ? 'pages-stepper-bar-active' : ''}
                                            ${activeStep < stepsDictionary.extras ? 'pages-stepper-bar-to-do' : ''}
                                            ${activeStep > stepsDictionary.extras ? 'pages-stepper-bar-done' : ''}`}
                            />
                    }
                    <div className={
                        `step-description 
                            ${activeStep === stepsDictionary.extras ? 'active' : ''} 
                            ${activeStep > stepsDictionary.extras ? 'done' : ''}
                            ${activeStep < stepsDictionary.extras ? 'to-do' : ''}`}>
                        {
                            generalPageContent && generalPageContent[0]?.fields?.stepperBarExtras
                        }</div>
                </Step>
                
                <Step
                    active={activeStep === stepsDictionary.checkout}
                    completed={activeStep > stepsDictionary.extras}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    {
                        isKLM ? <PassengersIconKLM
                                className={`pages-stepper-bar-icon 
                                ${activeStep === stepsDictionary.checkout ? 'pages-stepper-bar-active' : ''}
                                ${activeStep < stepsDictionary.checkout ? 'pages-stepper-bar-to-do' : ''}
                                ${activeStep > stepsDictionary.checkout ? 'pages-stepper-bar-done' : ''}`}
                            /> :
                            <PassengersIconTransavia
                                className={`pages-stepper-bar-icon 
                                ${activeStep === stepsDictionary.checkout ? 'pages-stepper-bar-active' : ''}
                                ${activeStep < stepsDictionary.checkout ? 'pages-stepper-bar-to-do' : ''}
                                ${activeStep > stepsDictionary.checkout ? 'pages-stepper-bar-done' : ''}`}
                            />
                    }
                    <div className={
                        `step-description 
                            ${activeStep === stepsDictionary.checkout ? 'active' : ''} 
                            ${activeStep > stepsDictionary.checkout ? 'done' : ''}
                            ${activeStep < stepsDictionary.checkout ? 'to-do' : ''}`}>
                        {generalPageContent && generalPageContent[0]?.fields?.stepperBarCheckout}
                    </div>
                </Step>
                <Step
                    active={activeStep === stepsDictionary.payment}
                    completed={activeStep > stepsDictionary.checkout}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    {
                        isKLM ? <PaymentIconKLM
                                className={`pages-stepper-bar-icon 
                                ${activeStep === stepsDictionary.payment ? 'pages-stepper-bar-active' : ''}
                                ${activeStep < stepsDictionary.payment ? 'pages-stepper-bar-to-do' : ''}
                                ${activeStep > stepsDictionary.payment ? 'pages-stepper-bar-done' : ''}`}
                            /> :
                            <PaymentIconTransavia
                                className={`pages-stepper-bar-icon 
                                ${activeStep === stepsDictionary.payment ? 'pages-stepper-bar-active' : ''}
                                ${activeStep < stepsDictionary.payment ? 'pages-stepper-bar-to-do' : ''}
                                ${activeStep > stepsDictionary.payment ? 'pages-stepper-bar-done' : ''}`}
                            />
                    }
                    <div className={
                        `step-description 
                            ${activeStep === stepsDictionary.payment ? 'active' : ''} 
                            ${activeStep > stepsDictionary.payment ? 'done' : ''}
                            ${activeStep < stepsDictionary.payment ? 'to-do' : ''}`}>
                        {generalPageContent && generalPageContent[0]?.fields?.stepperBarPayment}
                    </div>
                </Step>
                <Step
                    active={activeStep === stepsDictionary.payment}
                    completed={activeStep > stepsDictionary.payment}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    {
                        isKLM ? <ConfirmationIconKLM
                                className={`pages-stepper-bar-icon 
                                        ${activeStep === stepsDictionary.confirmation ? 'pages-stepper-bar-active' : ''}
                                        ${activeStep < stepsDictionary.confirmation ? 'pages-stepper-bar-to-do' : ''}
                                        ${activeStep > stepsDictionary.confirmation ? 'pages-stepper-bar-done' : ''}`}
                            /> :
                            <ConfirmationIconTransavia
                                className={`pages-stepper-bar-icon 
                                        ${activeStep === stepsDictionary.confirmation ? 'pages-stepper-bar-active' : ''}
                                        ${activeStep < stepsDictionary.confirmation ? 'pages-stepper-bar-to-do' : ''}
                                        ${activeStep > stepsDictionary.confirmation ? 'pages-stepper-bar-done' : ''}`}
                            />
                    }
                    <div className={
                        `step-description 
                            ${activeStep === stepsDictionary.confirmation ? 'active' : ''} 
                            ${activeStep > stepsDictionary.confirmation ? 'done' : ''}
                            ${activeStep < stepsDictionary.confirmation ? 'to-do' : ''}`}>
                        {generalPageContent && generalPageContent[0]?.fields?.stepperBarConfirmation}
                    </div>
                </Step>
            </Stepper>
        </div>
        </div>
    );
}
