import './country-input.component.scss'
import {Controller} from "react-hook-form";
import {PassengerDetailsFormFields} from "../../../../../common/constants/passengerDetailsFormFields";
import React from "react";
import {FormInputDropdownComponent} from "../../../../../components/form-input-dropdown/form-input-dropdown.component";

export const CountryInputComponent = ({
                                          countryOptions,
                                          preferredCountry,
                                          name,
                                          control,
                                          errors,
                                          contentfulCheckoutPassengerDetails,
                                          contentfulCheckoutFormValidators
                                      }) => {
    const {country: countryError} = errors[name] ?? '';

    return <label className="country-label-component">
        <Controller
            name={`${name}.${PassengerDetailsFormFields.COUNTRY_DATA}`}
            control={control}
            defaultValue={preferredCountry}
            render={({field, fieldState}) => (
                <div className="country-label-input">
                    <FormInputDropdownComponent
                        name={`${name}.${PassengerDetailsFormFields.COUNTRY_DATA}`}
                        label={contentfulCheckoutPassengerDetails && contentfulCheckoutPassengerDetails[0]?.fields?.country}
                        defaultValue={preferredCountry}
                        value={field.value}
                        onChange={(value) => field.onChange(value)}
                        isError={fieldState.error}
                        inputOptions={[]}
                        inputOptionObjects={countryOptions}
                        preselectedValue={preferredCountry}
                    />
                </div>
            )}
        />
        {countryError &&
        <div className="error-message-placeholder">
                <span className="error error-message">
                {countryError?.message}
            </span>
        </div>
        }
    </label>
}
