import React from "react";
import FlightArrival from "../../../assets/icons/klm/flight-arrivals.svg";
import FlightDeparts from "../../../assets/icons/klm/flight-departs.svg";
import { ReactComponent as VerticalDirection } from "../../../assets/icons/klm/flight-vertical-direct.svg";
import { ReactComponent as VerticalDirectionMany } from "../../../assets/icons/klm/flight-vertical-direction-many.svg";
import { ReactComponent as VerticalDirectionStop } from "../../../assets/icons/klm/flight-vertical-direction-one.svg";
import { ReactComponent as VerticalDirectionTransavia } from "../../../assets/icons/transavia/flight-vertical-direct.svg";
import { ReactComponent as VerticalDirectionManyTransavia } from "../../../assets/icons/transavia/flight-vertical-direction-many.svg";
import { ReactComponent as VerticalDirectionStopTransavia } from "../../../assets/icons/transavia/flight-vertical-direction-one.svg";
import FlightBack from "../../../assets/icons/transavia/plane-back.svg";
import FlightStart from "../../../assets/icons/transavia/plane-start.svg";
import { affiliates } from "../../../common/enums/affiliates";
import {
  GetAffiliate,
  GetLogoPath
} from "../../../services/general.service";
import "./holiday-summary-flight-overview.component.scss";

export const HolidaySummaryFlightOverviewComponent = ({
  contentfulHolidayPageSummary,
  contentfulHolidayPage,
  detailedHoliday,
}) => {
  const {
    fields: { flights, departures, directFlight, arrivals, ruimbagage } = {},
  } = contentfulHolidayPageSummary ? contentfulHolidayPageSummary[0] : {};

  const { fields: { stop } = {} } = contentfulHolidayPage
    ? contentfulHolidayPage[0]
    : {};

  const affiliate = GetAffiliate();
  const flightInfo = detailedHoliday?.holidayDetailInfo?.flightInfo;

  const oneTransfer =
    contentfulHolidayPage &&
    contentfulHolidayPage[0]?.fields?.flight.flightType.oneTransfer;

  const multipleTransfers =
    contentfulHolidayPage &&
    contentfulHolidayPage[0]?.fields?.flight.flightType.multipleTransfers;

  const formatFlightTime = (hours, minutes) => {
    return (
      <p className="flight-overview-container-box-content-direct-hours">
        {hours}h{minutes}m
      </p>
    );
  };

  const flightLegsImage = (flightDirection) => {
    const transfers =
      flightDirection === "outBound"
        ? flightInfo?.outBound?.numberOfTransfers
        : flightInfo?.inBound?.numberOfTransfers;

    const affiliate = GetAffiliate();

    switch (affiliate) {
      case affiliates.klm:
        if (transfers === 1) {
          return <VerticalDirectionStop />;
        } else if (transfers >= 2) {
          return <VerticalDirectionMany />;
        }
        return <VerticalDirection />;
      case affiliates.transavia:
        if (transfers === 1) {
          return <VerticalDirectionStopTransavia />;
        } else if (transfers >= 2) {
          return <VerticalDirectionManyTransavia />;
        }
        return <VerticalDirectionTransavia />;
      default:
        return null;
    }
  };

  const legDetailsTextOutBound = () => {
    const outBoundStops = flightInfo?.outBound?.numberOStops;
    const outBoundTransfers = flightInfo?.outBound?.numberOfTransfers;

    if (outBoundStops > 0) {
      return (
        <p>
          {`${outBoundStops} ${
            outBoundStops > 1 ? `${stop}s` : stop
          }`.toLowerCase()}
        </p>
      );
    }

    if (outBoundTransfers > 0) {
      const transferText =
        outBoundTransfers > 1 ? multipleTransfers : oneTransfer;
      return <p>{`${outBoundTransfers} ${transferText}`.toLowerCase()}</p>;
    }

    if (
      outBoundStops === 0 &&
      outBoundTransfers === 0 &&
      flightInfo?.outBound?.directFlight
    ) {
      return <p>{directFlight.toLowerCase()}</p>;
    }

    return <></>;
  };

  const legDetailsTextInBound = () => {
    const inBoundStops = flightInfo?.inBound?.numberOStops;
    const inBoundTransfers = flightInfo?.inBound?.numberOfTransfers;

    if (inBoundStops > 0) {
      return (
        <p>
          {`${inBoundStops} ${
            inBoundStops > 1 ? `${stop}s` : stop
          }`.toLowerCase()}
        </p>
      );
    }

    if (inBoundTransfers > 0) {
      const transferText =
        inBoundTransfers > 1 ? multipleTransfers : oneTransfer;
      return <p>{`${inBoundTransfers} ${transferText}`.toLowerCase()}</p>;
    }

    if (
      inBoundStops === 0 &&
      inBoundTransfers === 0 &&
      flightInfo?.inBound?.directFlight
    ) {
      return <p>{directFlight.toLowerCase()}</p>;
    }

    return <></>;
  };

  return (
    <div className="holiday-summary-flight-overview-component">
      <p className="holiday-summary-flight-overview-component-title">
        {flights}
      </p>
      <div className="flight-overview-container">
        <div className="flight-overview-container-box-left">
          <div className="flight-overview-container-box-header">
            {affiliate === affiliates.klm ? (
              <div className="icon-container">
                <img src={FlightDeparts} alt="flight-departs" />
              </div>
            ) : affiliate === affiliates.transavia ? (
              <div className="icon-container">
                <img src={FlightStart} alt="flight-start" />
              </div>
            ) : null}
            {affiliate === affiliates.klm ? (
              <p>{departures}</p>
            ) : (
              <p>
                {departures}{" "}
                {
                  detailedHoliday?.holidayDetailInfo.flightInfo.outBound
                    .airlineName
                }
              </p>
            )}
          </div>

          <div className="flight-overview-container-box-content">
            {flightLegsImage("outBound")}
            <div className="flight-overview-container-box-content-hours">
              <p className="flight-overview-container-box-content-hours-start">
                {flightInfo?.outBound.departureTime}
                <span className="flight-overview-container-box-content-date-start">
                  {flightInfo?.outBound?.departureDateShort}
                </span>
              </p>

              {formatFlightTime(
                detailedHoliday.holidayDetailInfo.flightInfo.outBound
                  .durationHours,
                detailedHoliday.holidayDetailInfo.flightInfo.outBound
                  .durationMinutes
              )}
              <p className="flight-overview-container-box-content-hours-end">
                {flightInfo?.outBound.arrivalTime}
                <span className="flight-overview-container-box-content-date-end">
                  {flightInfo?.outBound?.arrivalDateShort}
                </span>
              </p>
            </div>
            <div className="flight-overview-container-box-content-direction">
              <p className="flight-overview-container-box-content-direction-start">
                {flightInfo?.outBound.departureAirport}
              </p>
              {legDetailsTextOutBound()}
              <p className="flight-overview-container-box-content-direction-end">
                {flightInfo?.outBound.arrivalAiport}
              </p>
            </div>
            {affiliate === affiliates.klm && (
              <img
                src={GetLogoPath(flightInfo?.outBound.airlineCode)}
                className="flight-overview-container-box-content-image"
                alt="Company Logo"
              />
            )}
          </div>
          <div className="flight-overview-container-box-baggage-info">
            <div className="flight-overview-container-box-baggage-info-handbagage hand-luggage">
              <div
                className={
                  flightInfo?.outBound?.showCabinLuggageLabel
                    ? "hand-luggage-excluded"
                    : "hand-luggage-included"
                }
              ></div>
              <p>
                {flightInfo?.outBound?.showCabinLuggageLabel ? (
                  <>
                    <span>{`${contentfulHolidayPage[0]?.fields?.flight?.luggage?.excluded} `}</span>
                    {
                      contentfulHolidayPage[0]?.fields?.flight?.luggage
                        ?.cabinLuggageSummary
                    }
                  </>
                ) : (
                  <>
                    <span>{`${contentfulHolidayPage[0]?.fields?.flight?.luggage?.included} `}</span>
                    {
                      contentfulHolidayPage[0]?.fields?.flight?.luggage
                        ?.handLuggage
                    }
                  </>
                )}
              </p>
            </div>
            <div className="flight-overview-container-box-baggage-info-briefcase">
              <div className={`baggage`}>
                <div className={`baggage-excluded`}></div>
              </div>
              <p>
                <span>
                  {contentfulHolidayPage[0]?.fields?.flight?.luggage?.excluded}
                </span>
                {ruimbagage}
              </p>
            </div>
          </div>
        </div>

        <div className="flight-overview-container-box-right">
          <div className="flight-overview-container-box-header">
            {affiliate === affiliates.klm ? (
              <div className="icon-container">
                <img src={FlightArrival} alt="flight-arrival" />
              </div>
            ) : affiliate === affiliates.transavia ? (
              <div className="icon-container">
                <img src={FlightBack} alt="flight-back" />
              </div>
            ) : null}

            {affiliate === affiliates.klm ? (
              <p>{arrivals}</p>
            ) : (
              <p>
                {arrivals}{" "}
                {
                  detailedHoliday?.holidayDetailInfo.flightInfo.inBound
                    .airlineName
                }
              </p>
            )}
          </div>
          <div className="flight-overview-container-box-content">
            {flightLegsImage("inBound")}
            <div className="flight-overview-container-box-content-hours">
              <p className="flight-overview-container-box-content-hours-start">
                {flightInfo?.inBound.departureTime}
                <span className="flight-overview-container-box-content-date-start">
                  {flightInfo?.inBound?.departureDateShort}
                </span>
              </p>
              {formatFlightTime(
                detailedHoliday.holidayDetailInfo.flightInfo.inBound
                  .durationHours,
                detailedHoliday.holidayDetailInfo.flightInfo.inBound
                  .durationMinutes
              )}
              <p className="flight-overview-container-box-content-hours-end">
                {flightInfo?.inBound.arrivalTime}
                <span className="flight-overview-container-box-content-date-end">
                  {flightInfo?.inBound?.arrivalDateShort}
                </span>
              </p>
            </div>
            <div className="flight-overview-container-box-content-direction">
              <p className="flight-overview-container-box-content-direction-start">
                {flightInfo?.inBound.departureAirport}
              </p>
              {legDetailsTextInBound()}
              <p className="flight-overview-container-box-content-direction-end">
                {flightInfo?.inBound.arrivalAiport}
              </p>
            </div>
            {affiliate === affiliates.klm && (
              <img
                src={GetLogoPath(flightInfo?.inBound.airlineCode)}
                className="flight-overview-container-box-content-image"
                alt="Company Logo"
              />
            )}
          </div>
          <div className="flight-overview-container-box-baggage-info">
            <div className="flight-overview-container-box-baggage-info-handbagage hand-luggage">
              <div
                className={
                  flightInfo?.inBound.showCabinLuggageLabel
                    ? "hand-luggage-excluded"
                    : "hand-luggage-included"
                }
              ></div>
              <p>
                {flightInfo?.inBound.showCabinLuggageLabel ? (
                  <>
                    <span>{`${contentfulHolidayPage[0]?.fields?.flight?.luggage?.excluded} `}</span>
                    {
                      contentfulHolidayPage[0]?.fields?.flight?.luggage
                        ?.cabinLuggageSummary
                    }
                  </>
                ) : (
                  <>
                    <span>{`${contentfulHolidayPage[0]?.fields?.flight?.luggage?.included} `}</span>
                    {
                      contentfulHolidayPage[0]?.fields?.flight?.luggage
                        ?.handLuggage
                    }
                  </>
                )}
              </p>
            </div>

            <div className="flight-overview-container-box-baggage-info-briefcase">
              <div className={`baggage`}>
                <div className={`baggage-excluded`}></div>
              </div>
              <p>
                <span>
                  {contentfulHolidayPage[0]?.fields?.flight?.luggage?.excluded}
                </span>
                {ruimbagage}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
