import { staticColours } from "./static-colours";

export const affiliateStyleRules = {
  KLM: {
    holidayCardUsesSideBedIcon: true,
    holidayCardUsesFrontBedIcon: false,
    imageFlipperArrowColour: staticColours.klmBlue,
    tooltipBackgroundColour: staticColours.klmBlue,
    starRatingColour: "#F9BF71",
    usesFlightIcon: true,
  },
  TRANSAVIA: {
    holidayCardUsesSideBedIcon: false,
    holidayCardUsesFrontBedIcon: true,
    imageFlipperArrowColour: staticColours.transaviaPurple,
    tooltipBackgroundColour: "#2800a0",
    starRatingColour: "#F9BF71",
    usesFlightIcon: false,
  },
};
