import { affiliates } from "../../../../common/enums/affiliates";
import { InputComponent } from "../../../../components/input-component/input-component";
import React, { useEffect, useState, useRef } from "react";
import { GetAffiliate } from "../../../../services/general.service";
import { FormInputDropdownComponent } from "../../../../components/form-input-dropdown/form-input-dropdown.component";
import { Controller } from "react-hook-form";
import { PassengerDetailsFormFields } from "../../../../common/constants/passengerDetailsFormFields";
import { ValidationPattern } from "../../../../common/enums/validationRulesEnum";
import { frequentFlyerPreferredAirlineCode } from "../../../../common/enums/passengerDetailsEnum";

export const FrequentFlyerComponent = ({
  frequentFlyerAirlineOptions,
  name,
  travellerInfo,
  control,
  errors,
  unregister,
  setValue,
  contentfulCheckoutPassengerDetails,
  contentfulCheckoutFormValidators,
}) => {
  const affiliate = GetAffiliate();
  const [frequentFlyer, setFrequentFlyer] = useState(false);
  const { frequentFlyer: frequentFlyerError } = errors[name] ?? "";

  const loyaltyNumberInputRef = useRef(null);

  useEffect(() => {
    if (travellerInfo && travellerInfo.frequentFlyer?.loyaltyNumber?.length > 0) {
      setFrequentFlyer(true);
    }
  }, []);

  useEffect(() => {
    if (!frequentFlyer) {
      setValue(`${name}.${PassengerDetailsFormFields.FREQUENT_FLYER}`, null);
      unregister(`${name}.${PassengerDetailsFormFields.FREQUENT_FLYER}`);
      unregister(
        `${name}.${PassengerDetailsFormFields.FREQUENT_FLYER}.${PassengerDetailsFormFields.FREQUENT_FLYER_AIRLINE_INFO}`
      );
      unregister(
        `${name}.${PassengerDetailsFormFields.FREQUENT_FLYER}.${PassengerDetailsFormFields.FREQUENT_FLYER_CARD_NUMBER}`
      );
    }
  }, [frequentFlyer]);

  let loyaltyNumberError;

  if (frequentFlyerError) {
    loyaltyNumberError = frequentFlyerError.loyaltyNumber;
  }

  const handleOnClickFrequentFlyer = () => {
    setFrequentFlyer((frequentFlyer) => !frequentFlyer);
  };
  const preferredFrequentFlyer = frequentFlyerAirlineOptions.find(
    (airLine) => airLine.code === frequentFlyerPreferredAirlineCode
  );

  return (
    <div>
      {affiliate === affiliates.klm && (
        <div className="frequent-flyer-checkbox">
          <label className="frequent-flyer-label" onClick={handleOnClickFrequentFlyer}>
            <div
              className={
                frequentFlyer
                  ? "checkbox-container active"
                  : "checkbox-container inactive"
              }
            >
              <div className="checkbox"></div>
            </div>
            <div>
              {contentfulCheckoutPassengerDetails &&
                contentfulCheckoutPassengerDetails[0]?.fields?.frequentFlyer}
            </div>
          </label>
          {frequentFlyer && (
            <div className="frequent-flyer-inputs">
              <label className="frequent-flyer-society">
                <Controller
                  name={`${name}.${PassengerDetailsFormFields.FREQUENT_FLYER}.${PassengerDetailsFormFields.FREQUENT_FLYER_AIRLINE_INFO}`}
                  control={control}
                  defaultValue={preferredFrequentFlyer}
                  render={({ field, fieldState }) => (
                    <div className="">
                      <FormInputDropdownComponent
                        name={`${name}.${PassengerDetailsFormFields.FREQUENT_FLYER}.${PassengerDetailsFormFields.FREQUENT_FLYER_AIRLINE_INFO}`}
                        label={
                          contentfulCheckoutPassengerDetails &&
                          contentfulCheckoutPassengerDetails[0]?.fields
                            ?.frequentFlyerSelectSocietyPlaceholder
                        }
                        defaultValue={preferredFrequentFlyer}
                        value={field.value}
                        onChange={(value) => field.onChange(value)}
                        isError={fieldState.error}
                        inputOptions={[]}
                        preselectedValue={preferredFrequentFlyer}
                        inputOptionObjects={frequentFlyerAirlineOptions}
                      />
                    </div>
                  )}
                />
              </label>
              <label className="frequent-flyer-card-number">
                <Controller
                  name={`${name}.${PassengerDetailsFormFields.FREQUENT_FLYER}.${PassengerDetailsFormFields.FREQUENT_FLYER_CARD_NUMBER}`}
                  control={control}
                  defaultValue={travellerInfo.frequentFlyer.loyaltyNumber}
                  rules={{
                    required:
                      contentfulCheckoutFormValidators &&
                      contentfulCheckoutFormValidators[0]?.fields
                        ?.frequentFlyerCardNumberRequired,
                    pattern: {
                      value: ValidationPattern.ONLY_DIGITS,
                      message:
                        contentfulCheckoutFormValidators &&
                        contentfulCheckoutFormValidators[0]?.fields
                          ?.frequentFlyerCardNumberOnlyDigits,
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <div className="country-label-input">
                      <InputComponent
                        name={`${name}.${PassengerDetailsFormFields.FREQUENT_FLYER}.${PassengerDetailsFormFields.FREQUENT_FLYER_CARD_NUMBER}`}
                        label={
                          contentfulCheckoutPassengerDetails &&
                          contentfulCheckoutPassengerDetails[0]?.fields
                            ?.frequentFlyerCardNumber
                        }
                        defaultValue=""
                        value={field.value}
                        onChange={(value) => field.onChange(value)}
                        isError={fieldState.error}
                        ref={(e) => {
                          field.ref(e);
                          loyaltyNumberInputRef.current = e;
                        }}
                      />
                    </div>
                  )}
                />
                {loyaltyNumberError && (
                  <div className="error-message-placeholder">
                    <span className="error error-message">
                      {loyaltyNumberError.message}
                    </span>
                  </div>
                )}
              </label>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
