import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PagesConstant } from "../../../common/constants/pages";
import { pagination } from "../../../common/constants/pagination";
import { searchDefaults } from "../../../common/constants/searchDefaults";
import { affiliates } from "../../../common/enums/affiliates";
import { holidayTypes } from "../../../common/enums/holidayTypes";
import { LocalStorageEnum } from "../../../common/enums/localStorageEnum";
import { pages } from "../../../common/enums/pages";
import { searchParamsEnum } from "../../../common/enums/searchParamsEnum";
import { GetAffiliate } from "../../../services/general.service";
import { LocalStorageService } from "../../../services/local-storage.service";
import { Button } from "../../button/button";
import DatepickerComponent from "../../datepicker/datepicker-component";
import { GenericButton } from "../../generic-button/generic-button";
import { DepartureDropdownComponent } from "../departure/departure-dropdown.component";
import { TravellerRoomsComponent } from "../traveller-rooms/traveller-rooms.component";
import EditDataIconKLM from "./../../../assets/icons/klm/edit-data-S.svg";
import { ReactComponent as HotelIconKLM } from "./../../../assets/icons/klm/hotel-S.svg";
import { ReactComponent as AvatarIconKLM } from "./../../../assets/icons/login-alt-S.svg";
import { ReactComponent as AvatarIconTransavia } from "./../../../assets/icons/transavia/Avatar.svg";
import EditDataIconTransavia from "./../../../assets/icons/transavia/Edit.svg";
import { ReactComponent as HotelIconTransavia } from "./../../../assets/icons/transavia/Hotel.svg";
import "./search-bar-content-component.scss";

export const SearchBarContentComponent = ({
  isMobile,
  hotelActiveTab,
  searchInfo,
  contentfulSearchBar,
  contentfulButtons,
  contentfulRoomAllocation,
  performSearch,
  holidayHeader,
  filters
}) => {
  const defaultRoom = {
    adults: 1,
    children: 0,
    babies: 0,
  };

  const [editMobile, setEditMobile] = useState(true);
  const [showTravellerRoomsComponent, setStateShowTravellerRoomsComponent] =
    useState(false);
  const [roomAllocation, setStateRoomAllocation] = useState(
    "Initial room allocation"
  );
  const [roomAllocationDisplayInfo, setStateRoomAllocationDisplayInfo] =
    useState();
  const [departureLocationsDeepLinkParam, setDepartureLocationsDeepLinkParam] =
    useState(""); //pass data to DepartureDropdown component
  const [apiSearchInfo, setStateApiSearchInfo] = useState(null);

  const isResultsPage = pages.results.some((route) =>
    window.location.pathname.includes(route)
  );
  const isHolidayPage = pages.holiday.some((route) =>
    window.location.pathname.includes(route)
  );

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setApiSearchInfo();
    setDepartureLocations();
    setSearchDates();
    setRoomAllocation();
    setHotelId();
  }, [searchInfo, contentfulRoomAllocation]);

  useEffect(() => {
    setSearchType();
  }, [hotelActiveTab]);

  useEffect(() => {
    setRoomAllocation();
    selectedDatesFromStorage();
  }, [searchInfo, contentfulRoomAllocation, LocalStorageService.getCulture()]);

  const setApiSearchInfo = () => {
    setStateApiSearchInfo(searchInfo);
  };

  const setDepartureLocations = () => {
    if (searchInfo && searchInfo.departureLocations) {
      const departureLocations = searchInfo.departureLocations.find(
        (e) => e.selectedByUser === true
      );

      if (departureLocations && departureLocationsDeepLinkParam) {
        const departureLocationCode = searchInfo.departureLocations
          .filter((s) => s.selectedByUser === true)
          .map((a) => a.departureLocationCode);

        LocalStorageService.setLocalStorageItem(
          LocalStorageEnum.DEPARTURE_LOCATION,
          departureLocationCode.toString()
        );
      } else {
        LocalStorageService.setLocalStorageItem(
          LocalStorageEnum.DEPARTURE_LOCATION,
          ""
        );
      }

      setDepartureLocationsDeepLinkParam(departureLocations);
    }
  };

  const setSearchDates = () => {
    if (searchInfo && searchInfo.departureLocations) {
      let output = {};

      if (isResultsPage) {
        output = {
          departureDate: searchInfo.searchDates.departureDate,
          departureDateFlexibility:
            searchInfo.searchDates.departureDateFlexibility,
          returnDate: searchInfo.searchDates.returnDate,
        };
      } else if (isHolidayPage) {
        output = {
          departureDate: holidayHeader?.searchBarInfo?.departureDate,
          departureDateFlexibility: searchInfo.departureDateFlexibility ?? 0,
          returnDate: holidayHeader?.searchBarInfo?.returnDate,
        };
      } else {
        output = {
          departureDate: formatSelectedDatesForRequest(
            searchInfo?.departureDate
          ),
          departureDateFlexibility: searchInfo.departureDateFlexibility ?? 0,
          returnDate: formatSelectedDatesForRequest(searchInfo.returnDate),
        };
      }
      LocalStorageService.setLocalStorageItem(
        LocalStorageEnum.SELECTED_DATES,
        output
      );
    }
  };

  const setSearchType = () => {
    const searchType = hotelActiveTab
      ? holidayTypes.FLIGHT_HOTEL
      : holidayTypes.FLIGHT_CAR;
    LocalStorageService.setLocalStorageItem(
      LocalStorageEnum.SEARCH_TYPE,
      searchType
    );
  };
  const setHotelId = () => {
    if (searchInfo?.hotelCode !== undefined) {
      LocalStorageService.setLocalStorageItem(
        LocalStorageEnum.HOTEL_ID,
        searchInfo?.hotelCode
      );
    }
  };

  const [selectedFormatDates, setSelectedFormatDates] = useState({});

  const selectedDatesFromStorage = () => {
    const selectedDatesFromStorage = LocalStorageService.getLocalStorageItem(
      LocalStorageEnum.SELECTED_DATES
    );

    selectedFormatDates.departureDate = selectedDatesFromStorage?.departureDate
      ? formatSelectedDatesForRequest(selectedDatesFromStorage?.departureDate)
      : searchInfo?.searchDates?.departureDate;

    selectedFormatDates.displayDepartureDate =
      selectedDatesFromStorage?.displayDepartureDate
        ? formatSelectedDates(selectedDatesFromStorage?.departureDate)
        : searchInfo?.searchDates?.displayDepartureDate;

    selectedFormatDates.returnDate = selectedDatesFromStorage?.returnDate
      ? formatSelectedDatesForRequest(selectedDatesFromStorage?.returnDate)
      : searchInfo?.searchDates?.returnDate;

    selectedFormatDates.displayReturnDate = formatSelectedDates(
      selectedDatesFromStorage?.displayReturnDate
    )
      ? formatSelectedDates(selectedDatesFromStorage?.displayReturnDate)
      : searchInfo?.searchDates?.displayReturnDate;

    selectedFormatDates.departureDateFlexibility =
      selectedDatesFromStorage?.departureDateFlexibility;

    setSelectedFormatDates(selectedFormatDates);
  };
  const formatSelectedDatesForRequest = (date) => {
    const selectedDate = new Date(date);
    const year = selectedDate.getFullYear();
    const month = ("0" + (selectedDate.getMonth() + 1)).slice(-2);
    const day = ("0" + selectedDate.getDate()).slice(-2);
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  const formatSelectedDates = (date) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    if (date) {
      return new Date(date).toLocaleDateString(
        LocalStorageService.getCulture(),
        options
      );
    }
  };

  const setRoomAllocation = () => {
    let roomAllocation;
    if (searchInfo && searchInfo.roomAllocations) {
      roomAllocation = searchInfo.roomAllocations;
    } else if (localStorage.getItem("roomAllocation")) {
      roomAllocation = JSON.parse(localStorage.getItem("roomAllocation"));
    } else {
      roomAllocation = [defaultRoom];
    }

    setStateRoomAllocation(roomAllocation);
    setRoomAllocationDisplayInfo(roomAllocation);
    LocalStorageService.setLocalStorageItem(
      LocalStorageEnum.ROOM_ALLOCATION,
      JSON.stringify(roomAllocation)
    );
    const roomAllocationCode = parseRoomAllocationToCode(roomAllocation);
  };

  const parseRoomAllocationToCode = (roomAllocation) => {
    let roomAllocationCode;
    if (roomAllocation) {
      roomAllocationCode = roomAllocation
        ?.map((e) => {
          const room = "R";
          const adults = Array(e.adults + 1).join("A");
          const children = Array(e.children + 1).join("C");
          const babies = Array(e.babies + 1).join("B");
          return room + adults + children + babies;
        })
        .join("");
    }
    return roomAllocationCode;
  };

  const setShowTravellerRoomsComponent = () => {
    if (!showTravellerRoomsComponent) {
      setStateShowTravellerRoomsComponent(true);
    }
  };

  const closeTravellerRoomsComponent = () => {
    if (showTravellerRoomsComponent) {
      setStateShowTravellerRoomsComponent(false);
    }
  };

  const updateRoomAllocation = (roomAllocation) => {
    setStateRoomAllocation(roomAllocation);
    setRoomAllocationDisplayInfo(roomAllocation);
    closeTravellerRoomsComponent();
  };

  const getNumberOfTravellers = (roomAllocation) => {
    const numberOfTravellers = roomAllocation
      .map(
        (e) => e.adults.valueOf() + e.children.valueOf() + e.babies.valueOf()
      )
      .reduce((a, b) => a + b);
    return numberOfTravellers;
  };

  const setRoomAllocationDisplayInfo = (roomAllocation) => {
    const numberOfTravellers = roomAllocation
      ? getNumberOfTravellers(roomAllocation)
      : 1;
    const numberOfRooms = roomAllocation ? roomAllocation.length : 1;
    const label = `
            ${numberOfTravellers} ${contentfulRoomAllocation?.numberOfTravellers},
            ${numberOfRooms} ${contentfulRoomAllocation?.numberOfRooms}
        `;
    const output = { numberOfTravellers, numberOfRooms, label };
    setStateRoomAllocationDisplayInfo(output);
    return output;
  };

  const handleOnEdit = () => {
    setEditMobile(!editMobile);
  };

  const setSearchRequestBody = () => {
    const departureLocations =
      searchParams.get(searchParamsEnum.departureLocation) ??
      LocalStorageService.getLocalStorageItem(
        LocalStorageEnum.DEPARTURE_LOCATION
      );
    const destinationLocations = LocalStorageService.getLocalStorageItem(
      LocalStorageEnum.DESTINATION_LOCATIONS
    );

    const sortType = LocalStorageService.getLocalStorageItem(
      LocalStorageEnum.SELECTED_SORT_TYPE
    );

    const pageAndSorting =  {
      currentPage: pagination.firstPage,
      sortType: sortType,
    };

    const searchDates = JSON.parse(
      LocalStorageService.getLocalStorageItem(LocalStorageEnum.SELECTED_DATES)
    );
    const searchType =
      LocalStorageService.getLocalStorageItem(LocalStorageEnum.SEARCH_TYPE) ??
      searchDefaults.searchType.flightHotel;
    const culture = LocalStorageService.getCulture();
    const roomAllocation = JSON.parse(
      LocalStorageService.getLocalStorageItem(LocalStorageEnum.ROOM_ALLOCATION)
    );
    const hotelId = LocalStorageService.getLocalStorageItem(
      LocalStorageEnum.HOTEL_ID
    );
    const roomAllocationCode = parseRoomAllocationToCode(roomAllocation);
    if (isResultsPage) {
      const searchRequestBody = {
        departureLocationCode: departureLocations,
        destinationLocationCode: destinationLocations,
        departureDate: searchDates?.departureDate,
        returnDate: searchDates?.returnDate,
        departureDateFlexibility: searchDates?.departureDateFlexibility ?? 0,
        searchType: searchType,
        roomAllocationCode: roomAllocationCode,
        culture: culture,
        filters: filters,
        pageAndSorting: pageAndSorting
      };
      return searchRequestBody;
    }

    if (!isResultsPage) {
      const searchRequestBody = {
        departurelocation: departureLocations,
        destinationlocation: destinationLocations,
        departuredate: searchDates?.departureDate,
        returndate: searchDates?.returnDate,
        roomallocation: roomAllocationCode,
        hotelid: hotelId,
        culture: culture
      };
      const queryParams = new URLSearchParams(searchRequestBody);
      window.location.href = `${
        PagesConstant.HOLIDAY
      }?${queryParams.toString()}`;
    }
  };

  return (
    <div className="search-bar-content-component">
      {((!editMobile && isMobile) || !isMobile) && (
        <div
          className={`search-bar-container ${
            !isResultsPage ? "search-bar-padding-left" : ""
          }`}
        >
          <div className="search-bar-section">
            <div className="section-header departure-dropdown-header">
              {contentfulSearchBar?.departureLocationLabel}
            </div>
            <div className="section-content">
              <DepartureDropdownComponent
                optionList={searchInfo?.departureLocations}
                contentfulButtons={contentfulButtons}
                isMobile={isMobile}
                departureLocationsDeepLinkParam={
                  departureLocationsDeepLinkParam
                }
                isResultsPage={isResultsPage}
              />
            </div>
          </div>
          <div className="search-bar-section">
            <div className="section-header">
              {contentfulSearchBar?.travelCalendarLabel}
            </div>
            <div className="section-content">
              <div className="search-bar-section">
                <DatepickerComponent
                  contentfulButtons={contentfulButtons}
                  contentfulSearchBar={contentfulSearchBar}
                  searchDates={searchInfo?.searchDates}
                  formatSelectedDates={formatSelectedDates}
                  formatSelectedDatesForRequest={formatSelectedDatesForRequest}
                />
              </div>
            </div>
          </div>
          <div className="search-bar-section">
            <div className="section-header">
              {contentfulSearchBar?.travellersAndRoomsLabel}
            </div>
            <div className="section-content">
              <div
                className="traveller-rooms-component-container"
                onClick={setShowTravellerRoomsComponent}
              >
                <div className="text">{roomAllocationDisplayInfo?.label}</div>
                <div className="dropdown-caret-wrapper">
                  <div
                    className={
                      showTravellerRoomsComponent
                        ? "dropdown-caret rotate"
                        : "dropdown-caret"
                    }
                  ></div>
                </div>
                {showTravellerRoomsComponent ? (
                  <TravellerRoomsComponent
                    contentfulButtons={contentfulButtons}
                    contentfulRoomAllocation={contentfulRoomAllocation}
                    roomAllocation={roomAllocation}
                    updateRoomAllocation={updateRoomAllocation}
                    parseRoomAllocationToCode={parseRoomAllocationToCode}
                    closeTravellerRoomsComponent={closeTravellerRoomsComponent}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="search-bar-section">
            <Button
              buttonText={contentfulButtons?.close}
              onClickAction={handleOnEdit}
              value={false}
              type="button"
              className="button-cancel mobile-cancel"
            />
            <GenericButton
              className="button search-button"
              onClick={() => performSearch(setSearchRequestBody())}
            >
              <span>{contentfulButtons?.search}</span>
              <i className="search-button-icon"></i>
            </GenericButton>
          </div>
        </div>
      )}

      {isMobile && editMobile && (
        <div className="edit-search-mobile">
          <div className="travel-description">
            <div className="departure-location-row">
              {departureLocationsDeepLinkParam?.departureLocationName}
            </div>
            <div className="travel-details-row">
              <div className="departure-and-return-date">
                <div>
                  {formatSelectedDates(
                    JSON.parse(
                      LocalStorageService.getLocalStorageItem(
                        LocalStorageEnum.SELECTED_DATES
                      )
                    )?.departureDate
                  ) ?? searchInfo?.searchDates?.displayDepartureDate}
                </div>
                <div className="dash-space"> -</div>
                <div>
                  {formatSelectedDates(
                    JSON.parse(
                      LocalStorageService.getLocalStorageItem(
                        LocalStorageEnum.SELECTED_DATES
                      )
                    )?.returnDate
                  ) ?? searchInfo?.searchDates?.displayReturnDate}
                </div>
                {selectedFormatDates?.departureDateFlexibility > 0 && (
                  <div className="travel-flexibility">
                    &plusmn;{" "}
                    {selectedFormatDates?.departureDateFlexibility ??
                      searchInfo?.searchDates?.departureDateFlexibility}
                  </div>
                )}
              </div>
              <div className="rooms-and-persons">
                <span className="number-of-persons">
                  {GetAffiliate() === affiliates.klm ? (
                    <AvatarIconKLM className="svg-icon" />
                  ) : (
                    <AvatarIconTransavia className="svg-icon" />
                  )}

                  <span> {roomAllocationDisplayInfo?.numberOfTravellers} </span>
                </span>
                <span className="number-of-rooms">
                  {GetAffiliate() === affiliates.klm ? (
                    <HotelIconKLM className="svg-icon-hotel" />
                  ) : (
                    <HotelIconTransavia className="svg-icon-hotel" />
                  )}
                  <span> {roomAllocationDisplayInfo?.numberOfRooms} </span>
                </span>
              </div>
            </div>
          </div>
          <Button
            type="button"
            className="edit-search-mobile-button"
            onClickAction={handleOnEdit}
          >
            <img
              src={
                GetAffiliate() === affiliates.klm
                  ? EditDataIconKLM
                  : EditDataIconTransavia
              }
            />
            <span>{contentfulButtons?.customizeTrip}</span>
          </Button>
        </div>
      )}
    </div>
  );
};
