export const getCustomStyling = (isMobile) => {
  return {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "300px" : "40vw",
    bgcolor: "white",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    outline: "none",
  };
};

export const getModalWithAnimationStyling = (isMobile, img) => {
  return {
    position: "absolute",
    top: isMobile ? "40%" : "35%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "344px" : "600px",
    height: isMobile ? "417px" : "394px",
    boxShadow: 24,
    padding: isMobile ? "32px 16px" : "32px",
    boxSizing: "border-box",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    outline: "none",
    bgcolor: "white",
    backgroundImage: `url(${img?.url})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
};

export const getProgressBarStyling = () => {
  return {
    height: 16,
    width: "100%",
    borderRadius: 8,
    bgcolor: "white",
  };
};
