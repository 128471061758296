import React from "react";
import "./traveler-name.component.scss";
import {
  HomeStayerFormName,
  PassengerDetailsFormFields,
} from "../../../../common/constants/passengerDetailsFormFields";
import { ValidationPattern } from "../../../../common/enums/validationRulesEnum";
import { Controller } from "react-hook-form";
import { InputComponent } from "../../../../components/input-component/input-component";

export const TravelerNameComponent = ({
  control,
  name,
  travellerInfo,
  errors,
  contentfulCheckoutPassengerDetails,
  contentfulCheckoutFormValidators,
}) => {
  const { firstName: firstNameError, lastName: lastNameError } =
    errors[name] ?? "";

  return (
    <div className="traveler-name-component">
      <label className="first-name">
        <Controller
          name={`${name}.${PassengerDetailsFormFields.FIRST_NAME}`}
          control={control}
          defaultValue={travellerInfo?.firstName}
          rules={{
            minLength: {
              value: 2,
              message:
                contentfulCheckoutFormValidators &&
                contentfulCheckoutFormValidators[0]?.fields
                  ?.firstNameMinLength2,
            },
            maxLength: {
              value: 50,
              message:
                contentfulCheckoutFormValidators &&
                contentfulCheckoutFormValidators[0]?.fields
                  ?.firstNameMaxLength50,
            },
            pattern: {
              value: ValidationPattern.ONLY_CHARS,
              message:
                contentfulCheckoutFormValidators &&
                contentfulCheckoutFormValidators[0]?.fields?.onlyLettersAllowed,
            },
            required:
              contentfulCheckoutFormValidators &&
              contentfulCheckoutFormValidators[0]?.fields?.firstNameRequired,
          }}
          render={({ field, fieldState }) => (
            <div className="">
              <InputComponent
                label={
                  name === HomeStayerFormName
                    ? contentfulCheckoutPassengerDetails &&
                      contentfulCheckoutPassengerDetails[0]?.fields
                        .homeStayerFirstName
                    : contentfulCheckoutPassengerDetails &&
                      contentfulCheckoutPassengerDetails[0]?.fields?.firstName
                }
                name={`${name}.${PassengerDetailsFormFields.FIRST_NAME}`}
                value={field.value}
                onChange={field.onChange}
                isError={fieldState.error}
                inputMode="text"
                ref={field.ref}
              />
            </div>
          )}
        />
        {firstNameError && (
          <div className="error-message-placeholder">
            <span className="error error-message">
              {firstNameError?.message}
            </span>
          </div>
        )}
      </label>
      <label className="last-name">
        <Controller
          name={`${name}.${PassengerDetailsFormFields.LAST_NAME}`}
          control={control}
          defaultValue={travellerInfo?.lastName}
          rules={{
            minLength: {
              value: 2,
              message:
                contentfulCheckoutFormValidators &&
                contentfulCheckoutFormValidators[0]?.fields?.lastNameMinLength2,
            },
            maxLength: {
              value: 50,
              message:
                contentfulCheckoutFormValidators &&
                contentfulCheckoutFormValidators[0]?.fields
                  ?.lastNameMaxLength50,
            },
            pattern: {
              value: ValidationPattern.ONLY_CHARS,
              message:
                contentfulCheckoutFormValidators &&
                contentfulCheckoutFormValidators[0]?.fields?.onlyLettersAllowed,
            },
            required:
              contentfulCheckoutFormValidators &&
              contentfulCheckoutFormValidators[0]?.fields?.lastNameRequired,
          }}
          render={({ field, fieldState }) => (
            <div>
              <InputComponent
                label={
                  name === HomeStayerFormName
                    ? contentfulCheckoutPassengerDetails &&
                      contentfulCheckoutPassengerDetails[0]?.fields
                        .homeStayerLastName
                    : contentfulCheckoutPassengerDetails &&
                      contentfulCheckoutPassengerDetails[0]?.fields?.lastName
                }
                name={`${name}.${PassengerDetailsFormFields.LAST_NAME}`}
                value={field.value}
                onChange={field.onChange}
                isError={fieldState.error}
                inputMode="text"
                ref={field.ref}
              />
            </div>
          )}
        />
        {lastNameError && (
          <div className="error-message-placeholder">
            <span className="error error-message">{lastNameError.message}</span>
          </div>
        )}
      </label>
    </div>
  );
};
