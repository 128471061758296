export const SearchDtoParamsEnum = {
  authInfo: "authInfo",
  culture: "culture",
  deepLink: "deepLink",
  departureDate: "departureDate",
  departureDateFlexibility: "departureDateFlexibility",
  departureLocationCode: "departureLocationCode",
  destinationLocationCode: "destinationLocationCode",
  filters: "filters",
  haveDatesChanged: "haveDatesChanged",
  marketingInfo: "marketingInfo",
  returnDate: "returnDate",
  roomAllocationCode: "roomAllocationCode",
  searchType: "searchType",
};
