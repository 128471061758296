export const affiliateDetails = [
  {
    name: "KLM",
    tabTitleEnglish: "Flight & Hotel deals | KLM.nl",
    tabTitleDutch: "Vlucht & Hotel deals | KLM.nl",
    faviconPath: "klm",
  },
  {
    name: "Transavia",
    tabTitleEnglish: "Transavia Holidays - Transavia EN",
    tabTitleDutch: "Transavia Holidays - Transavia NL",
    faviconPath: "transavia",
  },
];
