import "./hotel-list-map.component.scss";
import { Wrapper } from "@googlemaps/react-wrapper";
import config from "./../../environments/config.json";
import { useEffect, useRef, useState } from "react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { PriceMarkerComponent } from "./price-marker/price-marker.component";
import InfoWindowContentComponent from "./info-window/info-window-content.component";
import { createRoot } from "react-dom/client";
import { useWindowSizeDetector } from "../../hooks/useWindowSizeDetector";
import { breakpoints } from "../../common/constants/breakpoints";
import { GoogleMapConst } from "../../common/enums/googleMap";
import {getClusterSizeStyles} from "./clusters-styling.js"

const HotelListMapComponent = ({
  fullHolidaysData,
  contentfulButtons,
  contentfulHolidaysComponent,
  isMapExtended,
  singleHotelData,
  mapClickedFromHolidayCard,
}) => {
  const [markers, setMarkers] = useState(null);

  useEffect(() => {
    setMarkers(fullHolidaysData.data);
  }, [fullHolidaysData]);

  return (
    <div className="hotel-list-map-component">
      <Wrapper apiKey={config.GM_KEY} version="beta" libraries={["marker"]}>
        <HotelMap
          singleHotelData={singleHotelData}
          mapClickedFromHolidayCard={mapClickedFromHolidayCard}
          markers={markers}
          isMapExtended={isMapExtended}
          contentfulButtons={contentfulButtons}
          contentfulHolidaysComponent={contentfulHolidaysComponent}
        />
      </Wrapper>
    </div>
  );
};

const HotelMap = ({
  singleHotelData,
  mapClickedFromHolidayCard,
  markers,
  isMapExtended,
  contentfulButtons,
  contentfulHolidaysComponent,
}) => {
  const [map, setMap] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);

  const ref = useRef();
  const windowSizeDetector = useWindowSizeDetector();

  useEffect(() => {
    windowSizeDetector.width < breakpoints.SM
      ? setIsMobile(true)
      : setIsMobile(false);
  }, [windowSizeDetector]);

  const mapOptions = {
    mapId: config.GOOGLE_MAP_ID,
    fullscreenControl: true,
    zoomControl: true,
    streetViewControl: true,
    disableDefaultUI: true,
    controlSize: 24,
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: window?.google?.maps?.MapTypeControlStyle?.HORIZONTAL_BAR,
      position: window?.google?.maps?.ControlPosition?.BOTTOM_LEFT,
      mapTypeIds: [
        window.google?.maps?.MapTypeId?.ROADMAP,
        window.google?.maps?.MapTypeId?.SATELLITE,
        window.google?.maps?.MapTypeId?.TERRAIN,
      ],
    },
  };

  useEffect(() => {
    if (singleHotelData) {
      const selectedPlaceFromHolidaysCard = {
        hotelId: singleHotelData?.hotel?.id,
        hotelName: singleHotelData?.hotel?.name,
        discountPerPerson: singleHotelData?.summary?.discountPerPerson,
        holidayPageLink: singleHotelData?.holidayPageLink,
        imageUrl: singleHotelData?.hotel?.images[0],
        position: singleHotelData?.hotel?.location?.position,
        pricePerPerson: singleHotelData?.summary?.pricePerPerson,
        rating: singleHotelData?.hotel?.rating,
      };

      setActiveMarker(selectedPlaceFromHolidaysCard);
    }

    if (!map && window.google) {
      setMap(new window.google.maps.Map(ref.current, mapOptions));
    }

    if (map && markers) {
      const { center, zoom } = calculateMapCenterAndZoom(markers, map);
      map.setCenter(center);
      map.setZoom(zoom);
    }
  }, [map, markers, singleHotelData]);

  const handleOnClose = () => {
    isMapExtended(!isMapExtended);
  };

  const calculateMapCenterAndZoom = (markers, map) => {
    if (singleHotelData) {
      return { center: singleHotelData.hotel.location.position, zoom: GoogleMapConst.ZOOM.DEFAULT };
    }
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach((marker) => bounds.extend(marker.position));
    const center = bounds.getCenter();

    if (map) {
      map.fitBounds(bounds);
    }
    const zoomLevel = calculateZoomLevel(bounds);
    return {
      center: { lat: center.lat(), lng: center.lng() },
      zoom: zoomLevel,
    };
  };

  const calculateZoomLevel = (bounds) => {
    if (singleHotelData) {
      return GoogleMapConst.ZOOM.DEFAULT;
    } else {
      const worldDimensions = { height: 256, width: 384 };
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      const latFraction = (ne.lat() - sw.lat()) / 360;
      const lngFraction = (ne.lng() - sw.lng()) / 360;
      const latZoomFractial = Math.log2(
        worldDimensions.height / 256 / latFraction
      );
      const lngZoomFractial = Math.log2(
        worldDimensions.width / 256 / lngFraction
      );
      const latZoom = Math.round(latZoomFractial);
      const lngZoom = Math.round(lngZoomFractial);
      const calculatedZoom = Math.min(latZoom, lngZoom);
      return calculatedZoom;
    }
  };

  const screenSizeMapStyle = (() => {
    if (isMobile) {
      return "map-container-mobile-style";
    }
    return "map-container-style";
  })();


  return (
    <>
      <div ref={ref} id="map" 
        className={screenSizeMapStyle} 
       />
      {map && (
        <Markers
          map={map}
          markers={markers}
          activeMarker={activeMarker}
          singleHotelData={singleHotelData}
          mapClickedFromHolidayCard={mapClickedFromHolidayCard}
          contentfulButtons={contentfulButtons}
          contentfulHolidaysComponent={contentfulHolidaysComponent}
        />
      )}
      <button onClick={handleOnClose} className="map-button-close">
        {contentfulButtons && contentfulButtons[0]?.fields?.cancel}
        <div className="close-icon"></div>
      </button>
    </>
  );
};

const Markers = ({
  map,
  markers,
  singleHotelData,
  activeMarker,
  contentfulButtons,
  contentfulHolidaysComponent,
}) => {
  const markerRefs = useRef([]);
  const infoWindowRef = useRef(null);
  const clustererRef = useRef(null);

  useEffect(() => {
    if (activeMarker) {
      const position = singleHotelData.hotel?.location?.position;
      const centerPoint = { lat: position.lat, lng: position.lng };
      map.panTo(centerPoint);
    }
    if (!map || !markers){ 
      return;
    }

    if (markerRefs.current.length) {
      markerRefs.current.forEach((marker) => marker.setMap(null));
      markerRefs.current = [];
    }

    if (!infoWindowRef.current) {
      infoWindowRef.current = new window.google.maps.InfoWindow();
    }

    map.addListener("click", () => {
      infoWindowRef.current.close();
    });

    markerRefs.current = markers.map((markerData) => {
      const container = document.createElement("img");
      container.src = PriceMarkerComponent({
        price: markerData.pricePerPerson,
      });

      const marker = new window.google.maps.marker.AdvancedMarkerView({
        position: markerData.position,
        map: map,
        content: container,
      });

      marker.addListener("gmp-click", () => {
        const containerDiv = document.createElement("div");
        map.panTo(markerData.position);
        const root = createRoot(containerDiv);
        root.render(
          <InfoWindowContentComponent
            contentfulHolidaysComponent={contentfulHolidaysComponent}
            activeMarker={markerData}
            contentfulButtons={contentfulButtons}
          />
        );

        infoWindowRef.current.setContent(containerDiv);
        infoWindowRef.current.open(map, marker);
      });

      return marker;
    });

    if (activeMarker) {
      const activePosition = activeMarker.position;
      const activeContainer = document.createElement("img");
      activeContainer.src = PriceMarkerComponent({
        price: activeMarker.pricePerPerson,
      });

      const activeMarkerView = new window.google.maps.marker.AdvancedMarkerView(
        {
          position: activePosition,
          map: map,
          content: activeContainer,
        }
      );

      const activeContainerDiv = document.createElement("div");
      const activeRoot = createRoot(activeContainerDiv);
      activeRoot.render(
        <>
          <InfoWindowContentComponent
            contentfulHolidaysComponent={contentfulHolidaysComponent}
            activeMarker={activeMarker}
            contentfulButtons={contentfulButtons}
          />
        <div className="arrow-indicator"></div>
        </>
      );

      infoWindowRef.current.setContent(activeContainerDiv);
      infoWindowRef.current.open(map, activeMarkerView);

      map.panTo(activePosition);
    }

    clustererRef.current = new MarkerClusterer({
      markers: markerRefs.current,
      map: map,
      renderer: {
        render: ({ count, position }) => {
          const div = document.createElement("div");
          div.className = "cluster-marker";

          const styles = getClusterSizeStyles(count);
          div.style.height = styles.height;
          div.style.width = styles.width;
          div.style.lineHeight = styles.lineHeight;
          div.innerText = count;

          return new window.google.maps.marker.AdvancedMarkerView({
            position,
            content: div,
          });
        },
      },
    });

    clustererRef.current.addListener("click", (event) => {
      infoWindowRef.current.close();
      const cluster = event.target;
      if (map.getZoom() < map.maxZoom) {
        map.setZoom(map.getZoom() + 1);
        map.panTo(cluster.getPosition());
      }
    });

    return () => {
      if (clustererRef.current) {
        clustererRef.current.clearMarkers();
      }
    };
  }, [map, markers, singleHotelData, activeMarker]);

  return null;
};

export default HotelListMapComponent;
