export const getClusterSizeStyles = (count) => {
  const clusterSize = {
    small: 20,
    medium: 60,
  }
  if (count < clusterSize.small) {
    return { height: "32px", width: "32px", lineHeight: "32px" };
  } else if (count < clusterSize.medium) {
    return { height: "48px", width: "48px", lineHeight: "48px" };
  } else {
    return { height: "64px", width: "64px", lineHeight: "64px" };
  }
};