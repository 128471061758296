import config from "../environments/config.json";
import {LocalStorageEnum} from "../common/enums/localStorageEnum";

export const LocalStorageService = {

    setCulture: (value) => {
        localStorage.setItem(LocalStorageEnum.HOLIDAYS_CULTURE, value);
    },

    getCulture: () => {
        return localStorage.getItem(LocalStorageEnum.HOLIDAYS_CULTURE) ?? config.DEFAULT_CULTURE;
    },

    getLocalStorageItem: (key) => {
        const isValueObject = typeof value === "object" && typeof value !== "string";
        if (isValueObject) {
            return JSON.parse(localStorage.getItem(key));
        }
        return localStorage.getItem(key);
    },

    setLocalStorageItem: (key, value) => {
        const isValueObject = typeof value === "object" && typeof value !== "string";
        if (isValueObject) {
            localStorage.setItem(key, JSON.stringify(value));
        }
        else {
            localStorage.setItem(key, value);
        }
    }
}
