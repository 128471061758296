import axios from "axios";

export const GetHolidayMockData = async () => {
  return await axios.get(
    `https://at-dev-363-at-app.azurewebsites.net/api/MockData/results-page`
  );
};

export const SampleSearchRequestBody = {
  culture: "nl-NL",
  departureDateFlexibility: 2,
  departureLocationCode: "AMS",
  destinationLocationCode: "",
  returnDate: "2024-01-28",
  roomAllocationCode: "",
  searchType: "FlightHotel",
};

export const SampleSearchClientKey = "KLMStaticPkgConsumer";
