export const GenericButton = ({
  children,
  className,
  onClick,
  disabledState
}) => {

  return (
    <button className={className} onClick={onClick} disabled={disabledState}>
      {children}
    </button>
  );
};
