import React from "react";
import { GetAffiliate } from "../../services/general.service";
import { ReactComponent as IconClose } from "../../assets/icons/klm/icon-close-summary.svg";
import { ReactComponent as IconCloseTransavia } from "../../assets/icons/transavia/icon-close-transavia-summary.svg";
import "./close-cart.scss";

const CloseCart = ({ onClose }) => {
  return (
    <div className="close-cart-container" onClick={onClose}>
      <div className="close-cart-body">
        <p>Sluit winkelwagen</p>
        {GetAffiliate() === "KLM" ? (
          <IconClose />
        ) : GetAffiliate() === "TRANSAVIA" ? (
          <IconCloseTransavia />
        ) : null}
      </div>
    </div>
  );
};

export default CloseCart;
