import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetAffiliate } from "../../services/general.service";
import { GetMealType } from "../../common/enums/meal-types";
import { affiliates } from "../../common/enums/affiliates";
import { ReactComponent as IconRoomTypeKLM } from "./../../assets/icons/klm/hotel-S.svg";
import { ReactComponent as IconRoomTypeTransavia } from "./../../assets/icons/transavia/Hotel.svg";

export const AccomodationComponent = ({
  data,
  contentfulHolidayPageSummary,
  contentfulHolidayPage
}) => {
  const [accomodationData, setAccomodationData] = useState(null);
  const [roomState, setRoomStateData] = useState(useSelector((state) => state.roomsData.descriptions));
  
  useEffect(() => {
    setAccomodationData(data)
  },[data]);

  return (
    <div className="accomodation-component">
      <div className="section-header">
        {GetAffiliate() === affiliates.klm ? (
          <IconRoomTypeKLM className="svg-color" />
        ) : (
          <IconRoomTypeTransavia className="svg-color" />
        )}
        {contentfulHolidayPageSummary &&
          contentfulHolidayPageSummary[0]?.fields?.hotel}
      </div>
      <div className="accomodation-description">
        {roomState
          ? roomState.map((description, index) => (
              <div key={index}>
                  <span>{description}</span>
              </div>
            ))
          : null}
      </div>
      <div>{GetMealType(data?.mealType)}</div>
      <div className="accommodation-date-info">
        {accomodationData?.checkInDate} - {accomodationData?.checkOutDate}
      </div>
      <div>
      {accomodationData?.duration}{" "}
      {contentfulHolidayPageSummary &&
          contentfulHolidayPageSummary[0]?.fields?.holidayPageSummaryExtension
            .days}

      {` (${accomodationData?.nights}`}
      {`${contentfulHolidayPage && contentfulHolidayPage[0].fields.trip.nights})`}
      
      </div>
    </div>
  );
};
