import React, { useState, useEffect, useRef } from "react";
import "./summary-price-overview.component.scss";
import warningIconKLM from "./../../../assets/icons/klm/ic_error_outline_klm.svg";
import warningIconTransavia from "./../../../assets/icons/transavia/ic_error_outline_transavia.svg";
import { GetAffiliate } from "../../../services/general.service";
import Tooltip from "../../tooltip/tooltip";
import { SummaryPriceOverviewTotalBlockComponent } from "./summary-price-overview-total-block/summary-price-overview-total-block.component";
import { ShoppingCartTooltipEnums } from "../../../common/enums/shoppingCartTooltipEnums";
import { affiliates } from "../../../common/enums/affiliates";

export const SummaryPriceOverviewComponent = ({
  contentfulHolidayPageSummary,
  contentfulHolidayPage,
  contentfulButtons,
  detailedHoliday,
  performCheckAvailability,
}) => {
  const affiliate = GetAffiliate();
  const isKLM = affiliate === affiliates.klm;
  const tooltipRef = useRef(null);
  const [tooltipVisible, setTooltipVisible] = useState({});

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        const updatedTooltipVisible = { ...tooltipVisible };
        Object.keys(ShoppingCartTooltipEnums).forEach((key) => {
          updatedTooltipVisible[key] = false;
        });
        setTooltipVisible(updatedTooltipVisible);
      }
    };

    const anyTooltipVisible = Object.values(tooltipVisible).some((v) => v);

    if (anyTooltipVisible) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [tooltipVisible]);

  const handleMouseOver = (key) => {
    setTooltipVisible((prevState) => ({ ...prevState, [key]: true }));
  };

  const handleMouseOut = (key) => {
    setTooltipVisible((prevState) => ({ ...prevState, [key]: false }));
  };

  return (
    <div className="summary-price-overview-component">
      <div className="summary-price-overview-block">
        <div className="price-overview-title">
          {contentfulHolidayPageSummary &&
            contentfulHolidayPageSummary[0]?.fields?.priceOverview}
        </div>
        <div className="price-overview-row">
          <div className="price-overview-row-description">
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields?.adults}
            {` (x${detailedHoliday?.holidayDetailInfo?.travelers})`}
          </div>
          <div className="price-overview-row-price">
            {"€ "}
            {detailedHoliday?.holidayDetailInfo?.priceDetailInfo.pricePerPerson}
          </div>
        </div>
        {detailedHoliday?.holidayDetailInfo?.priceDetailInfo?.discount && (
          <div className="price-overview-row">
            <div className="price-overview-row-description highlight-description-success">
              {contentfulHolidayPageSummary &&
                contentfulHolidayPageSummary[0]?.fields
                  ?.holidayPageSummaryExtension.packageHolidayDiscount}
            </div>
            <div className="price-overview-row-price highlight-description-success">
              - €{" "}
              {detailedHoliday?.holidayDetailInfo?.priceDetailInfo?.discount}
            </div>
          </div>
        )}
        <div className="price-overview-row">
          <div className="price-overview-row-description item-tooltip">
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields?.sgr}
            {` (x${detailedHoliday?.holidayDetailInfo?.travelers})`}
            <span
              className="content-holiday-tooltip"
              onMouseOver={() => handleMouseOver(ShoppingCartTooltipEnums.SGR)}
              onMouseOut={() => handleMouseOut(ShoppingCartTooltipEnums.SGR)}
            >
              <img src={warningIconKLM} alt="warning_icon" />
              {tooltipVisible[ShoppingCartTooltipEnums.SGR] && (
                <Tooltip
                  ref={tooltipRef}
                  header={
                    contentfulHolidayPageSummary &&
                    contentfulHolidayPageSummary[0]?.fields?.sgrTitle
                  }
                  body={
                    contentfulHolidayPageSummary &&
                    contentfulHolidayPageSummary[0]?.fields?.sgrContent
                  }
                />
              )}
            </span>
          </div>
          <div className="price-overview-row-price">
            {"€ "}
            {detailedHoliday?.holidayDetailInfo?.priceDetailInfo?.guaranteeFund}
          </div>
        </div>
        <div className="price-overview-row">
          <div className="price-overview-row-description item-tooltip">
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields?.calamityFund}
            <span
              className="content-holiday-tooltip"
              onMouseOver={() =>
                handleMouseOver(ShoppingCartTooltipEnums.Calamityfund)
              }
              onMouseOut={() =>
                handleMouseOut(ShoppingCartTooltipEnums.Calamityfund)
              }
            >
              <img src={warningIconKLM} alt="warning_icon" />
              {tooltipVisible[ShoppingCartTooltipEnums.Calamityfund] && (
                <Tooltip
                  ref={tooltipRef}
                  header={
                    contentfulHolidayPageSummary &&
                    contentfulHolidayPageSummary[0]?.fields?.calamityFund
                  }
                  body={
                    contentfulHolidayPageSummary &&
                    contentfulHolidayPageSummary[0]?.fields
                      ?.calamiteitenfondsContent
                  }
                />
              )}
            </span>
          </div>

          <div className="price-overview-row-price">
            {"€ "}
            {detailedHoliday?.holidayDetailInfo?.priceDetailInfo?.calamityFund}
          </div>
        </div>
        <div className="price-overview-row">
          <div className="price-overview-row-description">
            {contentfulHolidayPage &&
              contentfulHolidayPageSummary[0]?.fields?.bookingCost}
          </div>

          <div className="price-overview-row-price">
            {"€ "}
            {detailedHoliday?.holidayDetailInfo?.priceDetailInfo?.bookingCost}
          </div>
        </div>
        {detailedHoliday?.holidayDetailInfo?.priceDetailInfo?.localFee.length >
          0 && (
          <div className="price-overview-row">
            <div className="price-overview-row-description item-tooltip">
              {contentfulHolidayPageSummary &&
                contentfulHolidayPageSummary[0]?.fields?.localFee}
              <span
                className="content-holiday-tooltip"
                onMouseOver={() =>
                  handleMouseOver(ShoppingCartTooltipEnums.LocalFee)
                }
                onMouseOut={() =>
                  handleMouseOut(ShoppingCartTooltipEnums.LocalFee)
                }
              >
                <img src={warningIconKLM} alt="warning_icon" />
                {tooltipVisible[ShoppingCartTooltipEnums.LocalFee] && (
                  <Tooltip
                    ref={tooltipRef}
                    header={
                      contentfulHolidayPageSummary &&
                      contentfulHolidayPageSummary[0]?.fields?.betalen
                    }
                    body={
                      contentfulHolidayPageSummary &&
                      contentfulHolidayPageSummary[0]?.fields?.plaatseContent
                    }
                  />
                )}
              </span>
            </div>

            <div className="price-overview-row-price">
              {"€ "}
              {detailedHoliday?.holidayDetailInfo?.priceDetailInfo?.localFee}
            </div>
          </div>
        )}
        {detailedHoliday?.holidayDetailInfo?.priceDetailInfo?.localFee > 0 && (
          <div className="price-overview-row">
            <div className="price-overview-row-description">
              {contentfulHolidayPageSummary &&
                contentfulHolidayPageSummary[0]?.fields?.localFee}
              <img
                src={isKLM ? warningIconKLM : warningIconTransavia}
                alt="warning_icon"
              />
            </div>

            <div className="price-overview-row-price">
              {"€ "}
              {detailedHoliday?.holidayDetailInfo?.priceDetailInfo?.localFee}
            </div>
          </div>
        )}
      </div>
      <SummaryPriceOverviewTotalBlockComponent
        contentfulButtons={contentfulButtons}
        contentfulHolidayPageSummary={contentfulHolidayPageSummary}
        Icon={isKLM ? warningIconKLM : warningIconTransavia}
        detailedHoliday={detailedHoliday}
        performCheckAvailability={performCheckAvailability}
      />
    </div>
  );
};
