export const TravellerSelectorComponent = ({
  contentfulButtons,
  contentfulRoomAllocation,
  index,
  room,
  deleteRoom,
  setNumberOfTravellersPerRoom,
}) => {
  return (
    <div className="traveller-selector-component">
      {/* Header */}
      <div className="header">
        <div>
          {contentfulRoomAllocation?.travellerSelectorHeader} {index + 1}
        </div>
        {deleteRoom ? (
          <div
            className="delete-room-button"
            onClick={() => {
              deleteRoom(index);
            }}
            data-trigger="true"
          >
            {contentfulButtons?.delete}
          </div>
        ) : null}
      </div>

      {/* Selector */}
      <div className="selector">
        {/* Adults / Volwassenen */}
        <div className="item">
          <div className="selector-text-container">
            <div className="room-selector-header">
              {contentfulRoomAllocation?.travellerSelectorAdultHeader}
            </div>
            <div className="room-selector-text">
              {contentfulRoomAllocation?.travellerSelectorAdultText}
            </div>
          </div>
          <div className="selector-numeric-stepper">
            <div
              className={
                room.buttons.adultsMinusButtonDisabled
                  ? "minus-button disabled"
                  : "minus-button enabled"
              }
              onClick={
                !room.buttons.adultsMinusButtonDisabled
                  ? () => {
                      setNumberOfTravellersPerRoom(index, "-", "adults");
                    }
                  : null
              }
            >
              <div className="icon"></div>
            </div>
            <div className="value-indicator">{room.adults}</div>
            <div
              className={
                room.buttons.adultsPlusButtonDisabled
                  ? "plus-button disabled"
                  : "plus-button enabled"
              }
              onClick={
                !room.buttons.adultsPlusButtonDisabled
                  ? () => {
                      setNumberOfTravellersPerRoom(index, "+", "adults");
                    }
                  : null
              }
            >
              <div className="icon"></div>
            </div>
          </div>
        </div>

        {/* Children / Kinderen */}
        <div className="item">
          <div className="selector-text-container">
            <div className="room-selector-header">
              {contentfulRoomAllocation?.travellerSelectorChildrenHeader}
            </div>
            <div className="room-selector-text">
              {contentfulRoomAllocation?.travellerSelectorChildrenText}
            </div>
          </div>
          <div className="selector-numeric-stepper">
            <div
              className={
                room.buttons.childrenMinusButtonDisabled
                  ? "minus-button disabled"
                  : "minus-button enabled"
              }
              onClick={
                !room.buttons.childrenMinusButtonDisabled
                  ? () => {
                      setNumberOfTravellersPerRoom(index, "-", "children");
                    }
                  : null
              }
            >
              <div className="icon"></div>
            </div>
            <div className="value-indicator">{room.children}</div>
            <div
              className={
                room.buttons.childrenPlusButtonDisabled
                  ? "plus-button disabled"
                  : "plus-button enabled"
              }
              onClick={
                !room.buttons.childrenPlusButtonDisabled
                  ? () => {
                      setNumberOfTravellersPerRoom(index, "+", "children");
                    }
                  : null
              }
            >
              <div className="icon"></div>
            </div>
          </div>
        </div>

        {/* Babies / Baby’s */}
        <div className="item">
          <div className="selector-text-container">
            <div className="room-selector-header">
              {contentfulRoomAllocation?.travellerSelectorBabiesHeader}
            </div>
            <div className="room-selector-text">
              {contentfulRoomAllocation?.travellerSelectorBabiesText}
            </div>
          </div>
          <div className="selector-numeric-stepper">
            <div
              className={
                room.buttons.babiesMinusButtonDisabled
                  ? "minus-button disabled"
                  : "minus-button enabled"
              }
              onClick={
                !room.buttons.babiesMinusButtonDisabled
                  ? () => {
                      setNumberOfTravellersPerRoom(index, "-", "babies");
                    }
                  : null
              }
            >
              <div className="icon"></div>
            </div>
            <div className="value-indicator">{room.babies}</div>
            <div
              className={
                room.buttons.babiesPlusButtonDisabled
                  ? "plus-button disabled"
                  : "plus-button enabled"
              }
              onClick={
                !room.buttons.babiesPlusButtonDisabled
                  ? () => {
                      setNumberOfTravellersPerRoom(index, "+", "babies");
                    }
                  : null
              }
            >
              <div className="icon"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
