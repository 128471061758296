import TransaviaHolidayIcon from "../../assets/icons/transavia/transavia-holiday-logo.png";
import { affiliates } from "../../common/enums/affiliates";
import { GetAffiliate } from "../../services/general.service";
import { HolidaySummaryFlightOverviewComponent } from "./holiday-summary-flight-overview/holiday-summary-flight-overview.component";
import { HolidaySummaryHotelOverviewComponent } from "./holiday-summary-hotel-overview/holiday-summary-hotel-overview.component";
import "./holiday-summary-overview.component.scss";
import { SummaryPriceOverviewComponent } from "./summary-price-overview/summary-price-overview.component";

export const HolidaySummaryOverviewComponent = ({
  contentfulHolidayPageSummary,
  contentfulHolidayPage,
  contentfulButtons,
  contentfulRoomAllocation,
  detailedHoliday,
  performCheckAvailability,
}) => {
  return (
    <div className="holiday-summary-overview-component">
      <div className="icon-container">
        {GetAffiliate() === affiliates.klm ? (
          <div className="summary-icon">
            <img
              src={
                contentfulHolidayPageSummary &&
                contentfulHolidayPageSummary[0]?.fields?.affiliateLogo?.fields
                  ?.file.url
              }
              alt="affiliate-logo"
            />
          </div>
        ) : (
          <img src={TransaviaHolidayIcon} width="180" />
        )}
      </div>
      <div className="summary-title">
        {contentfulHolidayPageSummary &&
          contentfulHolidayPageSummary[0]?.fields?.summaryTitle}
      </div>
      <div className="summary-section-container">
        <div className="holiday-summary-overview">
          <HolidaySummaryHotelOverviewComponent
            contentfulHolidayPageSummary={contentfulHolidayPageSummary}
            contentfulRoomAllocation={contentfulRoomAllocation}
            detailedHoliday={detailedHoliday}
          />
          <HolidaySummaryFlightOverviewComponent
            contentfulHolidayPageSummary={contentfulHolidayPageSummary}
            contentfulHolidayPage={contentfulHolidayPage}
            detailedHoliday={detailedHoliday}
          />
        </div>
        <div className="price-summary-overview">
          <SummaryPriceOverviewComponent
            contentfulHolidayPageSummary={contentfulHolidayPageSummary}
            contentfulHolidayPage={contentfulHolidayPage}
            contentfulButtons={contentfulButtons}
            detailedHoliday={detailedHoliday}
            performCheckAvailability={performCheckAvailability}
          />
        </div>
      </div>
    </div>
  );
};
