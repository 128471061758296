import "./post-code-input.component.scss";
import { Controller } from "react-hook-form";
import { PassengerDetailsFormFields } from "../../../../../common/constants/passengerDetailsFormFields";
import { ValidationPattern } from "../../../../../common/enums/validationRulesEnum";
import { InputComponent } from "../../../../../components/input-component/input-component";
import React, { useRef } from "react";

export const PostCodeInputComponent = ({
  name,
  defaultZipCode,
  control,
  errors,
  contentfulCheckoutPassengerDetails,
  contentfulCheckoutFormValidators,
}) => {
  const { zipCode: zipCodeError } = errors[name] ?? "";
  const zipCodeInputRef = useRef(null);

  return (
    <div className="post-code-input-component">
      <label className="post-code-label">
        <Controller
          name={`${name}.${PassengerDetailsFormFields.ZIP_CODE}`}
          control={control}
          defaultValue={defaultZipCode}
          rules={{
            required: {
              value: true,
              message:
                contentfulCheckoutFormValidators &&
                contentfulCheckoutFormValidators[0]?.fields?.zipCodeRequired,
            },
            pattern: {
              value: ValidationPattern.ONLY_DIGITS_AND_LETTERS_ALLOWED,
              message:
                contentfulCheckoutFormValidators &&
                contentfulCheckoutFormValidators[0]?.fields
                  ?.zipCodeOnlyLettersAndDigitsAllowed,
            },
          }}
          render={({ field, fieldState }) => (
            <div className="post-code-input">
              <InputComponent
                label={
                  contentfulCheckoutPassengerDetails &&
                  contentfulCheckoutPassengerDetails[0]?.fields?.zipCode
                }
                name={`${name}.${PassengerDetailsFormFields.ZIP_CODE}`}
                value={field.value}
                onChange={field.onChange}
                isError={fieldState.error}
                inputMode="text"
                ref={(e) => {
                  field.ref(e);
                  zipCodeInputRef.current = e;
                }}
              />
            </div>
          )}
        />
        {zipCodeError && (
          <div className="error-message-placeholder">
            <span className="error error-message">{zipCodeError?.message}</span>
          </div>
        )}
      </label>
    </div>
  );
};
