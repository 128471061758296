import { FaTripadvisor } from "react-icons/fa";
import "./hotel-tripadvisor-rating.scss";

import { TripadvisorRatingComponent } from "../../tripadvisor-rating/tripadvisor-rating.component";

export const HotelTripadvisorRatingComponent = ({ data }) => {
  return (
    <div className="hotel-tripadvisor-rating-component">
      <FaTripadvisor />
      <div className="tripadvisor-rating-container">
        <TripadvisorRatingComponent
          data={parseFloat(data.hotel.rating.tripAdvisorRating)}
        />
      </div>
    </div>
  );
};