import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./custom-modal.scss";
import { Button } from "../button/button";

export const CustomModal = ({
  isMobile,
  shouldShow,
  headingText,
  handleClose,
  children,
  showCancelButton,
  showConfirmButton,
  cancelButtonText = "Cancel",
  confirmButtonText = "OK",
  confirmButtonAction
}) => {
  return (
    <div className="custom-modal-container">
      <Modal
        open={shouldShow}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="custom-modal-box">
          <div className="custom-modal-close">
            <div className="icon" onClick={handleClose}></div>
          </div>
          
          <div className="modal-body">
            {headingText && (
              <div className="custom-modal-header">
                {headingText}
              </div>
            )}

            <div className="modal-content">{children}</div>

            {
              (showCancelButton || showConfirmButton)
                ? <div className="modal-buttons">
                    {showCancelButton && (
                      <div className="modal-button">
                        <Button
                          buttonText={cancelButtonText}
                          onClickAction={handleClose}
                        />
                      </div>
                    )}
                    {showConfirmButton && (
                      <div className="modal-button">
                        <Button
                          className={"modal-button-confirm"}
                          buttonText={confirmButtonText}
                          onClickAction={confirmButtonAction}
                        />
                      </div>
                    )}
                  </div>
                : null
            }
          </div>
        </Box>
      </Modal>
    </div>
  );
};
