import { affiliates } from "../enums/affiliates";

export const barChart = (affiliate) => {
  return {
    height: {
      min: affiliate === affiliates.transavia ? 164 : 174,
      max: 220,
    },
    size: {
      small: 3,
      medium: 5,
      large: 7,
    },
    range: {
      small: 1,
      medium: 2,
      large: 3,
    },
  };
};
