export const PagesConstant = {
  SEARCH: "search",
  RESULTS: "results",
  HOLIDAY: "holiday",
  EXTRAS: "extras",
  CHECKOUT: "checkout",
  PAYMENT: "payment",
  CONFIRMATION: "confirmation",
  BASEPATH: "/app",
  NEW_SEARCH_KLM_HOLIDAYS_URL: "https://holidays.klm.nl", 
  NEW_SEARCH_TRANSAVIA_HOLIDAYS_URL: "https://holidays.transavia.com",
};

export const MAIN_ROUTE = "";
