import { useState } from "react";
import "./empty-state-info.component.scss";
import { renderDocument } from "../../services/contentful.service";
import CloseIcon from "@mui/icons-material/Close";

export const EmptyStateInfoComponent = ({
  defaultSearchUsed,
  contentfulEmptyStateInfo,
}) => {
  const [close, setClose] = useState(null);

  const onCloseEmptyStateInfo = () => {
    setClose((state) => !state);
  };

  return (
    defaultSearchUsed &&
    !close && (
      <div className="empty-state-info-component">
        <button
          onClick={onCloseEmptyStateInfo}
          className="empty-state-close-button"
        >
          <CloseIcon />
        </button>
        <div className="empty-state-content">
          <div className="empty-state-title">
            {contentfulEmptyStateInfo?.fields?.emptyStateInfoTitle}
          </div>
          <div className="empty-state-description">
            {renderDocument(
              contentfulEmptyStateInfo?.fields?.emptyStateInfoDescription
            )}
          </div>
        </div>
      </div>
    )
  );
};
