import "./baggage.component.scss";
import { useEffect, useState, useRef } from "react";
import { GetAffiliate } from "../../../services/general.service";
import { affiliates } from "../../../common/enums/affiliates";
import { ancillaryTypes } from "../../../common/constants/ancillaryTypes";
import CircularProgress from "@material-ui/core/CircularProgress";

export const BaggageDropdownComponent = ({
  currentPassenger,
  preselectedValue,
  onBaggageSelectionChanged,
  isLoading,
  loadingPassengerId,
}) => {
  const selectedOptionRef = useRef(null);
  const containerRef = useRef(null);
  const [isDropdownExtended, setDropdownExtended] = useState(false);
  const [inputValue, setInputValue] = useState();
  const [inputHelper, setInputHelper] = useState(preselectedValue);

  useEffect(() => {
    setInputValue(
      `${preselectedValue.displayName} - €${preselectedValue.price}`
    );
  }, [preselectedValue]);

  useEffect(() => {
    if (isDropdownExtended && selectedOptionRef.current) {
      selectedOptionRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }, [inputHelper, isDropdownExtended]);

  const onChange = (newSelection) => {
    const travellerBaggageDetails = {
      travellerIdentifier: currentPassenger.travellerIdentifier,
      baggageOptionCode: newSelection.code,
    };
    onBaggageSelectionChanged(travellerBaggageDetails);
  };

  const onDropdownOptionClick = (option) => {
    if (
      typeof option === "object" &&
      !(option instanceof String) &&
      option !== null
    ) {
      setInputHelper(option);
      setInputValue(option.name);
      onChange({ name: option.name, code: option.code });
    } else {
      setInputValue(option);
      onChange(option);
    }
    setDropdownExtended(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setDropdownExtended(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [containerRef]);

  const baggageOptions = (currentPassenger) => {
    if (currentPassenger.type === ancillaryTypes.Baggage) {
      const options = currentPassenger.ancillaryServiceOptionsList.map(
        (item) => {
          return {
            name: `${item.displayName} - €${item.price}`,
            code: item.ancillaryOptionCode,
          };
        }
      );
      return options;
    }

    return [];
  };

  return (
    <div className="input-dropdown-container">
      {isLoading &&
        loadingPassengerId === currentPassenger.travellerIdentifier && (
          <CircularProgress
            className="button-loading-spinner extras-spinner"
            size={6}
          />
        )}
      <div className="form-input-dropdown-component" ref={containerRef}>
        <label
          className="label-description"
          onClick={() => !isLoading && setDropdownExtended(!isDropdownExtended)}
        >
          {currentPassenger && (
            <div
              className={`form-input-dropdown ${
                isLoading ? "extras-input" : ""
              } ${isDropdownExtended ? "custom-focus" : ""}`}
            >
              <div className="input-placeholder">{inputValue}</div>
              <div className="icon-arrow">
                <div
                  className={
                    isDropdownExtended ? "icon-drop-up" : "icon-drop-down"
                  }
                ></div>
              </div>
              {isDropdownExtended && (
                <ul className="dropdown">
                  {baggageOptions(currentPassenger).map((option, index) => (
                    <div className="dropdown-item-wrapper" key={option?.code}>
                      {GetAffiliate() === affiliates.klm &&
                        (option?.code === inputHelper?.code ||
                          option?.code === inputHelper?.baggageOptionCode) && (
                          <div className="dropdown-item-spacer selected"></div>
                        )}
                      <li
                        className={`${
                          option?.code === inputHelper?.code ||
                          option?.code === inputHelper?.baggageOptionCode
                            ? "dropdown-item-selected"
                            : "dropdown-item"
                        }`}
                        ref={
                          option?.code === inputHelper?.code
                            ? selectedOptionRef
                            : null
                        }
                        onClick={() => onDropdownOptionClick(option)}
                        key={option?.code}
                        value={option.code}
                      >
                        {option.name}
                      </li>
                    </div>
                  ))}
                </ul>
              )}
            </div>
          )}
        </label>
      </div>
    </div>
  );
};
