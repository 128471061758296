export const footer = {
    uniqueSellingPoints: "uniqueSellingPoints",
    contactUsLinks: "contactUsLinks",
    contactUsLogoLink: "contactUsLogoLink",
    conditionsLinks: "conditionsLinks",
    logoLinks: "logoLinks",
    textContent: "textContent",
    nodeTypes: {
        asset: "asset",
        heading: "heading",
        paragraph: "paragraph"
    }
};