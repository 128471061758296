import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import IconHandBaggageTransaviaNotActive from "../../assets/icons/transavia/handbaggage-not-active.svg";
import IconHandBaggageTransaviaActive from "../../assets/icons/transavia/handbaggage-icon.svg";
import IconCabinBaggageActive from "../../assets/icons/transavia/cabinbagge-icon-active.svg";
import IconCabinBaggageNotActive from "../../assets/icons/transavia/cabinbaggage-icon-not-active.svg";
import IconCheckedBaggageActive from "../../assets/icons/transavia/checkedBaggage-active.svg";
import IconCheckedBaggageNotActive from "../../assets/icons/transavia/checkedBaggage-not-active.svg";

import IconHandBaggageKLMNotActive from "../../assets/icons/klm/cabinbaggage-not-active-klm.svg";
import IconHandBaggageKLMActive from "../../assets/icons/klm/cabinbaggage-active-klm.svg";
import IconCabinBaggageKLMNotActive from "../../assets/icons/klm/cabin-baggage-not-active-klm.svg";
import IconCabinBaggageKLMActive from "../../assets/icons/klm/cabin-baggage-active-klm.svg";
import IconCheckedBaggageKLMNotActive from "../../assets/icons/klm/ruimbaggage-not-active-klm-icon.svg";
import IconCheckedBaggageKLMActive from "../../assets/icons/klm/ruimbaggage-klm-icon.svg";

import { GetAffiliate } from "../../services/general.service";
import { affiliates } from "../../common/enums/affiliates";
import { PassengerDetailsFormFields } from "../../common/constants/passengerDetailsFormFields";
import { travellerCount } from "../../common/enums/travellerCount";
import { apiGenderRepresentationEnum } from "../../common/enums/apiGenderRepresentationEnum";
import { homeStayerCount } from "../../common/enums/homeStayerCount";
import { flightAirlineOperator } from "../../common/enums/flightOptions";

import "./checkout-card.scss";

const CheckoutCard = ({
  titleTraveller,
  titleTravellerNotDriver,
  titleHomeStayer,
  titleMainBooker,
  dataTraveller,
  dataHomeStayer,
  dataMainBooker,
  checkFlightOption,
  travellersCount,
  contentfulPaymentPage,
  contentfulHolidayPage,
  travellerBaggageInfo,
}) => {
  const [baggageInfo, setBaggageInfo] = useState(null);
  const [isCabinBaggageEmpty, setIsCabinBaggageEmpty] = useState(false);

  const singleTraveller =
    dataTraveller.length === travellerCount.One ? "single-traveller" : "";
  const twoTravellers =
    dataTraveller.length === travellerCount.Two ? "two-travellers" : "";
  const groupTravellers =
    dataTraveller.length >= travellerCount.Six ? "group-travellers" : "";
  const checkFlightOptionCode =
    checkFlightOption?.airlineCode ??
    (checkFlightOption?.segments && checkFlightOption.segments.length > 0
      ? checkFlightOption.segments[0].airlineCode
      : null);

  useEffect(() => {
    if (travellerBaggageInfo) {
      setBaggageInfo(travellerBaggageInfo);
    }
  }, [travellerBaggageInfo]);

  useEffect(() => {
    if (baggageInfo && dataMainBooker) {
      const mainBookerTraveller =
        dataMainBooker?.travellerInfo?.travellers?.find(
          (traveller) => traveller.isMainBooker
        );

      const cabinBaggageProductForMainBooker =
        baggageInfo?.cabinBaggageProducts?.find(
          (cabinBaggage) =>
            cabinBaggage.travellerIdentifier.trim() ===
            mainBookerTraveller?.travellerIdentifier.trim()
        );

      setIsCabinBaggageEmpty(!cabinBaggageProductForMainBooker);
    }
  }, [baggageInfo, dataMainBooker]);

  const renderBaggageInfo = (travellerIdentifier) => {
    if (!baggageInfo || !baggageInfo.baggageProducts) {
      return;
    }

    const traveller = dataTraveller.find(
      (traveller) => traveller.travellerIdentifier === travellerIdentifier
    );

    const {
      showCabinBaggageLabel,
      handBaggageIncluded,
      cabinBaggageIncluded,
      paidBaggageIncluded,
      paidBaggageWeight,
    } = traveller.baggageOverview;

    const isInfant =
      traveller?.travellerType === PassengerDetailsFormFields.INFANT;
    const valueClassInfant = isInfant ? "disabled-baggage" : "";

    const holidayPage = contentfulHolidayPage && contentfulHolidayPage[0];

    return (
      <div className="baggage-info">
        <div className={`baggage-title ${valueClassInfant}`}>
          {holidayPage && holidayPage?.fields?.baggageTitle}
        </div>

        {/* Hand baggage section */}
        <div
          className={`hand-baggage-section ${valueClassInfant} ${
            GetAffiliate() === affiliates.klm &&
            checkFlightOptionCode === flightAirlineOperator.KLM_Flight
              ? "hand-baggage-klm"
              : "hand-baggage-other"
          }`}
        >
          {isInfant ? (
            <>
              {checkFlightOptionCode === flightAirlineOperator.KLM_Flight &&
              GetAffiliate() === affiliates.klm ? (
                <img
                  src={IconCabinBaggageKLMNotActive}
                  alt={holidayPage && holidayPage?.fields?.altImgBaggageActive}
                />
              ) : checkFlightOptionCode === flightAirlineOperator.KLM_Flight &&
                GetAffiliate() === affiliates.transavia ? (
                <img
                  src={IconCabinBaggageNotActive}
                  alt={holidayPage && holidayPage?.fields?.altImgBaggageActive}
                />
              ) : GetAffiliate() === affiliates.klm ? (
                <img
                  src={IconHandBaggageKLMNotActive}
                  alt={holidayPage && holidayPage?.fields?.altImgBaggageActive}
                />
              ) : (
                <img
                  src={IconHandBaggageTransaviaNotActive}
                  alt={holidayPage && holidayPage?.fields?.altImgBaggageActive}
                />
              )}
              {holidayPage && holidayPage?.fields?.none}{" "}
              {holidayPage && holidayPage?.fields?.handBaggageInfant}
            </>
          ) : (
            <>
              {handBaggageIncluded ? (
                <>
                  {GetAffiliate() === affiliates.klm &&
                  checkFlightOptionCode === flightAirlineOperator.KLM_Flight ? (
                    <img
                      src={IconCabinBaggageKLMActive}
                      alt={
                        holidayPage && holidayPage?.fields?.altImgBaggageActive
                      }
                    />
                  ) : checkFlightOptionCode ===
                      flightAirlineOperator.KLM_Flight &&
                    GetAffiliate() === affiliates.transavia ? (
                    <img
                      src={IconCabinBaggageActive}
                      alt={
                        holidayPage && holidayPage?.fields?.altImgBaggageActive
                      }
                    />
                  ) : GetAffiliate() === affiliates.klm ? (
                    <img
                      src={IconHandBaggageKLMActive}
                      alt={
                        holidayPage && holidayPage?.fields?.altImgBaggageActive
                      }
                    />
                  ) : (
                    <img
                      src={IconHandBaggageTransaviaActive}
                      alt={
                        holidayPage && holidayPage?.fields?.altImgBaggageActive
                      }
                    />
                  )}
                  {holidayPage && holidayPage?.fields?.handBaggage}
                </>
              ) : (
                <>
                  <img
                    src={IconHandBaggageTransaviaNotActive}
                    alt={
                      holidayPage && holidayPage?.fields?.altImgBaggageNotActive
                    }
                  />
                  {holidayPage && holidayPage?.fields?.none}{" "}
                  {holidayPage && holidayPage?.fields?.handBaggage}
                </>
              )}
            </>
          )}
        </div>

        {/* Cabin Baggage section */}
        {showCabinBaggageLabel && (
          <div
            className={`cabin-baggage-section ${valueClassInfant} ${
              cabinBaggageIncluded ? "baggage-active" : "disabled-baggage"
            }`}
          >
            {isInfant || !cabinBaggageIncluded ? (
              <>
                {GetAffiliate() === affiliates.klm ? (
                  <img
                    src={IconCabinBaggageKLMNotActive}
                    alt={
                      holidayPage && holidayPage?.fields?.altImgBaggageNotActive
                    }
                  />
                ) : (
                  <img
                    src={IconCabinBaggageNotActive}
                    alt={
                      holidayPage && holidayPage?.fields?.altImgBaggageNotActive
                    }
                  />
                )}
                {holidayPage && holidayPage?.fields?.none}{" "}
                {holidayPage && holidayPage?.fields?.cabinBaggage}
              </>
            ) : (
              <>
                {GetAffiliate() === affiliates.klm ? (
                  <img
                    src={IconCabinBaggageKLMActive}
                    alt={
                      holidayPage && holidayPage?.fields?.altImgBaggageActive
                    }
                  />
                ) : (
                  <img
                    src={IconCabinBaggageActive}
                    alt={
                      holidayPage && holidayPage?.fields?.altImgBaggageActive
                    }
                  />
                )}
                {holidayPage && holidayPage?.fields?.cabinBaggage}
              </>
            )}
          </div>
        )}

        {/* Checked baggage section */}
        <div className={`checked-baggage-section ${valueClassInfant}`}>
          {paidBaggageIncluded ? (
            <div
              className={
                paidBaggageIncluded ? "baggage-active" : "disabled-baggage"
              }
            >
              <img
                src={
                  GetAffiliate() === affiliates.klm
                    ? paidBaggageIncluded
                      ? IconCheckedBaggageKLMActive
                      : IconCheckedBaggageKLMNotActive
                    : paidBaggageIncluded
                    ? IconCheckedBaggageActive
                    : IconCheckedBaggageNotActive
                }
                alt={holidayPage && holidayPage?.fields?.altImgBaggageActive}
              />
              {holidayPage && holidayPage?.fields?.ruimBaggage}
              {paidBaggageWeight > 0 && (
                <>
                  {" "}
                  ({paidBaggageWeight}
                  {holidayPage && holidayPage?.fields?.metricWeight})
                </>
              )}
            </div>
          ) : (
            <div className="disabled-baggage">
              <img
                src={
                  GetAffiliate() === affiliates.klm
                    ? IconCheckedBaggageKLMNotActive
                    : IconCheckedBaggageNotActive
                }
                alt={holidayPage && holidayPage?.fields?.altImgBaggageNotActive}
              />
              {holidayPage && holidayPage?.fields?.none}{" "}
              {holidayPage && holidayPage?.fields?.ruimBaggage}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderMainBooker = () => {
    const mainBookerTraveller = dataMainBooker?.travellerInfo?.travellers?.find(
      (traveller) => traveller.isMainBooker
    );
    const mainBookerContact = dataMainBooker?.contactDetailInfo?.mainBooker;

    return (
      <div
        className={`mainbooker ${
          travellersCount === travellerCount.One
            ? "mainbooker-inline"
            : "moved-down"
        } ${remainingTravellers.length >= 3 ? "moved-down-multi" : ""} ${
          isCabinBaggageEmpty ? "no-cabin-mainbooker" : ""
        } ${
          GetAffiliate() === affiliates.klm &&
          checkFlightOptionCode === flightAirlineOperator.KLM_Flight
            ? "moved-down-multi-klm"
            : ""
        }`}
      >
        <div className="title-container">{titleMainBooker}</div>
        <div className="mainbooker-container">
          <div className="data-mainbooker-info">
            <p className="title-item">
              {contentfulPaymentPage &&
                contentfulPaymentPage[0]?.fields?.address}
            </p>
            <div className="name">
              {mainBookerTraveller?.gender === apiGenderRepresentationEnum.MALE
                ? contentfulPaymentPage && contentfulPaymentPage[0]?.fields?.mr
                : contentfulPaymentPage &&
                  contentfulPaymentPage[0]?.fields?.ms}{" "}
              {`${mainBookerTraveller?.firstName || ""} ${
                mainBookerTraveller?.lastName || ""
              }`.trim()}
            </div>
            <div className="address">
              {mainBookerContact?.address.street}{" "}
              {mainBookerContact?.address.houseNumber}
              {mainBookerContact?.address.houseNumberExtension
                ? `-${mainBookerContact?.address.houseNumberExtension}`
                : ""}
            </div>
            <div className="zip-code">
              {mainBookerContact?.address.zipCode}{" "}
              {mainBookerContact?.address.city}
            </div>
          </div>
          <div className="data-info-container">
            <div className="mobile-info">
              <p className="title-item">
                {contentfulPaymentPage &&
                  contentfulPaymentPage[0]?.fields?.mobileNumber}
              </p>
              <div className="phone">
                {mainBookerContact?.phoneNumberCountryCode}{" "}
                {mainBookerContact?.phoneNumberSuffix}
              </div>
            </div>
            <div className="email-info">
              <p className="title-item">
                {contentfulPaymentPage &&
                  contentfulPaymentPage[0]?.fields?.mail}
              </p>
              <div className="email">{mainBookerContact?.email}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderHomeStayer = () => {
    return (
      <div
        className={`homestayer ${
          dataHomeStayer.length === homeStayerCount.MULTI ||
          dataHomeStayer.length === homeStayerCount.SINGLE
            ? "block-display"
            : "inline"
        } ${twoTravellers} ${groupTravellers} ${
          GetAffiliate() === affiliates.klm &&
          checkFlightOptionCode === flightAirlineOperator.KLM_Flight
            ? ""
            : "moved-down"
        } ${
          remainingTravellers.length >= travellerCount.Three
            ? "moved-down-multi"
            : ""
        } ${
          GetAffiliate() === affiliates.klm &&
          checkFlightOptionCode === flightAirlineOperator.KLM_Flight
            ? "moved-down-multi-klm"
            : ""
        } ${isCabinBaggageEmpty ? "no-cabin-mainbooker" : ""}`}
      >
        <div className="title-container">{titleHomeStayer}</div>
        <div className="name">
          {`${dataHomeStayer.firstName || ""} ${
            dataHomeStayer.lastName || ""
          }`.trim()}
        </div>
        <div className="phone">
          {dataHomeStayer.phoneNumberCountryCode}{" "}
          {dataHomeStayer.phoneNumberSuffix}
        </div>
      </div>
    );
  };

  const firstTraveller = dataTraveller ? dataTraveller[0] : "";
  const remainingTravellers = dataTraveller?.slice(1);

  return (
    <div
      className={`information-container ${singleTraveller} ${twoTravellers}`}
    >
      <div className="traveller-layout-container">
        <div className="traveller-layout">
          <div className="travellers-left-side">
            <div className="travellers-left-side-box">
              {firstTraveller && (
                <div
                  className={`first-traveller-container ${
                    checkFlightOptionCode === flightAirlineOperator.KLM_Flight
                      ? "container-klm"
                      : ""
                  }`}
                >
                  <div className="traveller">
                    <div className="title-container">
                      {firstTraveller.isDriver
                        ? titleTraveller
                        : titleTravellerNotDriver}
                    </div>
                    <div className="name">
                      {firstTraveller.gender ===
                      apiGenderRepresentationEnum.MALE
                        ? contentfulPaymentPage &&
                          contentfulPaymentPage[0]?.fields?.mr
                        : contentfulPaymentPage &&
                          contentfulPaymentPage[0]?.fields?.ms}{" "}
                      {`${firstTraveller.firstName || ""} ${
                        firstTraveller.lastName || ""
                      }`.trim()}
                    </div>
                    <div className="dob">
                      {firstTraveller.dateOfBirthDisplay}
                    </div>
                    <div className="passenger-type">
                      {firstTraveller.travellerType ===
                      PassengerDetailsFormFields.ADULT
                        ? contentfulPaymentPage &&
                          contentfulPaymentPage[0]?.fields?.adult
                        : firstTraveller.travellerType ===
                          PassengerDetailsFormFields.CHILD
                        ? contentfulPaymentPage &&
                          contentfulPaymentPage[0]?.fields?.child
                        : PassengerDetailsFormFields.INFANT
                        ? contentfulHolidayPage &&
                          contentfulHolidayPage[0]?.fields?.infant
                        : null}
                      {firstTraveller.isMainBooker ? "," : ""}{" "}
                      <span>
                        {firstTraveller.isMainBooker
                          ? contentfulPaymentPage &&
                            contentfulPaymentPage[0]?.fields?.mainBooker
                          : ""}
                      </span>
                    </div>
                    {renderBaggageInfo(firstTraveller.travellerIdentifier)}
                  </div>
                </div>
              )}
              <div className="booker-with-stayer">
                {renderMainBooker()}
                <div
                  className={`${
                    remainingTravellers.length <= travellerCount.Two
                      ? "not-group-box"
                      : ""
                  }`}
                ></div>
              </div>
            </div>
            <div className="home-stayer-container-more">
              {groupTravellers && renderHomeStayer()}
            </div>
          </div>

          <div className="travellers-right-side">
            {remainingTravellers.length > travellerCount.Zero && (
              <div className="remaining-travellers-container">
                <div className="traveller-grid">
                  {remainingTravellers.map((traveller) => (
                    <div
                      key={traveller.travellerIdentifier}
                      className={`traveller ${
                        remainingTravellers.length < travellerCount.Two
                          ? "not-group"
                          : ""
                      }`}
                    >
                      <div className="title-container">
                        {contentfulPaymentPage &&
                          contentfulPaymentPage[0]?.fields?.traveller}{" "}
                        {traveller.travellerNumber}
                      </div>
                      <div className="name">
                        {traveller.gender === apiGenderRepresentationEnum.MALE
                          ? `${
                              contentfulPaymentPage &&
                              contentfulPaymentPage[0]?.fields?.mr
                            }`
                          : `${
                              contentfulPaymentPage &&
                              contentfulPaymentPage[0]?.fields?.ms
                            }`}{" "}
                        {`${traveller.firstName || ""} ${
                          traveller.lastName || ""
                        }`.trim()}
                      </div>
                      <div className="dob">{traveller.dateOfBirthDisplay}</div>
                      <div className="passenger-type">
                        {traveller.travellerType ===
                        PassengerDetailsFormFields.ADULT
                          ? contentfulPaymentPage &&
                            contentfulPaymentPage[0]?.fields?.adult
                          : traveller.travellerType ===
                            PassengerDetailsFormFields.CHILD
                          ? contentfulPaymentPage &&
                            contentfulPaymentPage[0]?.fields?.child
                          : traveller.travellerType ===
                            PassengerDetailsFormFields.INFANT
                          ? contentfulHolidayPage &&
                            contentfulHolidayPage[0]?.fields?.infant
                          : null}
                        {traveller.isMainBooker ? "," : ""}{" "}
                        <span>
                          {traveller.isMainBooker
                            ? contentfulPaymentPage &&
                              contentfulPaymentPage[0]?.fields?.mainBooker
                            : ""}
                        </span>
                      </div>
                      {renderBaggageInfo(traveller.travellerIdentifier)}
                    </div>
                  ))}
                  <div
                    className={`home-stayer-container-less ${singleTraveller} ${twoTravellers}`}
                  >
                    {renderHomeStayer()}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {!groupTravellers && (
        <div
          className={`home-stayer-container ${singleTraveller} ${twoTravellers}`}
        >
          {renderHomeStayer()}
        </div>
      )}
    </div>
  );
};

CheckoutCard.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  typeTraveller: PropTypes.string,
  typeHomeStayer: PropTypes.string,
  typeMainBooker: PropTypes.string,
  dataTraveller: PropTypes.array,
  dataHomeStayer: PropTypes.object,
  dataMainBooker: PropTypes.object,
  travellersCount: PropTypes.number,
  contentfulPaymentPage: PropTypes.array,
  contentfulHolidayPage: PropTypes.array,
  travellerBaggageInfo: PropTypes.object,
};

export default CheckoutCard;
