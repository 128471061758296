import "./trip-advisor-rating.styles.scss"

export const TripAdvisorRatingComponent = ({rating}) => {
    const ratingContainer = [];

    for (let i = 0; i < 5; i++) {
        if (i < rating) {
            if (i === Math.floor(rating)) {
                const fractionalFill = rating - Math.floor(rating);
                ratingContainer.push(
                    <div key={i} className="fractional-rating-container">
                        <div className="fractional-border"></div>
                        <div className="dot fractional-filled" style={{'--fractional-fill': fractionalFill}}
                        ></div>
                    </div>
                )
                ;
            } else {
                ratingContainer.push(<div className="dot filled" key={i}></div>);
            }
        } else {
            ratingContainer.push(<div className="dot empty" key={i}></div>);
        }
    }

    return <div className="tripadvisor-rating-component">{ratingContainer}</div>;
};




