import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import "./car-feature-description.component.scss";
import { Alert } from "@mui/material";
import checkMarkKLM from "../../../../assets/icons/check-S.svg";
import checkMarkTransavia from "../../../../assets/icons/transavia/Icons_Small_Check.svg";
import {
  formatContentfulImageUrl,
  renderDocument,
} from "../../../../services/contentful.service";
import { affiliates } from "../../../../common/enums/affiliates";
import { GetAffiliate } from "../../../../services/general.service";

export const CarFeatureDescription = ({ contentfulCarComponent }) => {
  return (
    <div className="car-feature-description-component">
      <div className="car-features-section">
        <div className="car-company-header">
          <div>
            {contentfulCarComponent &&
              renderDocument(contentfulCarComponent[0].fields.carCompanyHeader)}
          </div>
          <img
            src={formatContentfulImageUrl(
              contentfulCarComponent &&
                contentfulCarComponent[0].fields.carCompanyImage.fields.file.url
            )}
            alt=""
          />
        </div>
        <div className="usp-container">
          <div className="usp-item">
            <img
              className="check-img"
              src={
                GetAffiliate() === affiliates.klm
                  ? checkMarkKLM
                  : checkMarkTransavia
              }
              alt="check_mark"
            />
            <div className="usp-description">
              {contentfulCarComponent &&
                contentfulCarComponent[0].fields?.carUspAllInclusive}
            </div>
          </div>
          <div className="usp-item">
            <img
              className="check-img"
              src={
                GetAffiliate() === affiliates.klm
                  ? checkMarkKLM
                  : checkMarkTransavia
              }
              alt="check_mark"
            />
            <div className="usp-description">
              {contentfulCarComponent &&
                contentfulCarComponent[0].fields?.carUspFreeCancellation}
            </div>
          </div>
          <div className="usp-item">
            <img
              className="check-img"
              src={
                GetAffiliate() === affiliates.klm
                  ? checkMarkKLM
                  : checkMarkTransavia
              }
              alt="check_mark"
            />
            <div className="usp-description">
              {contentfulCarComponent &&
                contentfulCarComponent[0].fields?.carUspNoHassle}
            </div>
          </div>
        </div>
      </div>
      <div className="car-feature-heading-description">
        <Alert className="car-feature-information-description" severity="info">
          {contentfulCarComponent &&
            contentfulCarComponent[0].fields.carCompanyDisclaimer}
        </Alert>
      </div>
    </div>
  );
};
