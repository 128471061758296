import {SessionStorageEnum} from "../common/enums/sessionStorageEnum";

export const SessionStorageService = {
    setSessionId: (value) => {
        sessionStorage.setItem(SessionStorageEnum.SESSION_ID, value);
    },
    getSessionId: () => {
        return sessionStorage.getItem(SessionStorageEnum.SESSION_ID) ?? "";
    },
    setSessionStorageItem: (key, value) => {
        return sessionStorage.setItem(key, value) ?? "";
    },
    getSessionStorageItem: (key) => {
        return sessionStorage.getItem(key) ?? "";
    }
}