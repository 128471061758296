import { useEffect, useRef, useState } from "react";
import { Accordion } from "../../accordion/accordion";
import "./hotel-name-filter.component.scss";
import { JsKeyCodes } from "../../../common/enums/jsKeyCodes";

export const HotelNameFilterComponent = ({
  contentfulFilters,
  data,
  isMobile,
  setGenericFilter,
}) => {
  const containerRef = useRef(null);
  const [apiHotelNameFilterOptions, setStateApiHotelNameFilterOptions] =
    useState([]);
  const [
    filteredHotelNameFilterOptions,
    setStateFilteredHotelNameFilterOptions,
  ] = useState(null);
  const [showHotelNameFilterOptions, setStateShowHotelNameFilterOptions] =
    useState(false);
  const [filterInputValue, setStateFilterInputValue] = useState("");
  const [requestedFilters, setStateRequestedFilters] = useState(null);

  useEffect(() => {
    setStateApiHotelNameFilterOptions(
      data?.filterInfo?.filterOptions?.hotelNameFilterOptions
    );
    setStateFilteredHotelNameFilterOptions(
      filterHotelNameFilterOptions(filterInputValue, apiHotelNameFilterOptions)
    );
    setStateRequestedFilters(data?.filterInfo?.requestedFilters);
    if (
      !data?.filterInfo?.requestedFilters ||
      (data?.filterInfo?.requestedFilters &&
        !data?.filterInfo?.requestedFilters.hotelName)
    ) {
      setStateFilterInputValue("");
    }
  }, [data, requestedFilters, apiHotelNameFilterOptions]);

  const setFilterInputValue = (input) => {
    if (typeof input === "string") {
      setStateFilterInputValue(input);
      setStateShowHotelNameFilterOptions(false);
      setStateFilteredHotelNameFilterOptions(
        filterHotelNameFilterOptions(input, apiHotelNameFilterOptions)
      );
      return [{ hotelName: input }];
    } else {
      if (input) {
        setStateFilterInputValue(input.target.value);
        setStateFilteredHotelNameFilterOptions(
          filterHotelNameFilterOptions(
            input.target.value,
            apiHotelNameFilterOptions
          )
        );
        return [{ hotelName: input.target.value }];
      } else {
        return [{ hotelName: input }];
      }
    }
  };

  const filterHotelNameFilterOptions = (input, collection) => {
    const output = collection
      ? collection.filter(
          (e) => e.toLowerCase().indexOf(input.toLowerCase()) !== -1
        )
      : [];
    return output;
  };

  const clearFilter = () => {
    setStateFilterInputValue("");
  };

  useEffect(() => {
    if (!isMobile) {
      const handleClickOutside = (event) => {
        if (
          containerRef.current &&
          !containerRef.current.contains(event.target)
        ) {
          setStateShowHotelNameFilterOptions(false);
        }
      };
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }
  }, [containerRef]);

  useEffect(() => {
    if (filterInputValue.length && filteredHotelNameFilterOptions.length)
      setStateShowHotelNameFilterOptions(true);
    else setStateShowHotelNameFilterOptions(false);
  }, [filterInputValue]);

  return (
    <div className="hotel-name-filter-component-container">
      <Accordion
        startingExpandedState="false"
        labelText={
          contentfulFilters
            ? contentfulFilters[0]?.fields?.hotelName
            : "Hotel name"
        }
      >
        <div className="filter-input-container">
          <input
            ref={containerRef}
            placeholder={
              contentfulFilters
                ? contentfulFilters[0]?.fields?.findAHotel
                : "Find a hotel"
            }
            value={filterInputValue}
            onChange={(e) => setFilterInputValue(e)}
            onClick={
              filterInputValue.length
                ? () =>
                    setStateShowHotelNameFilterOptions(
                      !showHotelNameFilterOptions
                    )
                : null
            }
            onKeyDown={(e) => {
              if (e.key === JsKeyCodes.Esc && showHotelNameFilterOptions)
                setStateShowHotelNameFilterOptions(false);
            }}
          />
          {Boolean(filterInputValue) ? (
            <div
              className="clear-filter"
              onClick={() => [
                clearFilter(),
                setGenericFilter(setFilterInputValue(null)),
              ]}
            />
          ) : null}
        </div>

        {showHotelNameFilterOptions ? (
          <div
            className={
              filteredHotelNameFilterOptions?.length > 0
                ? "hotel-name-filter-component-content show-border"
                : "hotel-name-filter-component-content"
            }
          >
            {filteredHotelNameFilterOptions?.map((element, index) => {
              return (
                <div
                  key={index}
                  className="hotel-name"
                  onClick={() => [
                    setFilterInputValue(element),
                    setGenericFilter(setFilterInputValue(element)),
                  ]}
                >
                  {element}
                </div>
              );
            })}
          </div>
        ) : null}
      </Accordion>
    </div>
  );
};
