import React from "react";
import "./tooltip.scss";

const Tooltip = ({ header, body }) => {
  return (
    <div className="tooltip-summary">
      <div className="tooltip-arrow"></div>
      <div className="tooltip-header">{header}</div>
      <hr className="tooltip-line" />
      <div className="tooltip-body">{body}</div>
    </div>
  );
};

export default Tooltip;
