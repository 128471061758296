import { useEffect, useState } from "react";
import { Accordion } from "../../accordion/accordion";
import Slider from "@mui/material/Slider";
import './distance-to-center-filter.component.scss';

export const DistanceToCenterFilterComponent = ({
  contentfulFilters,
  data,
  setGenericFilter,
}) => {
  const [apiDefaultDistanceToCenter, setStateApiDefaultDistanceToCenter] = useState([0, 0]);
  const [sliderDistanceToCenter, setStateSliderDistanceToCenter] = useState(0);

  useEffect(() => {
    setStateApiDefaultDistanceToCenter([
      data?.filterInfo?.filterOptions?.distanceToCenterFilterOptions?.minValue,
      data?.filterInfo?.filterOptions?.distanceToCenterFilterOptions?.maxValue
    ]);

    setStateSliderDistanceToCenter(
      data?.filterInfo?.requestedFilters?.maxDistanceToCenter
        ? data?.filterInfo?.requestedFilters?.maxDistanceToCenter
        : data?.filterInfo?.filterOptions?.distanceToCenterFilterOptions?.maxValue
    );
  }, [data]);

  const handleOnChangeEvent = (event, value) => {
    setStateSliderDistanceToCenter(value)
  };

  const handleOnChangeCommittedEvent = (event, value) => {
    setGenericFilter([{ maxDistanceToCenter: value }]);
  };

  return (
    <div className="distance-to-center-filter-component-container">
      <Accordion
        startingExpandedState="false"
        labelText={
          contentfulFilters
            ? contentfulFilters[0]?.fields?.distanceToCenter
            : "Distance to center"
        }
      >
        <div
          className="filter-options-container"
        >
          <Slider
            min={apiDefaultDistanceToCenter[0]}
            max={apiDefaultDistanceToCenter[1]}
            value={sliderDistanceToCenter}
            onChange={handleOnChangeEvent}
            onChangeCommitted={handleOnChangeCommittedEvent}
            valueLabelDisplay="on"
            valueLabelFormat={(value) => `${value} km`}
            step={1}
            sx={{ mt: 6 }}
          />
        </div>
        <div className="price-container">
          <div className="price-box">
            <div className="price-container-values">{apiDefaultDistanceToCenter[0]} km</div>
            <div className="price-container-values">{apiDefaultDistanceToCenter[1]} km</div>
          </div>
        </div>
      </Accordion>
    </div>
  );
};
