import { useEffect, useState } from "react";
import { TripadvisorRatingComponent } from "../tripadvisor-rating/tripadvisor-rating.component";
import { GetAffiliate } from "../../services/general.service";
import { StarRatingComponent } from "../star-rating/star-rating.component";
import { Carousel } from "react-responsive-carousel";
import { LocalStorageEnum } from "../../common/enums/localStorageEnum";
import { JsKeyCodes } from "../../common/enums/jsKeyCodes";
import { ReactComponent as ButtonClose } from "../../assets/icons/klm/button-close-images.svg";
import config from "../../environments/config.json";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./holiday-package-hotel-images.component.scss";
import { useWindowSizeDetector } from "../../hooks/useWindowSizeDetector";

export const HolidayPackageOptionsHotelImagesComponent = ({
  hotelInfo,
  contentfulHolidayPage,
}) => {
  const [apiHotelInfo, setStateApiHotelInfo] = useState(null);
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const windowSize = useWindowSizeDetector();

  useEffect(() => {
    setStateApiHotelInfo(hotelInfo);
  }, [hotelInfo]);

  useEffect(() => {
    if (isCarouselOpen) {
      setControlsPosition();
    }
  }, [isCarouselOpen]);

  useEffect(() => {
    if (isCarouselOpen) {
      setControlsPosition();
    }
  }, [windowSize]);

  useEffect(() => {
    if (hotelInfo) {
      localStorage.setItem(
        LocalStorageEnum.HOTEL_DATA,
        JSON.stringify(hotelInfo)
      );
      setStateApiHotelInfo(hotelInfo);
    } else {
      const savedHotelData = localStorage.getItem(LocalStorageEnum.HOTEL_DATA);
      if (savedHotelData) {
        setStateApiHotelInfo(JSON.parse(savedHotelData));
      }
    }
  }, [hotelInfo]);

  useEffect(() => {
    const closeCarouselOnEsc = (e) => {
      if (e.key === JsKeyCodes.Esc) {
        closeCarousel();
      }
    };

    document.addEventListener("keydown", closeCarouselOnEsc);

    return () => {
      document.removeEventListener("keydown", closeCarouselOnEsc);
    };
  }, [isCarouselOpen]);

  const openCarousel = (index) => {
    window.scrollTo(0, 0);
    setSelectedImageIndex(index);
    setIsCarouselOpen(true);
    document.body.style.overflow = "hidden";
    document.documentElement.style.overflowY = "hidden";

    document.body.style.scrollbarWidth = "none";
    document.body.style.msOverflowStyle = "none";
    document.body.style.overflowY = "scroll";
    document.body.style.setProperty(
      "::-webkit-scrollbar",
      "display: none",
      "important"
    );
  };

  const closeCarousel = () => {
    setIsCarouselOpen(false);
    const prevButtonDefaultPosition = "70px";
    const nextButtonDefaultPosition = "20px";
    document.body.style.overflow = "auto";
    document.documentElement.style.overflowY = "auto";
    document.body.style.scrollbarWidth = "auto";
    document.body.style.msOverflowStyle = "auto";
    document.body.style.overflowY = "auto";
    document.body.style.removeProperty("::-webkit-scrollbar");
    document.documentElement.style.setProperty(
      "--control-prev-position",
      prevButtonDefaultPosition
    );
    document.documentElement.style.setProperty(
      "--control-next-position",
      nextButtonDefaultPosition
    );
  };

  const setControlsPosition = () => {
    const imageContainer = document.querySelector(
      ".carousel-slider .slider-wrapper .selected div"
    );
    const imageContainerRect = imageContainer.getBoundingClientRect();
    const prevButtonExtraMargin = 42;
    const nextButtonExtraMargin = -8;
    const controlPrevPosition = imageContainerRect.left + prevButtonExtraMargin;
    document.documentElement.style.setProperty(
      "--control-prev-position",
      controlPrevPosition + "px"
    );

    const controlNextPosition =
      window.innerWidth - imageContainerRect.right + nextButtonExtraMargin;
    document.documentElement.style.setProperty(
      "--control-next-position",
      controlNextPosition + "px"
    );
  };

  return (
    <div className="holiday-package-hotel-images-component">
      <div className="hotel-info">
        <div className="header">{apiHotelInfo?.name}</div>
        <div className="content">
          <div className="rating">
            <div className="star-rating-container">
              {apiHotelInfo?.rating?.starRating ? (
                <StarRatingComponent data={apiHotelInfo.rating.starRating} />
              ) : null}
            </div>
            <div className="tripadvisor-rating-container">
              {apiHotelInfo?.rating?.tripAdvisorRating ? (
                <>
                  <div className="tripadvisor-logo-container"></div>
                  <TripadvisorRatingComponent
                    data={apiHotelInfo.rating.tripAdvisorRating}
                  />
                </>
              ) : null}
            </div>
          </div>
          <div className="location">
            <div className="flag-container">
              <img
                alt={apiHotelInfo?.location?.countryCode}
                src={`${
                  config.COUNTRY_FLAGS
                }/${apiHotelInfo?.location?.countryCode.toLowerCase()}.png`}
              />
            </div>
            <div className="text-container">
              {apiHotelInfo?.location?.countryDescription}
              &nbsp;-&nbsp;
              {apiHotelInfo?.location?.city}
            </div>
          </div>
        </div>
      </div>
      <div className="hotel-images">
        <div className="main">
          {apiHotelInfo?.images ? (
            <img
              src={apiHotelInfo?.images[0]}
              onClick={() => openCarousel(0)}
            />
          ) : null}
        </div>
        <div className="tiles">
          {apiHotelInfo?.images
            ? apiHotelInfo?.images.slice(1, 4).map((element, index) => {
                return (
                  <div key={index} className="tile">
                    <img
                      src={element}
                      onClick={() => openCarousel(index + 1)}
                    />
                  </div>
                );
              })
            : null}

          <div className="tile">
            <img src={apiHotelInfo?.images[5]} />
            <div className="overlay" onClick={() => openCarousel(5)}></div>
            <div className="text" onClick={() => openCarousel(5)}>
              {contentfulHolidayPage &&
                contentfulHolidayPage[0]?.fields?.viewPhotos}
            </div>
          </div>
        </div>
      </div>
      {isCarouselOpen && (
        <>
          <div className="carousel-modal">
            <ButtonClose
              className="close-carousel-btn"
              onClick={closeCarousel}
            />
          </div>
          <Carousel
            selectedItem={selectedImageIndex}
            showThumbs={true}
            thumbWidth={180}
            useKeyboardArrows={true}
            centerMode={true}
            centerSlidePercentage={100}
          >
            {apiHotelInfo?.images.map((image, index) => (
              <div key={index}>
                <img src={image} alt={`Hotel Image ${index + 1}`} />
              </div>
            ))}
          </Carousel>
        </>
      )}
    </div>
  );
};
