export const breakpoints = {
    XXS: 1,
    XS: 361,
    XS400: 400,
    SM: 576,
    SM645: 645,
    MD750: 750,
    MD: 769,
    LG: 993,
    LG1100: 1100,
    XL: 1201,
    XXL: 1441,
    XXXL: 1921,
}
