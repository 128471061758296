import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GetApiUrl } from "../../services/general.service";

export const getCheckOutDetailsCart = createAsyncThunk(
  "checkOut/getCheckOutDetailsCart",
  async (holidayKey) => {
    const config = {
      headers: {
        pathname: window.location.pathname
      }
    }
    const response = await axios.get(`${GetApiUrl()}/CheckOut/${holidayKey}`, config);
    return response.data;
  }
);

export const updateTravelerAndContact = createAsyncThunk(
  "checkOut/updateTravelerAndContact",
  async ({ holidayKey, data }) => {
    const response = await axios.put(
      `${GetApiUrl()}/CheckOut/${holidayKey}/TravellerAndContactUpdate`,
      data
    );
    return response;
  }
);

export const selectInsurance = createAsyncThunk(
  "checkOut/SelectInsurance",
  async ({ holidayKey, data }) => {
    const response = await axios.put(
      `${GetApiUrl()}/CheckOut/${holidayKey}/SelectInsurance`,
      data
    );
    return response;
  }
);

export const approveInsuranceConditions = createAsyncThunk(
  "checkOut/ApproveInsuranceConditions",
  async ({ holidayKey, data }) => {
    const response = await axios.put(
      `${GetApiUrl()}/CheckOut/${holidayKey}/ApproveInsuranceConditions`,
      data
    );
    return response;
  }
);

export const updateBaggageSelection = async (holidayKey, ancillaryUpdateRequest) => {
  try {
    const response = await axios.put(
      `${GetApiUrl()}/CheckOut/${holidayKey}/AncillaryUpdate`, 
      ancillaryUpdateRequest
    );
    return response.data;
  } catch (error) {
    console.error(error.response.data); 
    throw error;
  }
};

export const checkOutSlice = createSlice({
  name: "checkOut",
  initialState: {
    checkOutData: null,
    updateResponse: null,
  },
  reducers: {
    updateCheckOutDataReducer: (state, action) => {
      state.checkOutData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCheckOutDetailsCart.fulfilled, (state, action) => {
        state.checkOutData = action.payload;
      })
      .addCase(updateTravelerAndContact.fulfilled, (state, action) => {
        state.updateResponse = action.payload;
      })
      .addCase(selectInsurance.fulfilled, (state, action) => {
        state.checkOutData = action.payload;
      })
      .addCase(approveInsuranceConditions.fulfilled, (state, action) => {
        state.checkOutData = action.payload;
      });
  },
});

export const { updateCheckOutDataReducer } = checkOutSlice.actions;
export default checkOutSlice;
