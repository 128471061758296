import {useEffect, useState} from "react";
import './pagination.styles.scss'
import { pagination } from "./../../common/constants/pagination";

export const PaginationComponent = ({
    holidayFullData,
    updatePaginationNumber,
    pageAndSortInfo
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [numberOfPaginationPages, setNumberOfPaginationPages] = useState(0)

    useEffect(() => {
        setNumberOfPaginationPages(holidayFullData?.data?.pageAndSortInfo?.totalPages)
    }, [holidayFullData, numberOfPaginationPages]);

    useEffect(() => {
        if (pageAndSortInfo && pageAndSortInfo.currentPage) {
            setCurrentPage(pageAndSortInfo && pageAndSortInfo.currentPage);
        }
    }, [pageAndSortInfo]);

    const goToFirstPage = () => {
        updatePaginationNumber(1);
    };

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            updatePaginationNumber(currentPage - 1);
        }
    };

    const goToNextPage = () => {
        if (currentPage < numberOfPaginationPages) {
            updatePaginationNumber(currentPage + 1)
        }
    };

    const goToLastPage = () => {
        updatePaginationNumber(numberOfPaginationPages);
    };

    const handleOnClickPaginationNumber = (paginationNumber) => {
        updatePaginationNumber(paginationNumber);
    };

    const paginationNumbers = () => {
        const paginationPagesNumeration = Array.from({length: numberOfPaginationPages}, (_, i) => i + 1)
        if (currentPage <= pagination.range - 1) {
            return paginationPagesNumeration.slice(0, pagination.range)
        } else if (currentPage >= numberOfPaginationPages - 1) {
            return paginationPagesNumeration.slice(numberOfPaginationPages - pagination.range, numberOfPaginationPages)
        } else {
            return paginationPagesNumeration.slice(currentPage - 2, currentPage + 1)
        }
    };

    return (
        <div className="pagination-component">
            <button
                className={`pagination-first-page-button button-effects ${currentPage === 1 ? 'pagination-button-disabled' : ''}`}
                onClick={goToFirstPage} disabled={currentPage === 1}>
                <div
                    className="pagination-first-page-button-icon pagination-first-page-disabled-icon"></div>
            </button>
            <button
                className={`pagination-previous-button button-effects ${currentPage === 1 ? 'pagination-button-disabled' : ''}`}
                onClick={goToPreviousPage}>
                <div className="pagination-previous-button-icon"></div>
            </button>
            <div className="pagination-numbers">
                {paginationNumbers().map(paginationNumber=>
                    <button key={paginationNumber}
                            className={`pagination-number ${currentPage === paginationNumber ? 'pagination-selected' : ''}`}
                            onClick={() => handleOnClickPaginationNumber(paginationNumber)}>{paginationNumber}
                    </button>)}
            </div>
            <button
                className={`pagination-next-button button-effects ${currentPage === numberOfPaginationPages ? 'pagination-button-disabled' : ''}`}
                onClick={goToNextPage}>
                <div className="pagination-next-button-icon"></div>
            </button>
            <button
                className={`pagination-last-page-button button-effects
                                        ${currentPage === numberOfPaginationPages ?
                    'pagination-button-disabled' : ''}`}
                onClick={() => goToLastPage(numberOfPaginationPages - 1)}
                disabled={currentPage === numberOfPaginationPages}>
                <div className="pagination-last-page-button-icon"></div>
            </button>
        </div>
    );
}
