import "./email-input.component.scss";
import { Controller } from "react-hook-form";
import { PassengerDetailsFormFields } from "../../../../../common/constants/passengerDetailsFormFields";
import { ValidationPattern } from "../../../../../common/enums/validationRulesEnum";
import { InputComponent } from "../../../../../components/input-component/input-component";
import React from "react";

export const EmailInputComponent = ({
  name,
  defaultEmail,
  control,
  errors,
  contentfulCheckoutPassengerDetails,
  contentfulCheckoutFormValidators,
}) => {
  const { email: emailError } = errors[name] ?? "";

  return (
    <label className="email-input-component">
      <Controller
        name={`${name}.${PassengerDetailsFormFields.EMAIL}`}
        control={control}
        defaultValue={defaultEmail}
        rules={{
          required: {
            value: true,
            message:
              contentfulCheckoutFormValidators &&
              contentfulCheckoutFormValidators[0]?.fields?.emailRequired,
          },
          pattern: {
            value: ValidationPattern.EMAIL_RULE,
            message:
              contentfulCheckoutFormValidators &&
              contentfulCheckoutFormValidators[0]?.fields?.emailInvalid,
          },
        }}
        render={({ field, fieldState }) => (
          <div className="email-container">
            <InputComponent
              label={
                contentfulCheckoutPassengerDetails &&
                contentfulCheckoutPassengerDetails[0]?.fields?.email
              }
              name={`${name}.${PassengerDetailsFormFields.EMAIL}`}
              defaultValue=""
              value={field.value}
              onChange={field.onChange}
              isError={fieldState.error}
              inputMode="text"
              ref={field.ref}
            />
          </div>
        )}
      />
      {emailError && (
        <div className="error-message-placeholder">
          <span className="error error-message">{emailError?.message}</span>
        </div>
      )}
    </label>
  );
};
