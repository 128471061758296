import { useEffect, useState } from "react";
import { Accordion } from "../../accordion/accordion";
import "./all-inclusive-filter.component.scss";

export const AllInclusiveComponent = ({
  contentfulFilters,
  data,
  setGenericFilter,
}) => {

  const [allInclusive, setAllInclusive] = useState(data?.requestedFilters?.selectedAllInclusive);

  useEffect(() => {
    setAllInclusive(data?.requestedFilters?.selectedAllInclusive);

  }, [data?.requestedFilters?.selectedAllInclusive]);

  const handleOnClick = (param) => {
    setAllInclusive(param);
    setGenericFilter([
      {
        selectedAllInclusive: param === true ? param : null,
      },
    ]);
  };

  return (
    <div className="all-inclusive-filter-component-container">
      <Accordion
        startingExpandedState="true"
        labelText={
          contentfulFilters && contentfulFilters[0]?.fields?.allInclusiveHeader
        }
      >
        <div className="filter-options-container">
          <div
            className="filter-option"
            onClick={() => {
              handleOnClick(!allInclusive);
            }}
          >
            <div
              className={
                allInclusive
                  ? "checkbox-container active"
                  : "checkbox-container inactive"
              }
            >
              <div className="checkbox"></div>
            </div>

            <div className="text-container">
              {contentfulFilters && contentfulFilters[0]?.fields?.allInclusive}
            </div>

            <div className="counter-container">
              {data?.filterOptions?.allInclusiveFilterOption.count}
            </div>
          </div>
        </div>
      </Accordion>
    </div>
  );
};
