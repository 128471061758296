import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  holidayHeader: {},
};

const holidayHeaderSlice = createSlice({
  name: 'holidayHeader',
  initialState,
  reducers: {
    setHolidayHeader(state, action) {
      state.holidayHeader = action.payload;
    },
    clearHolidayHeader(state) {
      state.holidayHeader = {};
    },
  },
});

export const { setHolidayHeader, clearHolidayHeader } = holidayHeaderSlice.actions;

export default holidayHeaderSlice;
