import "./star-rating.component.scss";

export const StarRatingComponent = ({ data }) => {
  const validData = typeof data === 'number';
  const maxRating = 5;
  const filledIcons = validData ? parseInt(data) : null;
  const halfFilledIcon = validData ? (data - filledIcons) : null;
  const emptyIcons = validData ? parseInt(maxRating - data) : null;

  return (
    <div className="star-rating-component">
      {
        validData
          ? <>
            {
              [...Array(filledIcons).keys()].map((e, i) => {
                return (
                  <div key={i} className="star-filled-icon"></div>
                );
              })
            }
            {
              halfFilledIcon
                ? <div className="star-half-filled-icon"></div>
                : null
            }
          </>
          : <>
            {
              [...Array(maxRating).keys()].map((e, i) => {
                return (
                  <div key={i} className="star-empty-icon"></div>
                );
              })
            }
          </>
      }
    </div>
  );
};