import React from "react";
import "./confirmation-success.scss";
import {
  parseContentfulDynamicContent,
  renderDocument,
} from "../../services/contentful.service";

import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import "./confirmation-success.scss";

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
  },
};

const ConfirmationSuccess = ({
  contentfulConfirmationPage,
  paymentConfirmation,
}) => {
  const hasTripButtonLink =
    contentfulConfirmationPage &&
    parseContentfulDynamicContent(
      contentfulConfirmationPage[0]?.fields?.tripButtonLink
    );

  const boxCount = hasTripButtonLink ? 4 : 3;

  return (
    <div className="container">
      <header className="header">
        <p>
          {" "}
          {contentfulConfirmationPage &&
            parseContentfulDynamicContent(
              contentfulConfirmationPage[0]?.fields?.successTitle
            )}
        </p>
      </header>
      <div className="split-section">
        <div className="left-section">
          <p className="left-section-header">
            {contentfulConfirmationPage &&
              parseContentfulDynamicContent(
                contentfulConfirmationPage[0]?.fields?.leftPanelHeader
              )}
          </p>
          <p className="left-section-body">
            {contentfulConfirmationPage &&
              renderDocument(
                contentfulConfirmationPage[0]?.fields?.successLeftPanel
              )}
          </p>
        </div>
        <div className="right-section">
          <p className="right-section-header">
            {contentfulConfirmationPage &&
              parseContentfulDynamicContent(
                contentfulConfirmationPage[0]?.fields?.successRightPanelHeader
              )}
          </p>
          <p className="right-section-body">
            {contentfulConfirmationPage &&
              documentToReactComponents(
                contentfulConfirmationPage[0]?.fields?.successRightPanel,
                options
              )}
          </p>
        </div>
      </div>
      <div className={`box-information ${boxCount === 3 ? "three-boxes" : ""}`}>
        <div className="box">
          <div className="order-number-box">
            <p className="title-box">
              {contentfulConfirmationPage &&
                parseContentfulDynamicContent(
                  contentfulConfirmationPage[0]?.fields?.bookingNumber
                )}
            </p>
            <p>
              {paymentConfirmation &&
                paymentConfirmation?.orderDetails.orderNumber}
            </p>
          </div>
        </div>
        <div className="box">
          <div className="pnr-number-box">
            <p className="title-box">
              {contentfulConfirmationPage &&
                parseContentfulDynamicContent(
                  contentfulConfirmationPage[0]?.fields?.pnrNumber
                )}
            </p>
            <p>
              {paymentConfirmation &&
                paymentConfirmation?.orderDetails.bookingNumber}
            </p>
          </div>
        </div>
        <div className="box">
          <div className="contact-box">
            <p className="title-box">
              {contentfulConfirmationPage &&
                parseContentfulDynamicContent(
                  contentfulConfirmationPage[0]?.fields?.contactAirtrade
                )}
            </p>
            <p className="contact-info">
              <a
                href={`tel:${
                  contentfulConfirmationPage &&
                  parseContentfulDynamicContent(
                    contentfulConfirmationPage[0]?.fields?.contanctAirtradeValue
                  )
                }`}
              >
                {contentfulConfirmationPage &&
                  parseContentfulDynamicContent(
                    contentfulConfirmationPage[0]?.fields?.contanctAirtradeValue
                  )}
              </a>
            </p>
          </div>
        </div>
        {contentfulConfirmationPage &&
          parseContentfulDynamicContent(
            contentfulConfirmationPage[0]?.fields?.tripButtonLink
          ) && (
            <div className="box">
              <div className="redirect-button">
                <button
                  className="button button-secondary confirm"
                  onClick={() => {
                    const url =
                      contentfulConfirmationPage &&
                      parseContentfulDynamicContent(
                        contentfulConfirmationPage[0]?.fields?.tripButtonLink
                      );
                    if (url) {
                      window.open(url, "_blank");
                    }
                  }}
                >
                  {contentfulConfirmationPage &&
                    parseContentfulDynamicContent(
                      contentfulConfirmationPage[0]?.fields?.tripButton
                    )}
                </button>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default ConfirmationSuccess;
