import { GetAffiliate } from "../../services/general.service";
import { ReactComponent as IconInsurance } from "../../assets/icons/klm/check-round-S.svg";
import { ReactComponent as IconInsuranceTransavia } from "../../assets/icons/transavia/icon-insurance.svg";

export const ShoppingCartInsuranceComponent = ({
    insurance,
    contentfulHolidayPageSummary,
}) => {
    return (
        <div className="shopping-cart-insurance-component">
            <div className="section-header">
                {GetAffiliate() === "KLM" ? (
                    <IconInsurance />
                ) : GetAffiliate() === "TRANSAVIA" ? (
                    <IconInsuranceTransavia />
                ) : null}
                {contentfulHolidayPageSummary &&
                    contentfulHolidayPageSummary[0]?.fields?.insurance?.title}
            </div>
            <div className="small-space">
                {
                    insurance && (
                        <span>{contentfulHolidayPageSummary && contentfulHolidayPageSummary[0]?.fields?.insurance?.selectedInsurance[insurance?.selectedInsurance]}</span>
                    )
                }
            </div>
        </div>
    );
};