import { Fragment } from "react";
import { check_circle as CheckIconKLM, luggage_under_seat as LuggageUnderSeatIconKLM } from "../../../assets/icons/klm/index";
import { check_circle as CheckIconTransavia, luggage_under_seat as LuggageUnderSeatIconTransavia } from "../../../assets/icons/transavia/index";
import { affiliates } from "../../../common/enums/affiliates";
import { GetAffiliate } from "../../../services/general.service";

export const HandBaggageCard = ({ luggageData }) => {
  const affiliate = GetAffiliate();

  const getLuggageUnderSeatIcon = () => {
    switch (affiliate) {
      case affiliates.klm:
        return LuggageUnderSeatIconKLM;
      case affiliates.transavia:
        return LuggageUnderSeatIconTransavia;
    }
  };

  const getCheckIcon = () => {
    switch (affiliate) {
      case affiliates.klm:
        return CheckIconKLM;
      case affiliates.transavia:
        return CheckIconTransavia;
    }
  };

  const mapBaggageConditions = (data) => {
    if (data)
      return data.split("\n").map((line, key) => (
        <Fragment key={key}>
          {line}
          <br />
        </Fragment>
      ));
  };

  return (
    <Fragment>
      <div className="cabin-luggage-section-header">
        {luggageData && luggageData[0]?.fields?.handBaggageHeader}
      </div>
      <div className="hand-baggage-card-component">
        <div className="hand-baggage-card-component-header"></div>
        <div className="hand-baggage-card-info">
          <div className="hand-baggage-card-info-text">
            <div className="info-text-header">
              {luggageData && luggageData[0]?.fields?.handBaggageLabel}
            </div>
            <p className="info-text-description">
              {luggageData &&
                mapBaggageConditions(
                  luggageData[0]?.fields?.handBaggageConditions
                )}
            </p>
          </div>
          <div className="hand-baggage-card-info-label">
            <img className="info-label-image" src={getLuggageUnderSeatIcon()} />
            <div className="info-label-text-wrapper">
              <img className="info-label-text-check-img" src={getCheckIcon()} />
              <span className="info-label-text">
                {luggageData && luggageData[0]?.fields?.inclusiveLabel}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
