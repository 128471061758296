import { Button } from "../button/button";
import { useContext, useReducer, useEffect } from "react";
import ContentfulContext from "../../store/contentful/contentful-context";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { contentfulEntriesReducer } from "../../common/enums/contentfulEntriesReducer";
import { removeModal } from "../../store/modal/action";
import { getContentfulByContentType } from "../../services/contentful.service";
import { contentType } from "../../common/constants/contentType";
import { ReactComponent as IconModal } from "../../assets/icons/common/alert-icon-modal.svg";
import { ReactComponent as CloseModal } from "../../assets/icons/common/close-icon-modal.svg";
import { ErrorCode } from "../../common/enums/errorCodes";

const initialState = {
  contentfulButtons: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case contentfulEntriesReducer.CUSTOM_MODALS:
      return { ...state, contentfulCustomModals: action.payload };
    default:
      throw new Error("Unknown data");
  }
};

export const ModalComponent = () => {
  const reduxDispatch = useDispatch();
  const contentfulEntries = useContext(ContentfulContext);
  const [{ contentfulCustomModals }, localDispatch] = useReducer(
    reducer,
    initialState
  );
  const { openDialog, title, message, reasonCode, redirectUrl } = useSelector(
    (state) => state.modalData
  );

  useEffect(() => {
    const contentfulCustomModalData = getContentfulByContentType(
      contentfulEntries,
      contentType.GENERAL_MODAL
    );

    localDispatch({
      type: contentfulEntriesReducer.CUSTOM_MODALS,
      payload: contentfulCustomModalData,
    });
  }, [contentfulEntries]);

  const handleClose = () => {
    reduxDispatch(removeModal());

    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  const Backdrop = () => {
    return <div className="backdrop" onClick={handleClose} />;
  };

  const processReasonCode = (reasonCode) => {
    if (contentfulCustomModals) {
      const exceptions =
        contentfulCustomModals[0]?.fields?.exceptions?.content?.map(
          (element) => {
            const outputElement = {
              code: element.data?.target?.fields?.code,
              header: element.data?.target?.fields?.header,
              value:
                element.data?.target?.fields?.body?.content[0]?.content[0]
                  ?.value,
              primaryButton: element.data?.target?.fields?.primaryButton,
            };
            return outputElement;
          }
        );
      const existingException = exceptions.find(
        (element) => Number(element.code) === reasonCode
      );

      if (!existingException) {
        const unknownException = exceptions.find(
          (element) => Number(element.code) === ErrorCode.UNKNOWN_ERROR
        );
        return unknownException;
      } else return existingException;
    }
    return {
      code: "",
      header: "",
      value: "",
    };
  };

  const ModalOverlay = () => {
    const textContent = {
      title: title ? title : "We're sorry",
      message: message ? message : "Something went wrong, please try again.",
      primaryButton:
        contentfulCustomModals &&
        contentfulCustomModals[0]?.fields?.acceptButton,
    };

    if (reasonCode) {
      const exceptionTextContent = processReasonCode(reasonCode);
      textContent.title = exceptionTextContent.header
        ? exceptionTextContent.header
        : "We're sorry";
      textContent.message = exceptionTextContent.value
        ? exceptionTextContent.value
        : "Something went wrong, please try again.";
      textContent.primaryButton = exceptionTextContent.primaryButton
        ? exceptionTextContent.primaryButton
        : "OK";
    }

    return (
      <div className="modal">
        <header className="modal-header">
          <IconModal />
          <p>{textContent.title}</p>
          <div className="button-close">
            <Button className="modal-close-button" onClickAction={handleClose}>
              <CloseModal />
            </Button>
          </div>
        </header>
        <div className="modal-content">{textContent.message}</div>
        <div className="modal-actions">
          {/* Comment in that case, but in the future we will develop this task and we will need it */}

          {/* <Button className="button-cancel" buttonText="Annuleren" /> */}
          <Button
            className="button-accept"
            onClickAction={handleClose}
            buttonText={textContent.primaryButton}
          />
        </div>
      </div>
    );
  };

  return (
    openDialog && (
      <>
        {ReactDOM.createPortal(
          <Backdrop onConfirm={openDialog} />,
          document.getElementById("backdrop-root")
        )}
        {ReactDOM.createPortal(
          <ModalOverlay
            title={title}
            message={message}
            onConfirm={openDialog}
            reasonCode={reasonCode}
            redirectUrl={redirectUrl}
          />,
          document.getElementById("overlay-root")
        )}
      </>
    )
  );
};
