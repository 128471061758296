import React, { forwardRef, useEffect, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";
import en from "date-fns/locale/en-GB";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.styles.scss";
import { LocalStorageService } from "../../services/local-storage.service";
import { LocalStorageEnum } from "../../common/enums/localStorageEnum";
import { GetAffiliate } from "../../services/general.service";
import config from "./../../environments/config.json";
import { MAIN_ROUTE, PagesConstant } from "../../common/constants/pages";
import { useSearchParams } from "react-router-dom";
import { affiliates } from "../../common/enums/affiliates";
import { Alert } from "@mui/material";
import { pages } from "../../common/enums/pages";
import { searchParamsEnum } from "../../common/enums/searchParamsEnum";

const DatepickerComponent = ({
  searchDates,
  contentfulButtons,
  contentfulSearchBar,
  selectedDatesSettings,
  formatSelectedDates,
  formatSelectedDatesForRequest,
}) => {
  registerLocale("nl", nl);
  const componentRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [focus, setFocus] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [departureDateFlexibility, setDepartureDateFlexibility] = useState(0);
  const [isDateRangeValid, setDateRangeValidation] = useState(false);
  const [selectedDates, setSelectedDates] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const culture = LocalStorageService.getCulture();
  const currentDate = new Date();

  let minDateOfAll;
  let maxDateOfAll;

  if (startDate && !endDate) {
    minDateOfAll = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );
    maxDateOfAll = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate() + (config.MAX_TRIP_DURATION - 1)
    );
  } else {
    minDateOfAll = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 4
    );
    maxDateOfAll = new Date(
      currentDate.getFullYear() + 1,
      currentDate.getMonth(),
      currentDate.getDate()
    );
  }
  const affiliate = GetAffiliate();

  const createDateFromString = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
      return date;
    }
  };

  useEffect(() => {
    const departureDate = createDateFromString(
      searchDates?.departureDate ??
        LocalStorageService.getLocalStorageItem(LocalStorageEnum.SELECTED_DATES)
          ?.departureDate
    );
    const returnDate = createDateFromString(
      searchDates?.returnDate ??
        LocalStorageService.getLocalStorageItem(LocalStorageEnum.SELECTED_DATES)
          ?.returnDate
    );
    const selectedDatesFromStorage = JSON.parse(
      LocalStorageService.getLocalStorageItem(LocalStorageEnum.SELECTED_DATES)
    );

    if (selectedDatesFromStorage) {
      const tempSelectedDates = {
        departureDate:
          new Date(selectedDatesFromStorage?.departureDate) ?? departureDate,
        returnDate:
          new Date(selectedDatesFromStorage?.returnDate) ?? returnDate,
        departureDateFlexibility:
          selectedDatesFromStorage?.departureDateFlexibility ??
          departureDateFlexibility,
      };

      setSelectedDates(tempSelectedDates);
      setStartDate(tempSelectedDates?.departureDate);
      setEndDate(tempSelectedDates?.returnDate);
    } else {
      if (searchDates) {
        const tempSelectedDates = {
          departureDate: new Date(searchDates?.departureDate),
          returnDate: new Date(searchDates?.returnDate),
          departureDateFlexibility:
            selectedDatesFromStorage?.departureDateFlexibility ??
            departureDateFlexibility,
        };
        setSelectedDates(tempSelectedDates);
      }
    }

    setStartDate(departureDate);
    setEndDate(returnDate);
  }, [
    searchDates,
    LocalStorageService.getLocalStorageItem(LocalStorageEnum.SELECTED_DATES),
  ]);

  useEffect(() => {
    if (startDate && endDate) {
      setDateRangeValidation(true);
    } else {
      setDateRangeValidation(false);
    }
  }, [startDate, endDate]);

  const saveChanges = () => {
    const selectedDates = {
      departureDate: startDate,
      returnDate: endDate,
      departureDateFlexibility: departureDateFlexibility,
    };

    if (isDateRangeValid) {
      let newStartDate = formatSelectedDatesForRequest(startDate);
      let newEndDate = formatSelectedDatesForRequest(endDate);
      setSelectedDates(selectedDates);
      setSearchParams((params) => {
        params.set(searchParamsEnum.departureDate, newStartDate);
        params.set(searchParamsEnum.returnDate, newEndDate);
        params.set(
          searchParamsEnum.departureDateFlex,
          departureDateFlexibility
        );
        return params;
      });
      LocalStorageService.setLocalStorageItem(LocalStorageEnum.SELECTED_DATES, {
        departureDate: newStartDate,
        returnDate: newEndDate,
        departureDateFlexibility: departureDateFlexibility,
      });
      setFocus((prevFocus) => !prevFocus);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    componentRef.current.setOpen(isOpen);
  }, [isOpen]);

  const handleCloseClick = () => {
    setStartDate(selectedDates?.departureDate);
    setEndDate(selectedDates?.returnDate);
    setDepartureDateFlexibility(selectedDates?.departureDateFlexibility);
    setIsOpen(false);
  };

  const handleTravelFlexibility = (value) => {
    setDepartureDateFlexibility(value);
  };

  const onChange = (date) => {
    const [start, end] = date;
    const startDateToCompare = new Date(start);
    const endDateToCompare = new Date(end);
    if (startDateToCompare.getTime() === endDateToCompare.getTime()) {
      setStartDate(null);
      return;
    }
    setStartDate(start);
    setEndDate(end);
    const minimumEndDate = new Date(start);
    minimumEndDate.setDate(minimumEndDate.getDate() + config.MIN_TRIP_DURATION);
    if (minimumEndDate > end) {
      setEndDate(null);
    }
    const maximumEndDate = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate() + config.MAX_TRIP_DURATION
    );
    if (maximumEndDate <= end) {
      setEndDate(null);
    }
  };

  const toggleDatePickerOpen = () => {
    setStartDate(selectedDates?.departureDate);
    setEndDate(selectedDates?.returnDate);
    setDepartureDateFlexibility(selectedDates?.departureDateFlexibility);
    setIsOpen(!isOpen);
  };

  const formatWeekDay = () => {
    return (nameOfDay) =>
      affiliate === affiliates.klm
        ? nameOfDay.substr(0, 1).toLocaleUpperCase()
        : nameOfDay.substr(0, 2);
  };

  const CustomInput = forwardRef(({ value, onClick }, componentRef) => (
    <button className="custom-input" onClick={toggleDatePickerOpen}>
      <span className="dates-placeholder">
        <span className="dates-placeholder-wrapper">
          {" "}
          {formatSelectedDates(selectedDates?.departureDate)} -{" "}
          {formatSelectedDates(selectedDates?.returnDate)}
        </span>
        <span className="travel-flexibility-placeholder">
          {selectedDates?.departureDateFlexibility > 0 && (
            <span>&plusmn; {selectedDates?.departureDateFlexibility} </span>
          )}
        </span>
      </span>
      <div className="dropdown-caret-placeholder">
        {(isOpen && <div className="dropdown-caret rotate"></div>) ||
          (!isOpen && <div className="dropdown-caret"></div>)}
      </div>
    </button>
  ));

  return (
    <div className="datepicker-component" onChange={selectedDatesSettings}>
      <div className="datepicker-label">
        <DatePicker
          locale={culture === "nl-NL" ? nl : en}
          selected={null}
          onChange={onChange}
          onClickOutside={toggleDatePickerOpen}
          shouldCloseOnSelect={false}
          minDate={minDateOfAll}
          maxDate={maxDateOfAll}
          showDisabledMonthNavigation
          showPreviousMonths={false}
          startDate={startDate}
          endDate={endDate}
          selectsDisabledDaysInRange
          monthsShown={2}
          peekNextMonth
          formatWeekDay={formatWeekDay()}
          selectsRange
          ref={componentRef}
          className={focus && "datepicker-active"}
          dateFormat="dd MMM yyyy"
          inlineFocusSelectedMonth
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          customInput={<CustomInput selectedDates={selectedDates} />}
        >
          <>
            <Alert className="date-range-info" severity="info">
              {contentfulSearchBar && contentfulSearchBar?.maximumDateRange}
            </Alert>
            {pages.results.some((route) =>
              window.location.pathname.includes(route)
            ) && (
              <div className="date-range-modifiers">
                <button
                  className={`date-modifier-button ${
                    departureDateFlexibility === 0 &&
                    "flexibility-button-selected"
                  }`}
                  onClick={() => handleTravelFlexibility(0)}
                >
                  {contentfulSearchBar?.flexibilityExactDate}
                </button>
                <button
                  className={`date-modifier-button ${
                    departureDateFlexibility === 1 &&
                    "flexibility-button-selected"
                  }`}
                  onClick={() => handleTravelFlexibility(1)}
                >
                  &#177; {contentfulSearchBar?.flexibilityOneDay}
                </button>
                <button
                  className={`date-modifier-button ${
                    departureDateFlexibility === 2 &&
                    "flexibility-button-selected"
                  }`}
                  onClick={() => handleTravelFlexibility(2)}
                >
                  &#177; {contentfulSearchBar?.flexibilityTwoDays}
                </button>
                <button
                  className={`date-modifier-button ${
                    departureDateFlexibility === 3 &&
                    "flexibility-button-selected"
                  }`}
                  onClick={() => handleTravelFlexibility(3)}
                >
                  &#177; {contentfulSearchBar?.flexibilityThreeDays}
                </button>
              </div>
            )}
          </>
          <div className="datepicker-button-container">
            <button onClick={handleCloseClick} className="button">
              {contentfulButtons?.cancel}
            </button>
            <button
              onClick={saveChanges}
              className={`${
                isDateRangeValid
                  ? "button"
                  : "save-button-disabled button-primary-medium"
              }`}
            >
              {contentfulButtons?.save}
            </button>
          </div>
        </DatePicker>
      </div>
    </div>
  );
};

export default DatepickerComponent;
