import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    openDialog: false,
    message: '',
    title: '',
    reasonCode: null,
    redirectUrl: null
}
export const modalSlice = createSlice({
    name: 'modal',
    initialState: initialState,
    reducers: {
        showModal(state, action) {
            state.openDialog = true
            const { title, message, reasonCode, redirectUrl } = action.payload
            state.message = message
            state.title = title
            state.reasonCode = reasonCode
            state.redirectUrl = redirectUrl
        },
        clearModal(state) {
            state.openDialog = false
            state.message = ''
            state.title = ''
            state.reasonCode = null
            state.redirectUrl = null
        },
    },
})
