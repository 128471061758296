import styled from "styled-components";
import "./map.component.scss";
import { GenericButton } from "../generic-button/generic-button";

const ExpandMapButton = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const MapComponent = ({ contentfulButtons, isTestUser }) => {
  if (contentfulButtons?.length > 0)
    return (
      <div className="side-filter-bar-map">
        <ExpandMapButton>
          <GenericButton className="button button-show-map">
            <i className="map-icon"></i>
            <span>
              {isTestUser
                ? contentfulButtons[0]?.fields?.showHolidaysOnMap
                : contentfulButtons[0]?.fields?.showHotelsOnMap}
            </span>
          </GenericButton>
        </ExpandMapButton>
      </div>
    );
};
