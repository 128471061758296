import "./button.scss";

export const Button = ({
  buttonText,
  onClickAction,
  className,
  children,
  isDisabled,
}) => {
  if (isDisabled) {
    return (
      <button
        className={`button ${className} disabled-button`}
        onClick={() => onClickAction()}
      >
        {buttonText}
        {children}
      </button>
    );
  }
  return (
    <button className={`button ${className}`} onClick={() => onClickAction()}>
      {buttonText}
      {children}
    </button>
  );
};
