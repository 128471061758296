import AdultOnly from "./../../../assets/icons/themes/KLM/AdultOnly.svg";
import Beach from "./../../../assets/icons/themes/KLM/Beach.svg";
import Boutique from "./../../../assets/icons/themes/KLM/Boutique.svg";
import Business from "./../../../assets/icons/themes/KLM/Business.svg";
import Designhotel from "./../../../assets/icons/themes/KLM/Designhotel.svg";
import Family from "./../../../assets/icons/themes/KLM/Family.svg";
import Golf from "./../../../assets/icons/themes/KLM/Golf.svg";
import Greatlocation from "./../../../assets/icons/themes/KLM/Greatlocation.svg";
import LGBT from "./../../../assets/icons/themes/KLM/LGBT.svg";
import Luxury from "./../../../assets/icons/themes/KLM/Luxury.svg";
import Romantic from "./../../../assets/icons/themes/KLM/Romantic.svg";
import Wellness from "./../../../assets/icons/themes/KLM/Wellness.svg";

export const klmThemeIcons = {
  AdultOnly: AdultOnly,
  Beach: Beach,
  Boutique: Boutique,
  Business: Business,
  Designhotel: Designhotel,
  Family: Family,
  Golf: Golf,
  Greatlocation: Greatlocation,
  LGBT: LGBT,
  Luxury: Luxury,
  Romantic: Romantic,
  Wellness: Wellness,
}