import React from "react";
import { AddressDetailsInputComponent } from "./address-details-input/address-details-input-component";
import { CityInputComponent } from "./city-input/city-input.component";
import { CompanyNameInputComponent } from "./company-name-input/company-name-input.component";
import { CountryInputComponent } from "./country-input/country-input.component";
import { EmailInputComponent } from "./e-mail-input/email-input.component";
import './main-booker.component.scss';
import { PhoneNumberInputComponent } from "./phone-number-input/phone-number-input.component";
import { PostCodeInputComponent } from "./post-code-input/post-code-input.component";
export const MainBookerComponent = ({
                                        contactDetailInfo,
                                        name,
                                        register,
                                        control,
                                        errors,
                                        setValue,
                                        contentfulCheckoutPassengerDetails,
                                        contentfulCheckoutFormValidators
                                    }) => {
    const preferredCountry = contactDetailInfo?.countryOptions.find(country => country.preferred === true);

    return <div className="main-booker-details-component">
        <EmailInputComponent name={name}
                             defaultEmail={contactDetailInfo.mainBooker.email}
                             register={register}
                             control={control}
                             errors={errors}
                             contentfulCheckoutPassengerDetails={contentfulCheckoutPassengerDetails}
                             contentfulCheckoutFormValidators={contentfulCheckoutFormValidators}/>
        <PhoneNumberInputComponent name={name}
                                   phoneCodes={contactDetailInfo?.phonePrefixCodeOptions}
                                   defaultPhoneCode={contactDetailInfo.mainBooker.phoneNumberCountryCode}
                                   defaultPhoneSuffix={contactDetailInfo?.mainBooker?.phoneNumberSuffix}
                                   register={register}
                                   control={control}
                                   errors={errors}
                                   setValue={setValue}
                                   contentfulCheckoutPassengerDetails={contentfulCheckoutPassengerDetails}
                                   contentfulCheckoutFormValidators={contentfulCheckoutFormValidators}/>
        <AddressDetailsInputComponent name={name}
                                      defaultAddress={contactDetailInfo?.mainBooker?.address}
                                      register={register}
                                      control={control}
                                      errors={errors}
                                      contentfulCheckoutPassengerDetails={contentfulCheckoutPassengerDetails}
                                      contentfulCheckoutFormValidators={contentfulCheckoutFormValidators}/>

        <PostCodeInputComponent name={name}
                                defaultZipCode={contactDetailInfo?.mainBooker?.address?.zipCode}
                                register={register}
                                control={control}
                                errors={errors}
                                contentfulCheckoutPassengerDetails={contentfulCheckoutPassengerDetails}
                                contentfulCheckoutFormValidators={contentfulCheckoutFormValidators}
        />
        <CityInputComponent name={name}
                            defaultCity={contactDetailInfo?.mainBooker?.address?.city}
                            register={register}
                            control={control}
                            errors={errors}
                            contentfulCheckoutPassengerDetails={contentfulCheckoutPassengerDetails}
                            contentfulCheckoutFormValidators={contentfulCheckoutFormValidators}
        />

        <CountryInputComponent countryOptions={contactDetailInfo?.countryOptions}
                               preferredCountry={preferredCountry}
                               name={name}
                               register={register}
                               control={control}
                               errors={errors}
                               contentfulCheckoutPassengerDetails={contentfulCheckoutPassengerDetails}
                               contentfulCheckoutFormValidators={contentfulCheckoutFormValidators}
        />

        <CompanyNameInputComponent name={name}
                                   defaultCompanyName={contactDetailInfo?.mainBooker?.companyName}
                                   register={register}
                                   control={control}
                                   errors={errors}
                                   contentfulCheckoutPassengerDetails={contentfulCheckoutPassengerDetails}
                                   contentfulCheckoutFormValidators={contentfulCheckoutFormValidators}
        />
    </div>
}
